<div class="header-container">
  <div class="header-details">
    <div fxFlex="auto">
      <app-page-header [icon]="'assets/images/icons/custom-reciept-icon.png'" [title]="'Receipt Designer'" [breadcrumb]="''">
      </app-page-header>
      <h4 class="subUrl">Receipt Design > Sale Receipt</h4>
    </div>
  </div>
</div>

<div style="padding-left: 15px; padding-right: 15px">
  <div class="dialog-headline">
    <mat-label>Receipt Designer</mat-label>
  </div>

  <mat-dialog-content class="dialog-content">
    <div class="dialog-sections">
      <!-- Elements Section -->
      <div class="dialog-section dialog-element">
        <h3>Elements</h3>
        <!-- Add Element Section -->
        <div class="element-container">

          <!-- Logo Section -->
          <div class="logo-container">
            <!-- Draggable Logo -->
            <div class="preview-container" [draggable]="true" (dragstart)="onLogoDragStart($event)"
              (dragend)="onDragEnd()">
              <img [src]="previewImage || 'assets/images/icons/icon-pharmacies-blue.png'" alt="Logo Preview"
                class="logo-preview" />
            </div>
          </div>

          <!-- Upload and Reset Buttons for Logo -->
          <div class="logo-buttons">
            <!-- Hidden File Input -->
            <input type="file" id="logoUpload" (change)="onLogoUpload($event)" accept="image/*" hidden />

            <!-- Upload Logo Button -->
            <button mat-raised-button color="primary" (click)="triggerFileInput()">Upload Logo</button>
            <!-- Reset Logo Button -->
            <button mat-raised-button color="warn" (click)="resetLogo()">Reset Logo</button>
          </div>
        </div>

        <!-- Line Divider -->
        <hr class="divider-line">

        <!-- Header Section -->
        <div class="section">
          <div class="section-header">
            <h3>Header</h3>
            <mat-icon matTooltip="Toggle Header" (click)="toggleSection('header')">
              {{ showHeader ? 'expand_more' : 'expand_less' }}
            </mat-icon>
          </div>

          <!-- QR and Barcode Options for Header -->
          <div class="toggle-options">
            <div><mat-checkbox [(ngModel)]="isQrEnabledHeader">Enable QR Code</mat-checkbox></div>
            <div><mat-checkbox [(ngModel)]="isBarcodeEnabledHeader">Enable Barcode</mat-checkbox></div>
          </div>


          <!-- Section content is shown only when expanded -->
          <div class="section-content" *ngIf="showHeader">

            <!-- Header Section -->
            <div class="section" *ngFor="let field of headerFields">
              <div class="draggable-element" [draggable]="true" (dragstart)="onDragStart($event, field)"
                (dragend)="onDragEnd()">
                {{ field }}
              </div>
            </div>
          </div>
        </div>

        <!-- Line Divider -->
        <hr class="divider-line">

        <!-- Footer Section -->
        <div class="section">
          <div class="section-header">
            <h3>Footer</h3>
            <mat-icon matTooltip="Toggle Footer" (click)="toggleSection('footer')">
              {{ showFooter ? 'expand_more' : 'expand_less' }}
            </mat-icon>
          </div>

          <!-- QR and Barcode Options for Footer -->
          <div class="toggle-options">
            <div><mat-checkbox [(ngModel)]="isQrEnabledFooter">Enable QR Code</mat-checkbox></div>
            <div><mat-checkbox [(ngModel)]="isBarcodeEnabledFooter">Enable Barcode</mat-checkbox></div>
          </div>

          <!-- Section content is shown only when expanded -->
          <div class="section-content" *ngIf="showFooter">
            <div class="section" *ngFor="let field of footerFields">
              <div class="draggable-element" [draggable]="true" (dragstart)="onDragStart($event, field)"
                (dragend)="onDragEnd()">
                {{ field }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Builder Section -->
      <div class="dialog-section dialog-builder">
        <h3>Builder</h3>

        <!-- Container for font and size dropdowns and icons -->
        <div class="dropdown-container">
          <!-- Font Selection Dropdown -->
          <mat-form-field class="dropdown font-dropdown">
            <mat-label>Font</mat-label>
            <mat-select [(value)]="selectedFont" class="small-dropdown">
              <mat-option *ngFor="let font of fonts" [value]="font" [ngStyle]="{'font-family': font}">{{ font
                }}</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Font Size Selection Dropdown -->
          <mat-form-field class="dropdown size-dropdown">
            <mat-label>Size</mat-label>
            <mat-select [(value)]="selectedFontSize" class="small-dropdown">
              <mat-option *ngFor="let size of fontSizes" [value]="size">{{ size }} px</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Bold, Italic, Underline, Bullet and Numbering Icons -->
          <div class="icon-controls">
            <mat-icon matTooltip="Bold" (click)="toggleBold()">format_bold</mat-icon>
            <mat-icon matTooltip="Italic" (click)="toggleItalic()">format_italic</mat-icon>
            <mat-icon matTooltip="Underline" (click)="toggleUnderline()">format_underline</mat-icon>
            <mat-icon matTooltip="Bullet" (click)="toggleBullet()">format_list_bulleted</mat-icon>
            <mat-icon matTooltip="Numbering" (click)="toggleNumbering()">format_list_numbered</mat-icon>

            <!-- Alignment Icons -->
            <mat-icon matTooltip="Align Left" (click)="toggleAlignment('left')">format_align_left</mat-icon>
            <mat-icon matTooltip="Align Center" (click)="toggleAlignment('center')">format_align_center</mat-icon>
            <mat-icon matTooltip="Align Right" (click)="toggleAlignment('right')">format_align_right</mat-icon>

            <!-- Casing Icons -->
            <mat-icon matTooltip="Uppercase" (click)="toggleCasing('uppercase')">keyboard_capslock</mat-icon>
            <mat-icon matTooltip="Lowercase" (click)="toggleCasing('lowercase')">keyboard_backspace</mat-icon>
            <mat-icon matTooltip="Title Case" (click)="toggleCasing('titlecase')">title</mat-icon>
          </div>
        </div>

        <!-- Divider Line -->
        <hr class="divider-line">

        <!-- Header Section -->
        <div class="header-section" (dragover)="onDragOver($event)" (drop)="onDrop($event)"
          [ngClass]="{ 'drop-area': isDragging }">
          <h3>Header</h3>
          <p style="text-align: center;">Customize your header content here.</p>

          <!-- QR Code Image Placeholder -->
          <div *ngIf="isQrEnabledHeader" [ngStyle]="{ 'text-align': qrAlignment }">
            <img src="assets/images/Dummy_QR.png" alt="QR Code" class="qr-barcode-img" />
          </div>

          <!-- Barcode Image Placeholder -->
          <div *ngIf="isBarcodeEnabledHeader" [ngStyle]="{ 'text-align': barcodeAlignment }">
            <img src="assets/images/DummyBarcode.png" alt="Barcode" />
          </div>

          <!-- Dropped Logo -->
          <div *ngIf="droppedLogo" [ngStyle]="{ 'text-align': logoAlignment }">
            <img [src]="droppedLogo" alt="Dropped Logo" class="dropped-logo-img" />
          </div>

          <!-- Dropped Elements -->
          <div *ngIf="droppedStoreName">
            <input [(ngModel)]="droppedStoreName" class="editable-field" placeholder="Store Name"
              [style.font-family]="selectedFont" [style.font-size]="selectedFontSize + 'px'"
              [style.font-weight]="isBold ? 'bold' : 'normal'" [style.font-style]="isItalic ? 'italic' : 'normal'"
              [style.text-decoration]="isUnderline ? 'underline' : 'none'" [style.text-align]="selectedAlignment"
              [ngStyle]="{
              'text-transform': selectedCasing === 'uppercase' ? 'uppercase' :
                                selectedCasing === 'lowercase' ? 'lowercase' : 'none'
            }" (focus)="setActiveField('storeName')" />
          </div>

          <div *ngIf="droppedStoreAddress1">
            <input [(ngModel)]="droppedStoreAddress1" class="editable-field" placeholder="Store Address 1"
              [style.font-family]="selectedFont" [style.font-size]="selectedFontSize + 'px'"
              [style.font-weight]="isBold ? 'bold' : 'normal'" [style.font-style]="isItalic ? 'italic' : 'normal'"
              [style.text-decoration]="isUnderline ? 'underline' : 'none'" [style.text-align]="selectedAlignment"
              [ngStyle]="{'text-transform': selectedCasing === 'uppercase' ? 'uppercase' : selectedCasing === 'lowercase' ? 'lowercase' : 'none'
            }" (focus)="setActiveField('storeAddress1')" />
          </div>

          <div *ngIf="droppedStoreAddress2">
            <input [(ngModel)]="droppedStoreAddress2" class="editable-field" placeholder="Store Address 2"
              [style.font-family]="selectedFont" [style.font-size]="selectedFontSize + 'px'"
              [style.font-weight]="isBold ? 'bold' : 'normal'" [style.font-style]="isItalic ? 'italic' : 'normal'"
              [style.text-decoration]="isUnderline ? 'underline' : 'none'" [style.text-align]="selectedAlignment"
              [ngStyle]="{'text-transform': selectedCasing === 'uppercase' ? 'uppercase' : selectedCasing === 'lowercase' ? 'lowercase' : 'none'
            }" (focus)="setActiveField('storeAddress2')" />
          </div>

          <div *ngIf="droppedStoreCity">
            <input [(ngModel)]="droppedStoreCity" class="editable-field" placeholder="Store City"
              [style.font-family]="selectedFont" [style.font-size]="selectedFontSize + 'px'"
              [style.font-weight]="isBold ? 'bold' : 'normal'" [style.font-style]="isItalic ? 'italic' : 'normal'"
              [style.text-decoration]="isUnderline ? 'underline' : 'none'" [style.text-align]="selectedAlignment"
              [ngStyle]="{'text-transform': selectedCasing === 'uppercase' ? 'uppercase' : selectedCasing === 'lowercase' ? 'lowercase' : 'none'
            }" (focus)="setActiveField('storeCity')" />
          </div>

          <div *ngIf="droppedStoreZip">
            <input [(ngModel)]="droppedStoreZip" class="editable-field" placeholder="Store Zip"
              [style.font-family]="selectedFont" [style.font-size]="selectedFontSize + 'px'"
              [style.font-weight]="isBold ? 'bold' : 'normal'" [style.font-style]="isItalic ? 'italic' : 'normal'"
              [style.text-decoration]="isUnderline ? 'underline' : 'none'" [style.text-align]="selectedAlignment"
              [ngStyle]="{'text-transform': selectedCasing === 'uppercase' ? 'uppercase' : selectedCasing === 'lowercase' ? 'lowercase' : 'none'
            }" (focus)="setActiveField('storeZip')" />
          </div>

          <div *ngIf="droppedStorePhoneNo">
            <input [(ngModel)]="droppedStorePhoneNo" class="editable-field" placeholder="Store Phone Number"
              [style.font-family]="selectedFont" [style.font-size]="selectedFontSize + 'px'"
              [style.font-weight]="isBold ? 'bold' : 'normal'" [style.font-style]="isItalic ? 'italic' : 'normal'"
              [style.text-decoration]="isUnderline ? 'underline' : 'none'" [style.text-align]="selectedAlignment"
              [ngStyle]="{'text-transform': selectedCasing === 'uppercase' ? 'uppercase' : selectedCasing === 'lowercase' ? 'lowercase' : 'none'
            }" (focus)="setActiveField('storePhoneNo')" />
          </div>

          <div *ngIf="droppedStoreFaxNo">
            <input [(ngModel)]="droppedStoreFaxNo" class="editable-field" placeholder="Store Fax Number"
              [style.font-family]="selectedFont" [style.font-size]="selectedFontSize + 'px'"
              [style.font-weight]="isBold ? 'bold' : 'normal'" [style.font-style]="isItalic ? 'italic' : 'normal'"
              [style.text-decoration]="isUnderline ? 'underline' : 'none'" [style.text-align]="selectedAlignment"
              [ngStyle]="{'text-transform': selectedCasing === 'uppercase' ? 'uppercase' : selectedCasing === 'lowercase' ? 'lowercase' : 'none'
            }" (focus)="setActiveField('storeFaxNo')" />
          </div>

          <div *ngIf="droppedStoreTollFreeNo">
            <input [(ngModel)]="droppedStoreTollFreeNo" class="editable-field" placeholder="Store Toll Free Number"
              [style.font-family]="selectedFont" [style.font-size]="selectedFontSize + 'px'"
              [style.font-weight]="isBold ? 'bold' : 'normal'" [style.font-style]="isItalic ? 'italic' : 'normal'"
              [style.text-decoration]="isUnderline ? 'underline' : 'none'" [style.text-align]="selectedAlignment"
              [ngStyle]="{'text-transform': selectedCasing === 'uppercase' ? 'uppercase' : selectedCasing === 'lowercase' ? 'lowercase' : 'none'
            }" (focus)="setActiveField('storeTollFreeNo')" />
          </div>

          <div *ngIf="droppedStoreWebAddress">
            <input [(ngModel)]="droppedStoreWebAddress" class="editable-field" placeholder="Store Web Address"
              [style.font-family]="selectedFont" [style.font-size]="selectedFontSize + 'px'"
              [style.font-weight]="isBold ? 'bold' : 'normal'" [style.font-style]="isItalic ? 'italic' : 'normal'"
              [style.text-decoration]="isUnderline ? 'underline' : 'none'" [style.text-align]="selectedAlignment"
              [ngStyle]="{'text-transform': selectedCasing === 'uppercase' ? 'uppercase' : selectedCasing === 'lowercase' ? 'lowercase' : 'none'
            }" (focus)="setActiveField('storeWebAddress')" />
          </div>
        </div>

        <!-- Divider Line -->
        <hr class="divider-line">

        <!-- Footer Section -->
        <div class="footer-section" (dragover)="onDragOver($event)" (drop)="onDrop($event)"
          [ngClass]="{ 'drop-area': isDragging }">
          <h3>Footer</h3>
          <p style="text-align: center;">Customize your footer content here.</p>

          <!-- QR Code Image Placeholder -->
          <div *ngIf="isQrEnabledFooter" [ngStyle]="{ 'text-align': qrAlignment }">
            <img src="assets/images/Dummy_QR.png" alt="QR Code" class="qr-barcode-img" />
          </div>

          <!-- Barcode Image Placeholder -->
          <div *ngIf="isBarcodeEnabledFooter" [ngStyle]="{ 'text-align': barcodeAlignment }">
            <img src="assets/images/DummyBarcode.png" alt="Barcode" />
          </div>

          <div *ngIf="droppedFooterText">
            <input [(ngModel)]="droppedFooterText" class="editable-field" placeholder="Footer Text"
              [style.font-family]="selectedFont" [style.font-size]="selectedFontSize + 'px'"
              [style.font-weight]="isBold ? 'bold' : 'normal'" [style.font-style]="isItalic ? 'italic' : 'normal'"
              [style.text-decoration]="isUnderline ? 'underline' : 'none'" [style.text-align]="selectedAlignment"
              [ngStyle]="{'text-transform': selectedCasing === 'uppercase' ? 'uppercase' : selectedCasing === 'lowercase' ? 'lowercase' : 'none'
            }" (focus)="setActiveField('footerText')" />
          </div>
        </div>

        <!-- Divider Line -->
        <hr class="divider-line">

        <div class="button-container">
          <!-- Preview Button -->
          <button class="custom-orange-button" (click)="showPreview()">Preview</button>
          <!-- Save Button -->
          <button class="custom-blue-button" (click)="exportDesign()">Save</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
