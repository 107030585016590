import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { StoreService } from 'src/app/services/Store/store.service';
import { UserService } from 'src/app/services/User/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  //=============VARIABLES===============
  hideOldPwd: boolean = true;
  hideNewPwd: boolean = true;
  hideConfirmPwd: boolean = true;
  userData: any = {};
  panelOpenState: boolean = false;
  isPasswordStrong: boolean = false;
  showDetails: boolean = true;
  showError: boolean = false;
  changePasswordAtLogin: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public receivedData: any, private userService: UserService,
    private dialogRefPassword: MatDialogRef<ChangePasswordComponent>, private utilityService: UtilityService,
    private router: Router, private storeService: StoreService) {

    if (receivedData != undefined && receivedData.userData.changePasswordAtLogin != undefined) {
      this.changePasswordAtLogin = receivedData.userData.changePasswordAtLogin;
      this.userData.userName = receivedData.userData.user.userName;
      this.userData.NPINo = receivedData.userData.user.NPINo;

    } else {
      this.userData = {};
      this.userData.userName = sessionStorage.getItem('userName');
      this.userData.NPINo = sessionStorage.getItem('NPINo');
    }
  }

  ngOnInit(): void {
  }

  onPasswordStrengthChanged(event: any) {
    if (event == 100)
      this.isPasswordStrong = true;
  }

  changeUserPassword() {
    this.userData.password = this.userData.oldPassword;

    this.userService.changeUserPassword(this.userData).subscribe(response => {

      var receivedUserResponse = JSON.parse(response.data);

      if (response.result == "SUCCESS") {

        var alertRef = this.utilityService.showAlert(
          "SUCCESS",
          "Success",
          "You have changed the password please login again with new password"
        ).subscribe(result => {

          this.userService.logOutUser();
          this.dialogRefPassword.close();
          alertRef.unsubscribe();
        });

      } else if (response.result == "ERROR") {

        this.utilityService.showAlert(
          "WARNING",
          "Warning",
          receivedUserResponse.result
        );


      } else {
        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error occurred while changing the password"
        );
      }
    });
  }

  changeUserPasswordAtLogin() {
    this.userData.password = this.userData.oldPassword;

    this.userService.changePasswordAtLogin(this.userData).subscribe(response => {
        var receivedUserResponse = JSON.parse(response.data);

          if (response.result == "SUCCESS") {

            var alertRef = this.utilityService.showAlert(
              "SUCCESS",
              "Success",
              "Password changed successfully"
            ).subscribe(result => {

              var alertPassRef = this.utilityService.showAlert(
                "WARNING",
                "Warning",
                "You have changed the password please login again with new password"
              ).subscribe(result => {

                this.userService.logOutUser();
                this.dialogRefPassword.close();
                alertPassRef.unsubscribe();
              });
              alertRef.unsubscribe();
            });
          } else if (response.result == "ERROR") {

            this.utilityService.showAlert(
              "WARNING",
              "Warning",
              receivedUserResponse.result
            );
          } else {
            this.utilityService.showAlert(
              "ERROR",
              "Failed",
              "Error occurred while changing the password"
            );
          }
    });
    // this.storeService.getStoreDataFromStoreConfig(this.userData).subscribe(response => {

    //   var receivedUserResponse = JSON.parse(response.data);
    //   var storeData = receivedUserResponse.storeData;
    //   if (receivedUserResponse.userData != 'User not found') {


    //     let changePasswordInStoreConfig = this.storeService.changePasswordInStoreConfig(this.userData);
    //     let changePasswordInMPOS = this.userService.changePasswordAtLogin(this.userData);
    //     let changePasswordInEDI = this.userService.changePasswordInEDI(this.userData);


    //     forkJoin([changePasswordInStoreConfig]).subscribe(responses => {
    //       console.log('store config response');
    //       console.log(responses);
    //       if (responses[0].result == 'SUCCESS') {
    //         var storeConfig = "Password updated in store configurator successfully";
    //         if (storeData.isMpos) {
    //           forkJoin([changePasswordInMPOS]).subscribe(responses => {
    //             if (responses[0].result == constant.KEY_SUCCESS) {
    //               var mposMsg = "Password updated in ePrimePOS successfully";

    //               if (storeData.isEdiv2) {
    //                 forkJoin([changePasswordInEDI]).subscribe(responses => {
    //                   if (responses[0].result == 'SUCCESS') {

    //                     var ediMsg = "Password updated in EDIV2 successfully";

    //                     var alertRef = this.utilityService.showAlert(
    //                       constant.KEY_SUCCESS,
    //                       "Success",
    //                       (storeConfig + mposMsg + ediMsg)
    //                     ).subscribe(result => {

    //                       this.router.navigateByUrl('/login');
    //                       this.dialogRefPassword.close();

    //                       alertRef.unsubscribe();
    //                     });

    //                   } else {
    //                     var alertRef = this.utilityService.showAlert(
    //                       constant.KEY_SUCCESS,
    //                       "Success",
    //                       (storeConfig + mposMsg + 'Error while updating the password in EDI')
    //                     ).subscribe(result => {

    //                       this.router.navigateByUrl('/login');
    //                       this.dialogRefPassword.close();

    //                     });
    //                     alertRef.unsubscribe();
    //                     this.router.navigateByUrl('/login');
    //                     this.dialogRefPassword.close();

    //                   }
    //                 });
    //               } else {
    //                 var alertRef = this.utilityService.showAlert(
    //                   constant.KEY_SUCCESS,
    //                   "Success",
    //                   (storeConfig + mposMsg)
    //                 ).subscribe(result => {

    //                   this.router.navigateByUrl('/login');
    //                   this.dialogRefPassword.close();

    //                   alertRef.unsubscribe();
    //                 });
    //                 // this.router.navigateByUrl('/login');
    //                 // this.dialogRefPassword.close();

    //               }
    //             } else {
    //               var alertRef = this.utilityService.showAlert(
    //                 "SUCCESS",
    //                 "Success",
    //                 (storeConfig + 'Error while updating the password in ePrimePOS')
    //               ).subscribe(result => {

    //                 this.router.navigateByUrl('/login');

    //                 alertRef.unsubscribe();
    //               });


    //               // this.router.navigateByUrl('/login');

    //             }
    //           });
    //         } else {
    //           var alertRef = this.utilityService.showAlert(
    //             "SUCCESS",
    //             "Success",
    //             (storeConfig)
    //           ).subscribe(result => {

    //             this.router.navigateByUrl('/login');

    //             alertRef.unsubscribe();
    //           });
    //           // this.router.navigateByUrl('/login');

    //         }
    //       } else {
    //         var alertRef = this.utilityService.showAlert(
    //           "ERROR",
    //           "Failed",
    //           ('Old password does not match with current password. Please check your password')
    //         ).subscribe(result => {

    //           this.router.navigateByUrl('/login');

    //           alertRef.unsubscribe();
    //         });
    //         // this.router.navigateByUrl('/login');

    //       }
    //     });
    //   } else {
    //     this.userService.changePasswordAtLogin(this.userData).subscribe(response => {

    //       var receivedUserResponse = JSON.parse(response.data);

    //       if (response.result == "SUCCESS") {

    //         var alertRef = this.utilityService.showAlert(
    //           "SUCCESS",
    //           "Success",
    //           "Password changed successfully"
    //         ).subscribe(result => {

    //           var alertPassRef = this.utilityService.showAlert(
    //             "WARNING",
    //             "Warning",
    //             "You have changed the password please login again with new password"
    //           ).subscribe(result => {

    //             this.userService.logOutUser();
    //             this.dialogRefPassword.close();
    //             alertPassRef.unsubscribe();
    //           });
    //           alertRef.unsubscribe();
    //         });
    //       } else if (response.result == "ERROR") {

    //         this.utilityService.showAlert(
    //           "WARNING",
    //           "Warning",
    //           receivedUserResponse.result
    //         );
    //       } else {
    //         this.utilityService.showAlert(
    //           "ERROR",
    //           "Failed",
    //           "Error occurred while changing the password"
    //         );
    //       }
    //     });
    //   }
    // });
  }
}