import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { LabelPrintObject } from 'src/app/models/LabelPrintObject';




@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(private httpClient: HttpClient) { }

  getItems(item: any) {
    return this.httpClient.post<any>(constant.getBaseItemUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  getItemPriceHistory(item: any) {
    return this.httpClient.post<any>(constant.getItemPriceHistoryUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  getQuickAddItemsOnLoad(item: any) {
    return this.httpClient.post<any>(constant.getQuickAddItemsOnLoadUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
  getQuickAddItems(item: any) {
    return this.httpClient.post<any>(constant.getQuickAddItemsUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  reactivateDeletedItem(item: any) {
    return this.httpClient.post<any>(constant.reactivateDeletedItemUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  createQuickAddItems(item: any) {
    return this.httpClient.post<any>(constant.createQuickAddItemsUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
  updateQuickAddItems(item: any) {
    return this.httpClient.post<any>(constant.createQuickAddItemsUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
  deleteQuickAddItems(item: any) {
    return this.httpClient.post<any>(constant.deleteQuickAddItemsUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  createQuickAddGroups(item: any) {
    return this.httpClient.post<any>(constant.createUpdateQuickAddGroupUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  updateQuickAddGroups(item: any) {
    return this.httpClient.post<any>(constant.createUpdateQuickAddGroupUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  deleteQuickAddGroups(item: any) {
    return this.httpClient.post<any>(constant.deleteQuickAddGroupItemUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  getItemMonitoringCategories(item: any) {
    return this.httpClient.post<any>(constant.getItemMonitoringCategoriesUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  insertItemMonitoringCategories(item: any) {
    return this.httpClient.post<any>(constant.insertItemMonitoringCategoriesUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  updateItemMonitoringCategories(item: any) {
    return this.httpClient.post<any>(constant.updateItemMonitoringCategoriesUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  deleteItemMonitoringCategories(item: any) {
    return this.httpClient.post<any>(constant.deleteItemMonitoringCategoriesUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  createItem(item: any) {
    return this.httpClient.post<any>(constant.createItemUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  getAggregateItem(item: any) {

    return this.httpClient.post<any>(constant.getAggregateItemUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  updateItem(item: any) {

    return this.httpClient.post<any>(constant.updateItemUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  deleteItem(item: any) {

    return this.httpClient.post<any>(constant.deleteItemUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }


  insertDefaultItems(item: any) {

    return this.httpClient.post<any>(constant.insertDefaultItemUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  getItemsForPO(item: any) {
    return this.httpClient.post<any>(constant.getItemUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  generateLabels(item: any) {
    return this.httpClient.post<any>(constant.generateLabelsUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
  saveLabelTemplate(item: any) {
    return this.httpClient.post<any>(constant.saveLabelTemplateUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
  deleteLabelTemplate(item: any) {
    return this.httpClient.post<any>(constant.deleteLabelTemplateUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
  updateLabelTemplate(item: any) {
    return this.httpClient.post<any>(constant.updateLabelTemplateUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
  getByTemplateName(item: any) {
    return this.httpClient.post<any>(constant.getByTemplateNameUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }


    getAllAggregateItem(item: any) {

        return this.httpClient.post<any>(constant.getAllAggregateItemUrl, item, constant.httpClientHeader).pipe(
            map((response: any) => {

                return response;
            })
        )

    }


    updatePriceHistory(NPINO: any) {
        return this.httpClient.post<any>(constant.updatePriceHistory, NPINO, constant.httpClientHeader).pipe(
            map((response: any) => {

                return response;
            })
        )

    }


  createLabelPDF(labelData: LabelPrintObject[]) {
    return this.httpClient.post(constant.createLabelPDFUrl, labelData,{responseType:'blob'});

  }

  getItemsByDeptId(item: any) {
    return this.httpClient.post<any>(constant.getItemByIDList, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  getItemByNPI(item: any) {
    return this.httpClient.post<any>(constant.getAllNpiItemUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
  
  
  getItemVendorData(item: any) {
    return this.httpClient.post<any>(constant.getItemVendorDataUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
  
  createItemVendor(item: any) {
    return this.httpClient.post<any>(constant.insertItemVendorDataUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
  
  updateLastVendor(poData: any) {
    return this.httpClient.post<any>(constant.updateLastVendorDataUrl, poData, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }


  CheckItemCodeExists(item: any) {
    return this.httpClient.post<any>(constant.CheckItemCodeExistsUrl, item, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }


}
