import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sample-file-modal',
  templateUrl: './sample-file-modal.component.html',
  styleUrls: ['./sample-file-modal.component.css']
})
export class SampleFileModalComponent {
  constructor(
    public dialogRef: MatDialogRef<SampleFileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  downloadSampleFile() {
    const csvContent = this.data.sampleData.map((e: any) => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'Sample_File.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    this.dialogRef.close(); // Close modal after download
  }
}
