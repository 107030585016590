import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { authInterceptorProviders } from './services/utility/http-auth-interceptor-service';
import { PageNotFoundComponent } from './utility/pageNotFound/page-not-found.component';
import { ChangePasswordComponent } from './changePassword/change-password/change-password.component';
import { UnAuthorisePageComponent } from './utility/unAuthorisePage/un-authorise-page.component';
import { LoginWithoutCaptchaComponent } from './loginWithoutCaptcha/login-without-captcha.component';
import { HomeComponent } from './components/home/home.component';
import { ReportsComponent } from './Reports/reports.component';

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "login", component: LoginComponent },
  { path: "loginWithoutCaptcha", component: LoginWithoutCaptchaComponent },
  { path: "pageNotFound", component: PageNotFoundComponent },
  { path: "unAuthorisedPage", component: UnAuthorisePageComponent },
  {path:"changePassword",component:ChangePasswordComponent },
  {
    path: '',
    component: LayoutComponent,
    children: [

      {
        path: '',
        loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./Reports/reports.module').then(m => m.ReportsModule)
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
    providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, authInterceptorProviders],
  exports: [RouterModule]
})
export class AppRoutingModule { }
