import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { BaseHttpService } from 'src/app/utility/BaseHttpService';

@Injectable({
  providedIn: 'root'
})

export class ReceiptDesignerService {

  constructor(private baseHttpService: BaseHttpService) { }

  insertReceiptDesignData(designData: any) {
    return this.baseHttpService.post<any>(constant.insertDesignDataUrl, designData, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }
}
