import { SelectionModel } from "@angular/cdk/collections";
import { Component, HostListener, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { constant } from "src/app/constant/constant";
import { Customer } from "src/app/models/Customer";
import { userAccess } from "src/app/models/UserAccess";
import { CustomerService } from "src/app/services/Customer/customer.service";
import { UserService } from "src/app/services/User/user.service";
import { UtilityService } from "src/app/services/utility/utility.service";

@Component({
    selector: 'app-customer-search',
    templateUrl: './customer-search.component.html',
    styleUrls: ['./customer-search.component.scss']
})

export class CustomerSearchComponent implements OnInit {

    // itemDept: any={
    //     DeptCode:"",
    //     DeptName:""
    // }
    searchCust: any={
        customerCode:""
    }
    maxDate:Date=new Date()
    showTableForTransactionReport: boolean = true;
    custArray: any[] = [];
    userAccessData!: userAccess;
    selection = new SelectionModel<any>(true, []);

    @ViewChild(MatPaginator, { static: false })
    paginator!: MatPaginator;
    @ViewChild(MatSort)
    sort!: MatSort;
    displayedColumns = ['customerID','customerCode', 'firstName', 'lastName','cellNo','address1','address2','city'];
    pageSizeOptions: number[] = [10, 25, 50, 100];
    currentPage = 0;
    pageSize = 10;
    totalRows = 0;
    searchKey = "";
    custDataSource: MatTableDataSource<any> = new MatTableDataSource();
    selectedRow:any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private custService: CustomerService, private userService: UserService, private utilityService: UtilityService, private custDialogService: MatDialogRef<CustomerSearchComponent>,) {
        this.userAccessData = this.userService.getUsersAccessData();
        if (data.calledFrom) {
            switch (data.calledFrom) {

                case "SalesReport":
                    {
                        this.showTableForTransactionReport = true;
                        break;
                    }
                default: {

                }

            }
        }
        //this.searchCust.customerID = data.itemData.customerID;
        this.searchCustomer();
    }

    ngOnInit(): void {
        this.maxDate=new Date()
        this.maxDate.setHours(23);
        this.maxDate.setMinutes(59);
        this.maxDate.setSeconds(59);
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.custDataSource.data.length;
        return numSelected === numRows;
    }

   

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.custDataSource.data.forEach(row => this.selection.select(row));
    }

    AllowOnlyNumber(event: any) {

        var inputValue = String.fromCharCode(event.keyCode);
        // Allow numbers, alpahbets, space, underscore
        if (/[0-9]/.test(inputValue)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    pageChanged(event: PageEvent) {

        console.log({ event });
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.searchCustomer();
    }

    sendSelectedCust(custData: any) {
        this.custDialogService.close(custData);//this will close the dialoge for item search and send the data to subscriber
    }

    sendSelectedDepts() {

        this.custDialogService.close(this.selection.selected[this.selection.selected.length-1]);
        // if(this.selection.selected.length>1)
        // {
        //     this.custDialogService.close(this.selection.selected[this.selection.selected.length-1]);
        // }
        // else{
        //     this.custDialogService.close(this.selection.selected);
        // }
    }

    @HostListener('keydown.enter', ['$event'])
    onEnterKey(event: KeyboardEvent) {
        this.searchCustomer();
    }

    

    searchCustomer() {
        // setting pagination request object 
        this.searchCust.NPINo = this.userAccessData.NPINo;
        if(this.searchCust.dateOfBirthInStr!=undefined)
        {
            this.searchCust.dateOfBirth=new Date(moment(this.searchCust.dateOfBirthInStr).format('YYYY-MM-DD')).toDateString();
        }
       
        this.custService.getCustomersByNPI(this.searchCust).subscribe(response => {
            if (response.data) {
                this.custArray = response.data;

                if (this.custArray.length > 0) {
                    this.custDataSource = new MatTableDataSource<Customer>(this.custArray);
                    this.custDataSource.paginator = this.paginator;
                    this.custDataSource.sort = this.sort;
                } else {
                    this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "No data found");
                    this.custDataSource = new MatTableDataSource<any>(this.custArray);
                    this.custDataSource.paginator = this.paginator;
                    this.custDataSource.sort = this.sort;
                }
                
            }

        }, error => {

            this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while fetching the Customer details");
      
          });
    }

}