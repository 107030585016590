import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-clone-button',
  templateUrl: './table-clone-button.component.html',
  styleUrls: ['./table-clone-button.component.css']
})
export class TableCloneButtonComponent {

  @Input()
    imageSrc!: string;
    @Input()
    tooltip!: string;
    @Input() color: string = 'primary';
    @Output() buttonClick = new EventEmitter<void>();

    onClick() {
      this.buttonClick.emit();
    }

}
