import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { BaseHttpService } from 'src/app/utility/BaseHttpService';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private baseHttpService: BaseHttpService) { }

  getRoles(roleFilter: any) {
    return this.baseHttpService.post<any>(constant.getRoleUrl, roleFilter, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getRolesOnLoad(roleFilter: any) {
    return this.baseHttpService.post<any>(constant.getRolesOnLoadUrl, roleFilter, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  createRole(roleFilter: any) {
    return this.baseHttpService.post<any>(constant.createRoleUrl, roleFilter, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  updateRole(roleFilter: any) {
    return this.baseHttpService.post<any>(constant.updateRoleUrl, roleFilter, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  deleteRole(roleFilter: any) {
    return this.baseHttpService.post<any>(constant.deleteRoleUrl, roleFilter, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getModules() {
    return this.baseHttpService.get<any>(constant.getModulesUrl, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

}
