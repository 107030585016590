import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { ItemDepartment } from 'src/app/models/ItemDepartment';
import { itemDepartmentRequest } from 'src/app/models/RequestModel/itemDepartmentRequest';

@Injectable({
  providedIn: 'root'
})
export class ItemDepartmentService {

  constructor(private httpClient: HttpClient) { }

  
  getItemDepartment(itemDept: any) {
    return this.httpClient.post<any>(constant.getItemDepartmentUrl, itemDept, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }


  createItemDepartment(itemDept: any) {
    return this.httpClient.post<any>(constant.createItemDepartmentUrl, itemDept, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
  updateItemDepartment(itemDept: any) {
    return this.httpClient.post<any>(constant.updateItemDepartmentUrl, itemDept, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
  deleteItemDepartment(itemDept: any) {
    return this.httpClient.post<any>(constant.deleteItemDepartmentUrl, itemDept, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
  createDefaultItemDepartment(itemDept: any) {
    return this.httpClient.post<any>(constant.insertDefaultItemDepartmentUrl, itemDept, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  getItemDeptWithPagination(itemDept: any) {
    return this.httpClient.post<any>(constant.getItemDepartmentPaginationUrl, itemDept, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  
}
