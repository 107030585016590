import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';

import { HeaderComponent } from './layout/header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { MaterialModule } from './materialComponents/material.module';
import { PromptDialogComponent } from './utility/prompt-dialog/prompt-dialog.component';

import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import * as CanvasJSAngularChart from '../assets/canvasjs.angular.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChangePasswordComponent } from './changePassword/change-password/change-password.component';
import { LoginComponent } from './login/login.component';
import { LoginWithoutCaptchaComponent } from './loginWithoutCaptcha/login-without-captcha.component';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './services/utility/timeout-Interceptor';
import { SharedModule } from './shared/shared.module';
import { AlertDialogComponent } from './utility/alert-dialog/alert-dialog.component';
import { CountdownAlertComponent } from './utility/countdown-alert/countdown-alert.component';
import { ItemSearchComponent } from './utility/itemSearch/item-search.component';
import { PageNotFoundComponent } from './utility/pageNotFound/page-not-found.component';
import { PdfViewerComponent } from './utility/PDFViewer/pdf-viewer.component';
import { UnAuthorisePageComponent } from './utility/unAuthorisePage/un-authorise-page.component';
var CanvasJSChart = CanvasJSAngularChart.CanvasJSChart;

import { MatDialogModule } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatTableExporterModule } from 'mat-table-exporter';
import { PreviewDialogComponent } from './components/preview-dialog/preview-dialog.component';
import { RecieptDesignerComponent } from './components/reciept-designer/reciept-designer.component';
import { SampleFileModalComponent } from './sample-file-modal/sample-file-modal.component';
import { CustomerSearchComponent } from './utility/customerSearch/customer-search.component';
import { DepartmentSearchComponent } from './utility/departmentSearch/department-search.component';
import { ItemVendorAddModalComponent } from './utility/vendorItemAdd/item-vendor-add-modal.component';
import { VendorSearchComponent } from './utility/vendorSearch/vendor-search.component';
import { ReusableComponentsModule } from "./ReusableComponents/reusable-components.module";




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LayoutComponent,
    SidebarComponent,
    HeaderComponent,
    PromptDialogComponent,
    AlertDialogComponent,
    CountdownAlertComponent,
    PageNotFoundComponent,
    UnAuthorisePageComponent,
    ChangePasswordComponent,
    LoginWithoutCaptchaComponent,
    ItemSearchComponent,
    PdfViewerComponent,
    ItemVendorAddModalComponent,
    DepartmentSearchComponent,
    CustomerSearchComponent,
    VendorSearchComponent,
    PreviewDialogComponent,
    RecieptDesignerComponent,
    SampleFileModalComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxUiLoaderHttpModule.forRoot({
        showForeground: true,
    }),
    MatPasswordStrengthModule.forRoot(),
    RecaptchaFormsModule,
    RecaptchaModule,
    MatTableExporterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    ReusableComponentsModule
],
  providers: [
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    [{ provide: DEFAULT_TIMEOUT, useValue: 150000 }],
    [{ provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.recaptcha.siteKey } as RecaptchaSettings, },],
    [{ provide: DatePipe}],
    [{provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}}]

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
