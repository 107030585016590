import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map} from 'rxjs';
import { constant } from 'src/app/constant/constant';




@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(private httpClient: HttpClient) { }

 

  getVendorsByNPINo(vendor: any) {
    return this.httpClient.post<any>(constant.getVendorsByNPINoUrl, vendor, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getVendorsWithContractByNPINo(vendor: any) {
    return this.httpClient.post<any>(constant.getVendorsWithContractByNPINoUrl, vendor, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  
}
