import { Injectable } from '@angular/core';
import { constant } from 'src/app/constant/constant';
import { environment } from 'src/environments/environment';

export interface SubChildMenu {
  state: string;
  name: string;
  type: string;
  icon: string;
}

export interface ChildMenu {
  state: string;
  name: string;
  type: string;
  icon: string;
  isHovered: boolean;
  subChildMenu: SubChildMenu[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  imageUrl: string;
  expanded: boolean;
  isHovered: boolean;
  childMenu: ChildMenu[];
}


var retMenuData: Menu[] = [];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return retMenuData;
  }
  userRights: any = {};
  stationDetails: any;
  abc: any = {};
  childMenuData: ChildMenu[] = [];
  subChildMenuData: SubChildMenu[] = [];


  setAccessLinks() {

    retMenuData.length = 0;

    //IF THE USER IS NOT LOGGED IN THEN SESSION WILL BE NULL CHECKING THAT

    if (sessionStorage.getItem(constant.KEY_USER_RIGHTS)) {

      this.stationDetails = JSON.parse(sessionStorage.getItem(constant.KEY_STATION_DETAILS) || "");

      //FIRST GET THE RIGHTS FROM THE SESSION AND THEN ADD LINKS
      this.userRights = JSON.parse(sessionStorage.getItem(constant.KEY_USER_RIGHTS) || "");

      let webAdminRight = this.userRights.webRights.webAdministrativeFunction;

      //#region Administrative
      this.childMenuData = [];

      if (this.stationDetails != null && this.stationDetails != undefined && this.stationDetails != "") {
        //storeView

        if (this.userRights && webAdminRight.webAdminCompanySetup != null && (webAdminRight.webAdminCompanySetup.add == true || webAdminRight.webAdminCompanySetup.update == true || webAdminRight.webAdminCompanySetup.delete == true || webAdminRight.webAdminCompanySetup.search == true)) {
          this.childMenuData.push({ state: 'store', type: 'link', name: 'Store', icon: 'tab',isHovered: false, subChildMenu: [] });
        }

        if (this.userRights && webAdminRight.webAdminCustomer != null && (webAdminRight.webAdminCustomer.add == true || webAdminRight.webAdminCustomer.update == true || webAdminRight.webAdminCustomer.delete == true || webAdminRight.webAdminCustomer.search == true)) {

          this.childMenuData.push({ state: 'customer', type: 'link', name: 'Customers', icon: 'tab', isHovered: false, subChildMenu: [] });
        }

        if (this.userRights && webAdminRight.webAdminUser != null && (webAdminRight.webAdminUser.add == true || webAdminRight.webAdminUser.update == true || webAdminRight.webAdminUser.delete == true || webAdminRight.webAdminUser.search == true)) {

          this.childMenuData.push({ state: 'user', type: 'link', name: 'Users', icon: 'tab', isHovered: false, subChildMenu: [] });
        }

        if (this.userRights && webAdminRight.webAdminRole != null && (webAdminRight.webAdminRole.add == true || webAdminRight.webAdminRole.update == true || webAdminRight.webAdminRole.delete == true || webAdminRight.webAdminRole.search == true)) {

          this.childMenuData.push({ state: 'role', type: 'link', name: 'Roles', icon: 'view_headline', isHovered: false, subChildMenu: [] });
        }

        if (this.userRights && webAdminRight.manageRights == true) {

          this.childMenuData.push({ state: 'manage-rights', type: 'link', name: 'Manage Rights', icon: 'tab', isHovered: false, subChildMenu: [] });
        }

        if (this.userRights && webAdminRight.resetStation == true) {

          this.childMenuData.push({ state: 'reset-station', type: 'link', name: 'Reset Station', icon: 'tab', isHovered: false, subChildMenu: [] });
        }

        if (this.userRights && webAdminRight.webRecon == true) {

          this.childMenuData.push({ state: 'recon', type: 'link', name: 'Recon', icon: 'tab', isHovered: false, subChildMenu: [] });
        }


        if (this.userRights && webAdminRight.webTax != null && (webAdminRight.webTax.add == true || webAdminRight.webTax.update == true || webAdminRight.webTax.delete == true || webAdminRight.webTax.search == true)) {

          this.childMenuData.push({ state: 'tax', type: 'link', name: 'Taxes', icon: 'tab', isHovered: false, subChildMenu: [] });
        }

        if (this.userRights && webAdminRight.webScheduler) {

          this.childMenuData.push({ state: 'scheduler', type: 'link', name: 'Scheduler', icon: 'tab', isHovered: false, subChildMenu: [] });
        }

        //Suggestive selling
        this.childMenuData.push({ state: 'suggestiveselling', type: 'link', name: 'Suggestive Selling', icon: 'tab', isHovered: false, subChildMenu: [] });

        if (this.childMenuData.length > 0) {
          retMenuData.push({ state: 'administrative', type: 'text', name: 'Admin Function', icon: 'admin_panel_settings', expanded: false, imageUrl: 'assets/images/icons/icon-admin.png', isHovered: false, childMenu: this.childMenuData });
        }

        //}
        //#endregion Administrative

        //#region EOD
        this.childMenuData = [];
        
        if (this.userRights && webAdminRight.EOD == true) {

          this.childMenuData.push({ state: 'EOD-Reports', type: 'link', name: 'EOD Reports', icon: 'tab', isHovered: false, subChildMenu: [] });
        }

        if (this.userRights && webAdminRight.webEODHistory == true) {

          this.childMenuData.push({ state: 'EOD-History', type: 'link', name: 'EOD History', icon: 'tab', isHovered: false, subChildMenu: [] });
        }

        if (this.childMenuData.length > 0) {
          retMenuData.push({ state: 'EOD', type: 'text', name: 'EOD', icon: 'grain', expanded: false, imageUrl: 'assets/images/icons/icon-eod.png', isHovered: false, childMenu: this.childMenuData });
        }
        //#endregion EOD

        //#region Item Function
        this.childMenuData = [];

        if (this.userRights && webAdminRight.webAdminItem != null && (webAdminRight.webAdminItem.add == true || webAdminRight.webAdminItem.update == true || webAdminRight.webAdminItem.delete == true || webAdminRight.webAdminItem.search == true)) {


          this.childMenuData.push({ state: 'item', type: 'link', name: 'Items', icon: 'view_comfy', isHovered:false, subChildMenu: [] });
        }

        if (this.userRights && webAdminRight.webAdminItemDepartment != null && (webAdminRight.webAdminItemDepartment.add == true || webAdminRight.webAdminItemDepartment.update == true || webAdminRight.webAdminItemDepartment.delete == true || webAdminRight.webAdminItemDepartment.search == true)) {

          this.childMenuData.push({ state: 'item-department', type: 'link', name: 'Item Departments', icon: 'view_list', isHovered:false, subChildMenu: [] });
        }

        if (this.userRights && webAdminRight.webItemMonitoringCategory == true) {

          this.childMenuData.push({ state: 'item-monitoring-category', type: 'link', name: 'Item Monitoring Categories', icon: 'tab', isHovered:false, subChildMenu: [] });
        }

        if (this.userRights && webAdminRight.webItemPriceLogLabel == true) {
          this.childMenuData.push({ state: 'itemPriceLogLabels', type: "link", name: 'Item Price Log Labels', icon: 'tab', isHovered:false, subChildMenu: [] });
        }


        if (this.userRights && webAdminRight.itemPriceHistory == true) {

          this.childMenuData.push({ state: 'itemPriceHistory', type: 'link', name: 'Item Price History', icon: 'tab', isHovered:false, subChildMenu: [] });
        }

        if (this.userRights && webAdminRight.webQuickAddOrder != null && (webAdminRight.webQuickAddOrder.add == true || webAdminRight.webQuickAddOrder.update == true || webAdminRight.webQuickAddOrder.delete == true || webAdminRight.webQuickAddOrder.search == true)) {

          this.childMenuData.push({ state: 'quickAddItems', type: 'link', name: 'Quick Add Items', icon: 'tab', isHovered:false, subChildMenu: [] });
        }

        if (this.userRights && webAdminRight.webQuickAddGroup != null && (webAdminRight.webQuickAddGroup.add == true || webAdminRight.webQuickAddGroup.update == true || webAdminRight.webQuickAddGroup.delete == true || webAdminRight.webQuickAddGroup.search == true)) {

          this.childMenuData.push({ state: 'quickAddGroup', type: 'link', name: 'Quick Add Groups', icon: 'tab', isHovered:false, subChildMenu: [] });
        }

        if (this.childMenuData.length > 0) {
          retMenuData.push({ state: 'item', type: 'text', name: 'Item Function', icon: 'grain', expanded: false, imageUrl: 'assets/images/icons/icon-item.png', isHovered: false, childMenu: this.childMenuData });
        }

        //#endregion Item Function

        //#region Reports

        this.childMenuData = [];
        this.subChildMenuData = [];


        if (this.userRights && webAdminRight.webCustomerSalesReport == true) {
          this.childMenuData.push({ state: 'reports/sales-report-customer', type: 'link', name: 'Sale By Customer', icon: 'tab',isHovered:false, subChildMenu: this.subChildMenuData });
        }

        if (this.userRights && webAdminRight.webDepartmentSalesReport == true) {
          this.childMenuData.push({ state: 'reports/sales-report-department', type: 'link', name: 'Sale By Department', icon: 'tab' ,isHovered:false, subChildMenu: this.subChildMenuData});
        }

        if (this.userRights && webAdminRight.webSalesItemReport == true) {
          this.childMenuData.push({ state: 'reports/sales-report-by-item', type: 'link', name: 'Sale By Item', icon: 'tab' ,isHovered:false, subChildMenu: this.subChildMenuData});
        }

        if (this.userRights && webAdminRight.webSalesPaymentReport == true) {
          this.childMenuData.push({ state: 'reports/sales-report-by-payment', type: 'link', name: 'Sale By Payment', icon: 'tab' ,isHovered:false, subChildMenu: this.subChildMenuData});
        }

        if (this.userRights && webAdminRight.webSalesUserReport == true) {
          this.childMenuData.push({ state: 'reports/sales-report-by-user', type: 'link', name: 'Sale By User', icon: 'tab' ,isHovered:false, subChildMenu: this.subChildMenuData});
        }

        // if (this.userRights && this.subChildMenuData.length > 0) {
        //   this.childMenuData.push({ state: 'salesreports', type: 'text', name: 'Sales', icon: 'tab', isHovered:false, subChildMenu: this.subChildMenuData });
        // }

        // this.subChildMenuData = [];
        if (this.userRights && webAdminRight.storeView) {
          this.childMenuData.push({ state: 'store-view', type: 'link', name: 'Store View', icon: 'tab', isHovered:false, subChildMenu: this.subChildMenuData });
        }

        if (this.userRights && webAdminRight.webTransactionReport == true) {
          this.childMenuData.push({ state: 'reports/transaction-report', type: "link", name: 'Transaction Details Report', icon: 'tab', isHovered:false, subChildMenu: this.subChildMenuData });
        }

        this.subChildMenuData = [];
        if (this.userRights && webAdminRight.webItemPriceChangeReport == true) {
          this.childMenuData.push({ state: 'reports/item-price-change-log-report', type: "link", name: 'Item Price Change Report', icon: 'tab', isHovered:false, subChildMenu: this.subChildMenuData });
        }


        if (this.userRights && webAdminRight.webPayOutReport == true) {
          this.childMenuData.push({ state: 'reports/payout-report', type: "link", name: 'Payout Details Report', icon: 'tab', isHovered:false, subChildMenu: this.subChildMenuData });
        }

        if (this.userRights && webAdminRight.webPriceOverrideReport == true) {
          this.childMenuData.push({ state: 'reports/price-override-report', type: "link", name: 'Price Overridden Report', icon: 'tab', isHovered:false, subChildMenu: this.subChildMenuData });
        }




        if (this.userRights && webAdminRight.webDiscountReport == true) {
          this.childMenuData.push({ state: 'reports/discount-report', type: "link", name: 'Discount Report', icon: 'tab', isHovered:false, subChildMenu: this.subChildMenuData });
        }

        if (this.userRights && webAdminRight.webInventoryRecdReport == true) {
          this.childMenuData.push({ state: 'reports/inventory-received-report', type: 'link', name: 'Inventory Received', icon: 'tab', isHovered:false, subChildMenu: this.subChildMenuData });
        }
        if (this.userRights && webAdminRight.webInventoryStatusReport == true) {
          this.childMenuData.push({ state: 'reports/inventory-status-report', type: 'link', name: 'Inventory Status', icon: 'tab', isHovered:false, subChildMenu: this.subChildMenuData });
        }
        // if (this.userRights && this.subChildMenuData.length > 0) {
        //   this.childMenuData.push({ state: 'inventoryreports', type: 'text', name: 'Inventory', icon: 'tab', isHovered:false, subChildMenu: this.subChildMenuData });
        // }

        if (this.userRights && (webAdminRight.webCustomerSalesReport == true || webAdminRight.webDepartmentSalesReport == true
          || webAdminRight.webSalesItemReport == true || webAdminRight.webSalesPaymentReport == true || webAdminRight.webSalesUserReport == true
          || webAdminRight.storeView || webAdminRight.webTransactionReport == true || webAdminRight.webItemPriceChangeReport == true
          || webAdminRight.webPayOutReport == true || webAdminRight.webPriceOverrideReport == true || webAdminRight.webDiscountReport == true
          || webAdminRight.webInventoryRecdReport == true || webAdminRight.webInventoryStatusReport == true)
        ) {
          if (this.userRights && this.childMenuData.length > 0) {
            // this.childMenuData.push({ state: 'Tax Over Ride Report', type: "link", name: 'reports/tax-override-report', icon: 'tab', subChildMenu: this.subChildMenuData });
            retMenuData.push({ state: 'reports', type: 'text', name: 'Reports', icon: 'table_chart', expanded: false, imageUrl: 'assets/images/icons/icon-reports.png', isHovered: false, childMenu: this.childMenuData });
          }
        }



        //#endregion Reports

        //#region Inventory Files
        this.childMenuData = [];
        if (this.userRights && webAdminRight.webPurchaseOrder == true) {
          this.childMenuData.push({ state: 'purchaseOrder', type: 'link', name: 'Purchase Order', icon: 'tab', isHovered:false, subChildMenu: [] });
        }
        if (this.userRights && webAdminRight.webPurchaseOrderAck == true) {
          this.childMenuData.push({ state: 'purchaseOrderAck', type: 'link', name: 'Purchase Order Ack', icon: 'tab', isHovered:false, subChildMenu: [] });
        }
        if (this.userRights && webAdminRight.webManualFileUpdate == true) {
          this.childMenuData.push({ state: 'manual-file-update', type: 'link', name: 'Manual File Update', icon: 'tab', isHovered:false, subChildMenu: [] });
        }

        if (this.userRights && webAdminRight.web810FileProcessing == true) {
          this.childMenuData.push({ state: 'file-upload', type: 'link', name: '810 File Processing', icon: 'tab',isHovered:false, subChildMenu: [] });
        }

        if (this.userRights && webAdminRight.WebAdminInventoryReceived == true) {
          this.childMenuData.push({ state: 'inventoryReceived', type: 'link', name: 'Inventory Received', icon: 'tab',isHovered:false, subChildMenu: [] });
        }

        if (this.userRights && this.childMenuData.length > 0) {
          retMenuData.push({ state: 'inventory', type: 'text', name: 'Inventory Files', icon: 'Inventory Files', expanded: false, imageUrl: 'assets/images/icons/icon-inventory.png', isHovered: false, childMenu: this.childMenuData });
        }
        //#endregion

        //#region CLP
        this.childMenuData = [];
        if (this.userRights && webAdminRight.webClpSettings == true) {
          this.childMenuData.push({ state: 'clp-settings', type: "link", name: 'CLP Settings', icon: 'tab', isHovered:false, subChildMenu: [] });
        }
        //EPPOS-3640
        // if (this.userRights && this.childMenuData.length > 0) {
        //   retMenuData.push({ state: 'clp', type: 'text', name: 'CLP', icon: 'badge', expanded: false, imageUrl: 'assets/images/icons/icon-edi.png', isHovered: false, childMenu: this.childMenuData });
        // }
        //#endregion CLP

        //#region Label
        //EPPOS-4021-Commented to avoid issues because of fabric upgradation
        // this.childMenuData = [];
        // if (this.stationDetails != null && this.stationDetails != undefined && this.stationDetails != "") {

        //   if (this.userRights && webAdminRight.webLabelDesign == true) {
        //     this.childMenuData.push({ state: 'labelDesign', type: 'link', name: 'Label Design', icon: 'tab', isHovered:false, subChildMenu: [] });
        //   }

        // }
        // if (this.userRights && this.childMenuData.length > 0) {
        //   retMenuData.push({ state: 'Label Design', type: 'text', name: 'Label Design', icon: 'Label Design', expanded: false, imageUrl: 'assets/images/icons/icon-label.png', isHovered: false, childMenu: this.childMenuData });
        // }
        //#endregion Label

        //#region Reciept Design
        if (!environment.production) {
          if (this.stationDetails != null && this.stationDetails != undefined && this.stationDetails != "") {
            this.childMenuData = [];
            // Add the receipt design menu if the user has the appropriate rights
            if (this.userRights) {
              this.childMenuData.push({ state: 'receipt-designer', type: 'link', name: 'Sale Receipt', icon: 'receipt_long', isHovered: false, subChildMenu: [] });
            }

            // Add receipt design data to retMenuData if childMenuData is populated
            if (this.userRights && this.childMenuData.length > 0) {
              retMenuData.push({
                state: 'Receipt Design',
                type: 'text',
                name: 'Receipt Design',
                icon: 'Receipt Design',
                expanded: false,
                imageUrl: 'assets/images/icons/receiptDesign.png',
                isHovered: false,
                childMenu: this.childMenuData
              });
            }
          }
        }
        //#endregion

        for (let menu of retMenuData) {
          menu.expanded = false; // Reset all menu items first
      }
      
      const expandedItem = sessionStorage.getItem('expandedItem');
      const isExpandedItem = sessionStorage.getItem('isExpandedItem');
      
      if (expandedItem && isExpandedItem) {
          try {
              const Item = JSON.parse(expandedItem);
              const state = JSON.parse(isExpandedItem) === true; // Ensure it's a boolean
      
              if (state) {
                  const targetMenu = retMenuData.find(menu => menu.name === Item.name);
                  if (targetMenu) {
                      targetMenu.expanded = true;
                  }
              }
          } catch (error) {
              console.error("Error parsing session storage data:", error);
          }
      }  

      }
      else {
        this.childMenuData.push({ state: 'store', type: 'link', name: 'Store', icon: 'tab', isHovered:false, subChildMenu: [] });
        if (this.childMenuData.length > 0) {
          retMenuData.push({ state: 'administrative', type: 'text', name: 'Admin Function', icon: 'admin_panel_settings', expanded: false, imageUrl: 'assets/images/icons/icon-edi.png', isHovered: false, childMenu: this.childMenuData });
        }
      }
    }
  }
}
