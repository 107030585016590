import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ChangePasswordComponent } from 'src/app/changePassword/change-password/change-password.component';
import { UserModalComponent } from 'src/app/components/user/user-modal/user-modal.component';
import { userAccess } from 'src/app/models/UserAccess';
import { UserService } from 'src/app/services/User/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  userAccessData!: userAccess;//this variable will hold user data
  notifications: string[] = [];

  constructor(private router: Router, private dialogService: MatDialog, private userService: UserService, private utilityService: UtilityService) { }


  ngOnInit(): void {
    this.userAccessData = this.userService.getUsersAccessData();
    this.checkStoreUpdate();
  }

  logoutUser() {
    //clearing the session from the storage
    this.userService.logOutUser();

  }

  changePassword() {

    const taxModalRef = this.dialogService.open(ChangePasswordComponent, {
      width: '40%',
      disableClose: false,
      data: { headerTitle: "Change Password",userData:''}
    });
  }

  editLoggedinUserProfile() {

    let userData = {
      userName: this.userAccessData.userName
    };

    this.userService.getUserByUserName(userData).subscribe(response => {

      if (response.result == "SUCCESS") {
        var receivedUserResponse = JSON.parse(response.data);

        this.dialogService.open(UserModalComponent, {
          width: '50%',
          disableClose: false,
          data: { userData: receivedUserResponse, headerTitle: "Edit User" }
        });

      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error while fetching the user data"
        );
      }

    });


  }

  checkStoreUpdate() {
    this.notifications = [];
    const storeDetails = JSON.parse(sessionStorage.getItem('storeDetails') || 'null');

    if (!storeDetails || (!storeDetails.rxLink && !storeDetails.primeRxCloudURL)) {
      this.notifications.push('⚠️ Pending store update.');
    }
  }

  clearNotifications() {
    this.notifications = []; // Clears the notifications array
}

}
