import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css']
})
export class PageHeaderComponent {
  @Input()
  icon!: string;
  @Input()
  title!: string;
  @Input()
  breadcrumb!: string;
  @Input() iconColor: string = 'primary'; // Default color for the icon
}
