import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
declare const PDFObject: any;
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit {

  pdfData: any = {};
  url: any = {};
  pdfurl: any = {};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.pdfData = data.pdfData;
    let blob: Blob = data.pdfData as Blob;
    this.url = window.URL.createObjectURL(blob);
    window.open(this.url);
  }

  ngOnInit(): void {

    
    this.pdfurl = this.url;

    if (this.pdfData) {
      this.handleRenderPdf(this.pdfData);
    }
  }

  handleRenderPdf(data: any) {
    const pdfObject = PDFObject.embed(data, '#pdfContainer');
  }

  pdfBase64 ='';

}
