import { Store } from './../../models/Store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, timeout } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { UtilityService } from '../utility/utility.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private httpClient: HttpClient, private utilityService: UtilityService) { }

  getStoreByNPI(userNPI: string) {
    return this.httpClient.post<any>(constant.getStoreByNPI, { NPINo: userNPI }, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )

  }

  getStoresForNPI(userNPI: string) {
    return this.httpClient.post<any>(constant.getStoresForNPI, { NPINo: userNPI }, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )

  }
  getEODHistory(userNPI: string) {
    return this.httpClient.post<any>(constant.getEODHistoryByNPI, { NPINo: userNPI }, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )

  }

  getConfiguredStations(userNPI: string) {
    return this.httpClient.post<any>(constant.getConfiguredStations, { NPINo: userNPI }, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )

  }

  resetStation(store: any) {
    return this.httpClient.post<any>(constant.resetStationByNPI, store, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )

  }

  fetchEODData(NPI: any) {
    return this.httpClient.post<any>(constant.fetchEODDataUrl, { NPINo: NPI }, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        console.log(err);
        
        return err;
      })
    )
  }

  checkEODEligibility(NPI: any) {
    return this.httpClient.post<any>(constant.checkEODEligibilityUrl, { NPINo: NPI }, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        console.log(err);
        
        return err;
      })
    )
  }
  
  saveEODData(NPI: any) {

    return this.httpClient.post<any>(constant.saveEODUrl, { NPINo: NPI }, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        console.log(err);
        
        return "";
      })
    )
  }
  
  handleTimeOut(): any {
    this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_TIMEOUT_TITLE, "Api calling has been timed out");
  }
  updateStore(store :any){
    return this.httpClient.post<any>(constant.updateStoreUrl, store, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )
  }
  getStoreDataFromStoreConfig(user :any){
    return this.httpClient.post<any>(constant.fetchStoreDataFromStoreConfigUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  changePasswordInStoreConfig(user :any){
    return this.httpClient.post<any>(constant.changePasswordWithCheckboxInStoreConfigUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  updatePointyData(store :any){
    return this.httpClient.post<any>(constant.updatePointyDataUrl, store, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )
  }
}
