<div class="login-main-container">


  <div fxLayout="column" fxLayoutAlign="space-evenly center">

    <div>

      <form #loginUserForm="ngForm" autocomplete="off">
        <div class="form-group row" style="padding-top: 25%;">
          <div style="text-align: left;" oncontextmenu="return false;">
            <img width="210px" width="300px"
              src="assets/images/logos/PrimeRx_POS_Logo-032224_Full-Logo-Full-Color-V1.jpg">
          </div>
        </div>
        <div class="form-group row" style="padding-top: 20px;">
          <!-- <div> -->
          <mat-form-field appearance="outline" style="border-radius: 0px;">
            <input matInput placeholder="USER NAME" name="UserName" #UserName="ngModel" id="UserName"
              style="width: 300px;background-color: transparent;" [(ngModel)]="user.userName" required>

            <mat-error *ngIf="UserName.invalid && (UserName.dirty || UserName.touched)">
              Please enter user name
            </mat-error>
            <!-- <div *ngIf="UserName.invalid && (UserName.dirty || UserName.touched)"
                  id="UserNameError">
                  Please enter user name
                </div> -->
          </mat-form-field>

          <!-- </div> -->
          <div class="col-md-2"></div>
        </div>
        <div class="form-group row">
          <div>

            <!-- <mat-form-field appearance="outline" class="custom-mat-form-field">
                <input matInput placeholder="PASSWORD" name="password" #password="ngModel" id="password" style="width: 300px;"
                  [(ngModel)]="user.password" required [type]="hide ? 'password' : 'text'">

                <mat-icon class="pointer" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
                </mat-icon>

                <mat-error *ngIf="password.invalid && (password.dirty || password.touched)" class="invalid-feedback">
                  Please enter password
                </mat-error>
                <div *ngIf="UserName.invalid && (UserName.dirty || UserName.touched)" class="invalid-feedback"
                  id="passwordError">
                  Please enter password
                </div>
              </mat-form-field> -->
            <mat-form-field appearance="outline" class="custom-mat-form-field">
              <input matInput placeholder="PASSWORD" name="password" #password="ngModel" id="password"
                style="width: 300px;" [(ngModel)]="user.password" required [type]="hide ? 'password' : 'text'">

              <!-- Replace mat-icon with img tag -->
              <img class="pointer" *ngIf="!hide" width="24px" style="text-align: right;" matSuffix
                src="assets/images/icons/icon-visibility-ON.png" alt="Toggle Password Visibility"
                (click)="hide = !hide">
              <img class="pointer" *ngIf="hide" width="24px" style="text-align: right;" matSuffix
                src="assets/images/icons/icon-visibility-OFF.png" alt="Toggle Password Visibility"
                (click)="hide = !hide">

              <!-- <mat-error *ngIf="password.invalid && (password.dirty || password.touched)" class="invalid-feedback">
                  Please enter password
                </mat-error> -->
              <mat-error *ngIf="password.invalid && (password.dirty || password.touched)">
                Please enter password
              </mat-error>
              <!-- <div *ngIf="UserName.invalid && (UserName.dirty || UserName.touched)" class="invalid-feedback"
                  id="passwordError">
                  Please enter password
                </div> -->
            </mat-form-field>


          </div>

        </div>

        <div class="form-group row">
          <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required
            [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)">
          </re-captcha>

          <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="invalid-feedback">

            <div *ngIf="recaptcha.errors?.['required']">
              <mat-error *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)">
                Please verify that you are not a robot
              </mat-error>
            </div>
          </div>
        </div>

        <div class="form-group row" style="padding-top: 10px;">
          <div style="padding-top: 6px; text-align: center;">
            <button id="loginBtn" mat-raised-button [disabled]="loginUserForm.invalid" style="width: 150px; height: 50px; font-size: 16px; font-weight: normal;
              color: #FFFFFF; background-color: #0C7BB8; opacity: 1;
              {{ loginUserForm.invalid ? 'background-color:  #ADADAD;' : '' }}" (click)="loginUser();"
              color="accent">Login</button>
          </div>
        </div>
        <!-- <div class="form-group row">
            <div style="padding-top: 26px;text-align: center;">
              <mat-label style=" float: center; cursor:pointer;font-size: 13px;font-weight: normal;" (click)="resetPassword()">Forgot
                Password ? </mat-label>
            </div>
          </div> -->
        <div class="form-group row" style="padding-bottom: 5px;">
          <div style="padding-top: 26px;text-align: center;">
            <mat-label style=" float: center; cursor:auto;font-size: 13px;font-weight: normal;">
              © 2025 Micro Merchant Systems, Inc.
              <br>
              Version P3v5.1 All rights reserved.
            </mat-label>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
