<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->

<!-- <button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5"> <img src="assets/images/users/1.jpg" alt="user"
        class="profile-pic"> </button>
<mat-menu #profile="matMenu" class="mymegamenu">

    <button mat-menu-item class="text-info">
        <mat-icon color="primary">account_box</mat-icon> <b>{{userAccessData.userName}}</b>

    </button>
    
    <button mat-menu-item (click)="editLoggedinUserProfile()">
        <mat-icon color="primary">mode_edit</mat-icon> Edit User
    </button>
    <button mat-menu-item (click)="changePassword()">
        <mat-icon color="primary">settings</mat-icon> Change Password
    </button>
    <button mat-menu-item (click)="logoutUser()">
        <mat-icon color="primary">exit_to_app</mat-icon> Sign Out
    </button>
</mat-menu> -->

<button mat-icon-button [matMenuTriggerFor]="notificationMenu" class="notification-button">
    <mat-icon color="primary">notifications</mat-icon>
    <span *ngIf="notifications.length > 0" class="notification-badge">{{ notifications.length }}</span>
</button>
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5"> <mat-icon color="primary">account_circle</mat-icon> </button>
<mat-menu #profile="matMenu" class="mymegamenu">

    <button mat-menu-item class="text-info">    
        <mat-icon color="primary">account_box</mat-icon> <b>{{userAccessData.userName}}</b>

    </button>
    
    <button mat-menu-item (click)="editLoggedinUserProfile()">
        <mat-icon color="primary">mode_edit</mat-icon> Edit User
    </button>
    <button mat-menu-item (click)="changePassword()">
        <mat-icon color="primary">settings</mat-icon> Change Password
    </button>
    
    <button mat-menu-item (click)="logoutUser()">
        <mat-icon>exit_to_app</mat-icon> Logout </button>
</mat-menu>

<mat-menu #notificationMenu="matMenu">
    <div class="notification-list">
        <ng-container *ngIf="notifications.length > 0; else noNotifications">
            <div *ngFor="let notification of notifications" class="notification-item">
                <!-- <mat-icon class="notification-icon">notifications_active</mat-icon> -->
                <span>{{ notification }}</span>
            </div>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="clearNotifications()">
                <mat-icon>clear_all</mat-icon> Clear All
            </button>
        </ng-container>
    </div>

    <!-- No Notifications Template -->
    <ng-template #noNotifications>
        <div class="no-notifications">
            <mat-icon color="gray">notifications_off</mat-icon>
            <p>No new notifications</p>
        </div>
    </ng-template>
</mat-menu>
