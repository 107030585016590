import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { BaseHttpService } from 'src/app/utility/BaseHttpService';

@Injectable({
  providedIn: 'root'
})

export class StoreService {

  constructor(private baseHttpService: BaseHttpService) { }

  getStoreByNPI(userNPI: string) {
    return this.baseHttpService.post<any>(constant.getStoreByNPI, { NPINo: userNPI }, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getStoresForNPI(userNPI: string) {
    return this.baseHttpService.post<any>(constant.getStoresForNPI, { NPINo: userNPI }, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getEODHistory(userNPI: string) {
    return this.baseHttpService.post<any>(constant.getEODHistoryByNPI, { NPINo: userNPI }, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getConfiguredStations(userNPI: string) {
    return this.baseHttpService.post<any>(constant.getConfiguredStations, { NPINo: userNPI }, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )

  }

  resetStation(store: any) {
    return this.baseHttpService.post<any>(constant.resetStationByNPI, store, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )

  }

  fetchEODData(NPI: any) {
    return this.baseHttpService.post<any>(constant.fetchEODDataUrl, { NPINo: NPI }, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  checkEODEligibility(NPI: any) {
    return this.baseHttpService.post<any>(constant.checkEODEligibilityUrl, { NPINo: NPI }, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  saveEODData(NPI: any) {
    return this.baseHttpService.post<any>(constant.saveEODUrl, { NPINo: NPI }, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        console.log(err);

        return "";
      })
    )
  }

  updateStore(store :any){
    return this.baseHttpService.post<any>(constant.updateStoreUrl, store, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getStoreDataFromStoreConfig(user :any){
    return this.baseHttpService.post<any>(constant.fetchStoreDataFromStoreConfigUrl, user, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  changePasswordInStoreConfig(user :any){
    return this.baseHttpService.post<any>(constant.changePasswordWithCheckboxInStoreConfigUrl, user, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  updatePointyData(store :any){
    return this.baseHttpService.post<any>(constant.updatePointyDataUrl, store, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }
}
