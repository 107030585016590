import { StoreService } from 'src/app/services/Store/store.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoleService } from 'src/app/services/Role/role.service';
import { UserService } from 'src/app/services/User/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { constant } from 'src/app/constant/constant';
import { userAccess } from 'src/app/models/UserAccess';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.css']
})
export class UserModalComponent implements OnInit {

  user: any = {};
  roleArray: any[] = [];
  drawerArray!: any[];
  hide: boolean = true;
  hideConfirm: boolean = true;
  showDetails: boolean = true;
  showError: boolean = false;
  panelOpenState: boolean = false;
  isPasswordStrong: boolean = false;
  deleteUserData: boolean = false;
  pseudoName: any = "";//used for showing in the modal form
  webRights: any = {}

  userAccessData!: userAccess;

  hidePass: boolean = true;
  hideConfirmPass: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public receivedData: any, private dialogService: MatDialogRef<UserModalComponent>,
    private utilityService: UtilityService, private roleService: RoleService,
    private userService: UserService, private storeService: StoreService) {

    this.userAccessData = this.userService.getUsersAccessData();
    var userRights = JSON.parse(this.userAccessData.userRights);
    this.webRights = userRights.webRights;

    if (receivedData.userData) {
      this.user = receivedData.userData;
      this.user.confirmPassword = receivedData.userData.password;
    }
  }

  ngOnInit(): void {
    this.pseudoName = this.userAccessData.pseudoName;
    this.user.NPINo = this.userAccessData.NPINo;
  }

  ngAfterViewInit() {
    //calling role api to load in role dropdown
    this.getRoles();
    this.getConfiguredDrawers();
  }

  onPasswordStrengthChanged(event: any) {
    if (event == 100)
      this.isPasswordStrong = true;

  }
  roleChange(event: any) {
    console.log(event)
    var countryObj = this.roleArray.find(stat => stat.roleName == event);

    this.user.roleId = countryObj.roleID;
    this.user.role = countryObj.roleName
  }

  //===========api for getting roles===========
  getRoles() {

    let roleFilter = {
      isActive: true,
      NPINo: this.userAccessData.NPINo
    };
    this.roleService.getRoles(roleFilter).subscribe(response => {

      if (response.result == constant.KEY_SUCCESS) {
        var receivedUserResponse = JSON.parse(response.data);

        this.roleArray = receivedUserResponse;
        if (this.user != undefined && this.user.role != undefined && this.user.role != "") {

          var matchedRole = this.roleArray.find(stat => stat.roleName == this.user.role);
          this.user.roleId = matchedRole.roleID;
          this.user.role = matchedRole.roleName
        }

      }
      else {

        this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while fetching the role data");
      }

    });
  }

  //===========api for getting user Drawers===========
  getConfiguredDrawers() {
    this.storeService.getStoreByNPI(this.userAccessData.NPINo).subscribe(response => {
      if (response.result == constant.KEY_SUCCESS) {
        var receivedStore = JSON.parse(response.data);
        this.drawerArray = receivedStore.retstore[0].DrawerDetail ?? [];
      } else {
        this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while getting the configured drawers");
      }
    });
  }

  //===========api for creating user===========
  createUser() {
    let persistedUser = this.user.userName;
    if (this.user.password != this.user.confirmPassword) {
      this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Password and confirm password does not match.");

    } else {
      this.user.userName = this.user.userName + "@" + this.pseudoName;
      this.userService.createUsers(this.user).subscribe(response => {

        if (response.result == constant.KEY_SUCCESS) {

          this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_SUCCESS_TITLE, "User created successfully").subscribe(result => {

            //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
            this.dialogService.close(true);
          });
        } else if (response.result == constant.KEY_ERROR) {
          this.user.userName = persistedUser;
          var receivedUserResponse = JSON.parse(response.data);

          this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, receivedUserResponse);

        } else {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while creating the user data");
        }

      });
    }

  }

  //===========api for udpating user===========
  updateUser() {

    this.user.modifiedBy = this.userAccessData.userName;

    this.userService.updateUsers(this.user).subscribe(response => {

      if (response.result == constant.KEY_SUCCESS) {

        this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_UPDATE_TITLE, "User updated successfully").subscribe(result => {

          //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
          this.dialogService.close(true);
        });
      }
      else {

        this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while updating the user data");
      }

    });
  }


  //===========api for deleting user===========
  deleteUser() {

    this.utilityService.openPrompt("Are you sure ?", "You won't be able to revert this!").subscribe(result => {
      if (result == true) {
        this.deleteUserData = true;
        this.userService.deleteUsers(this.user).subscribe(response => {

          if (response.result == constant.KEY_SUCCESS) {

            this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_DELETE_TITLE, "User deleted successfully").subscribe(result => {

              //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
              this.dialogService.close(true);
            });
          }
          else {

            this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while deleting the user data");
          }

        });
      }
    });


  }

  AllowOnlyChar(event: any) {
    return this.utilityService.AllowOnlyCharacter(event);
  }

  AllowOnlyNumber(event: any) {
    return this.utilityService.AllowOnlyNumber(event);
  }

  allow3DigitFilter(event: any, modelProperty: string) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const maxLength = 3;

    // Remove non-digit characters
    let truncatedValue: string = "";
    const numericValue = inputValue.replace(/\D/g, '');

    // Validate length
    if (numericValue.length > maxLength) {
      truncatedValue = numericValue.slice(0, maxLength);
    } else {
      truncatedValue = numericValue;
    }

    // Update input value
    inputElement.value = truncatedValue;
    // Update model property
    if (modelProperty === "maxDiscountLimit") {

      this.user.maxDiscountLimit = truncatedValue;
    } else if (modelProperty === "maxCashbackLimit") {
      this.user.maxCashbackLimit = truncatedValue;

    }
  }

  allow8DigitFilter(event: any, modelProperty: string) {

    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const maxLength = 8;

    // Remove non-digit characters
    let truncatedValue: string = "";
    const numericValue = inputValue.replace(/\D/g, '');

    // Validate length
    if (numericValue.length > maxLength) {
      truncatedValue = numericValue.slice(0, maxLength);
    } else {
      truncatedValue = numericValue;
    }

    // Update input value
    inputElement.value = truncatedValue;

    // Update model property
    if (modelProperty === "maxTransactionLimit") {

      this.user.maxTransactionLimit = truncatedValue;
    } else if (modelProperty === "maxTenderedAmountLimit") {
      this.user.maxTenderedAmountLimit = truncatedValue;

    } else if (modelProperty === "maxReturnTransLimit") {
      this.user.maxReturnTransLimit = truncatedValue;
    }
  }

  

}
