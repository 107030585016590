

import { MediaMatcher } from '@angular/cdk/layout';
import {ChangeDetectorRef, Component,OnDestroy,AfterViewInit} from '@angular/core';
import { userAccess } from '../models/UserAccess';
import { UserService } from '../services/User/user.service';
import { MenuItems } from 'src/app/shared/menu-items/menu-items';

/** @title Responsive sidenav */

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})

export class LayoutComponent implements OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;
  isExpanded:boolean=false;
  private _mobileQueryListener: () => void;
  userAccessData!: userAccess;//this variable will hold user data

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private userService: UserService,
    public menuItems: MenuItems
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.userAccessData = this.userService.getUsersAccessData();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() {}

  getTooltipText(): string {
    return !this.isExpanded? 
      "Collapse" : 
      "Expand";
  }

  clearSessionStorage() {
    sessionStorage.removeItem('parent');  
    sessionStorage.removeItem('activeChild');  
    sessionStorage.removeItem('isExpandedItem');  
    sessionStorage.removeItem('expandedItem'); 
    
    this.menuItems.setAccessLinks();
  }
}
