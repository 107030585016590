import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Injectable, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuItems } from 'src/app/shared/menu-items/menu-items';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnDestroy, OnInit {
  shouldRun: boolean=true;
  mobileQuery: MediaQueryList;
  showDetails: boolean = true;
  showNestedItems: boolean = false;
  showNestedChildItems: boolean= false;
  panelOpenState: boolean = false;
  private _mobileQueryListener: () => void;

  isHovered: boolean = false;
  isExpanded = false;
  show = true;
  selectedItem: any;
  parentItem: any;
  @Input() sidenavOpened: boolean=false;

  constructor(
   private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.menuItems.setAccessLinks();
      const activeItem = sessionStorage.getItem('activeChild');

    if (activeItem) {
      const parsedActiveItem = JSON.parse(activeItem);
      this.selectedItem = parsedActiveItem;
    }
  }

  showNestedMenu(){
    if(this.showNestedItems!=true){

      this.showNestedItems=true;
    }else{

      this.showNestedItems=false;
    }

    
    // for(var mnu of mnuitem.childMenuData)
    // {
    //   mnu.expanded=false;
    // }

    // item.expanded = true;
    // this.showNestedItems=item.expanded;
  }

  onEdit(item: any) {

    var menuItems:any=[];
    menuItems=this.menuItems;
    for(var mnu of menuItems)
    {
      mnu.expanded=false;
    }

    item.expanded = true;
    
  }

  showNestedChildMenu(){
    if(this.showNestedChildItems!=true){

      this.showNestedChildItems=true;
    }else{

      this.showNestedChildItems=false;
    }
  }

  toggleSidenav(event: Event): void {
    event.stopPropagation();  // Prevent the parent click event from firing
    this.sidenavOpened = !this.sidenavOpened;
  }

  onMenuItemClick(item: any, parent: any) {
    this.selectedItem = item;
    this.parentItem = parent;

    if (parent && parent.childMenu) {
       sessionStorage.setItem('parent', JSON.stringify(parent));
       sessionStorage.setItem('activeChild', JSON.stringify(item))
     }
     
     this.changeDetectorRef.detectChanges();
  }

  isParentSelected(item: any): boolean {
    return item === this.parentItem;
  }

  toggleExpansion(item: any ) {
    // Toggle the expanded state
    item.expanded = !item.expanded;
    // Store the updated state in sessionStorage
    sessionStorage.setItem(`isExpandedItem`, JSON.stringify(item.expanded));
    sessionStorage.setItem('expandedItem', JSON.stringify(item))
    this.menuItems.setAccessLinks();
  }

  isSelected(child: any): boolean {
    var result = this.selectedItem && this.selectedItem.name === child.name;
    return result;
  }

  // isParentSelected(item: any): boolean {
  //   return item.isParentSelected || (item.childMenu && item.childMenu.some(child => this.selectedItem === child));
  // }
}