<div class="dialog-headline">
    <mat-label style="float:left;">Sample Item File</mat-label>
    <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
</div>
<div mat-dialog-content style="padding-top: 20px;">
  <table mat-table [dataSource]="data.sampleData.slice(1)" class="mat-elevation-z8">
    <ng-container *ngFor="let header of data.sampleData[0]; let i = index">
      <ng-container matColumnDef="{{header}}">
        <th mat-header-cell *matHeaderCellDef>{{ header }}</th>
        <td mat-cell *matCellDef="let element">{{ element[i] }}</td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="data.sampleData[0]"></tr>
    <tr mat-row *matRowDef="let row; columns: data.sampleData[0]"></tr>
  </table>
</div>
<mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="center center" style="padding-top: 20px;padding-bottom: 20px;" >

    <button mat-stroked-button mat-dialog-close style="background-color: #6C747E;width:
    80px;height: 40px;color: #FFFFFF; border-radius:
    4px;font-size: 14px;text-align: center;line-height: 20px;">
        Close
    </button>

    <button mat-flat-button color="primary" (click)="downloadSampleFile()" matTooltip="Download Sample File" style="background-color: #F59120;width:
        auto;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
        cdkFocusInitial>Download</button>
</mat-dialog-actions>
