<div class="dialog-headline">
    <mat-label style="float:left;">{{data.headerTitle}}</mat-label>
    <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
</div>
<mat-dialog-content class="mat-typography" style="padding-top: 20px;">

    <!-- user form taking values -->

    <form #itemVendorModalForm="ngForm" autocomplete="off">

        <div fxLayout="row wrap">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

                <!-- THIS INPUT WILL BE SHOWN ON CREATE MODAL -->
                <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Item Code" name="itemId" #itemId="ngModel" id="itemId"
                            [(ngModel)]="itemData.itemId" disabled>

                    </mat-form-field>
                </div>


                <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
                <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100" style="padding-top: 15px;">
                    <mat-form-field>
                        <input matInput placeholder="Description" name="description" #description="ngModel"
                            id="description" [(ngModel)]="itemData.description" disabled>

                    </mat-form-field>

                </div>
            </div>
        </div>

        <div fxLayout="row wrap">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

                <!-- THIS INPUT WILL BE SHOWN ON CREATE MODAL -->
                <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Vendor Code" name="vendorCode" #vendorCode="ngModel"
                            id="vendorCode" [(ngModel)]="itemVendorData.vendorCode" disabled>

                    </mat-form-field>
                </div>


                <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
                <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100" style="padding-top: 15px;">
                    <mat-form-field>
                        <input matInput placeholder="Vendor Item Code" name="vendorItemID" #vendorItemID="ngModel"
                            id="vendorItemID" [(ngModel)]="itemVendorData.vendorItemID" required>

                        <mat-error *ngIf="vendorItemID.invalid && (vendorItemID.dirty || vendorItemID.touched)"
                            class="invalid-feedback">
                            Please enter vendor item code
                        </mat-error>
                    </mat-form-field>

                </div>
            </div>
        </div>


        <div fxLayout="row wrap">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

                <!-- THIS INPUT WILL BE SHOWN ON CREATE MODAL -->
                <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Selling Price" name="sellingPrice" #sellingPrice="ngModel"
                            id="sellingPrice" [(ngModel)]="itemData.sellingPrice" disabled>

                    </mat-form-field>
                </div>


                <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
                <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100" style="padding-top: 15px;">
                    <mat-form-field>
                        <input matInput placeholder="Vendor Cost Price" name="vendorCostPrice"
                            #vendorCostPrice="ngModel" id="vendorCostPrice" [(ngModel)]="itemVendorData.vendorCostPrice"
                            required>

                        <mat-error *ngIf="vendorCostPrice.invalid && (vendorCostPrice.dirty || vendorCostPrice.touched)"
                            class="invalid-feedback">
                            Please enter vendor cost price
                        </mat-error>
                    </mat-form-field>

                </div>
            </div>
        </div>

    </form>



</mat-dialog-content>
<!-- for adding line -->
<hr>
<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close class="secondary" matTooltip="Close this dialog">
        <mat-icon>close</mat-icon>Close
    </button>
    <button mat-flat-button color="primary" (click)="createItemVendor();" [disabled]="itemVendorModalForm.invalid"
        cdkFocusInitial>Add</button>

</mat-dialog-actions>