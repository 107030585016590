import { SelectionModel } from '@angular/cdk/collections';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { constant } from 'src/app/constant/constant';
import { ItemRequest } from 'src/app/models/RequestModel/ItemRequest';
import { userAccess } from 'src/app/models/UserAccess';
import { ItemService } from 'src/app/services/Item/item.service';
import { UserService } from 'src/app/services/User/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-item-search',
  templateUrl: './item-search.component.html',
  styleUrls: ['./item-search.component.css']
})
export class ItemSearchComponent implements OnInit {


  //#region Mat table variables
  highlightedRows: any = [];
  displayedColumns = ['Action', 'itemId', 'description', 'vendorCode', 'qtyInStock', 'qtyOnOrder', 'minOrdQty', 'sellingPrice', 'prefferedVendor', 'vendorItemID', 'vendorCostPrice', 'pckSize', 'pckQty', 'pckUnit', 'lastVendor', 'vendorItemCode', 'bestPrice'];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  currentPage = 0;
  pageSize = 10;
  totalRows = 0;
  searchKey = "";
  @ViewChild(MatPaginator, { static: false })
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  itemDataSource: MatTableDataSource<any> = new MatTableDataSource();
  pseudoName: any = '';
  selectedRow:any;
  
  //#endregion Mat table variables


  //#region Mat table variables
  invItemCol = ['Select', 'itemId', 'description', 'sellingPrice', 'unit', 'qtyInStock', 'discount', 'exptDeliveryDate', 'reOrderLevel', 'saleStartDate', 'saleEndDate', 'prefferedVendor', 'lastVendor'];
  reportItemCol = ['itemId', 'description', 'departmentName', 'sellingPrice'];
  priceOverrideReportCol = ['Select', 'itemId', 'description', 'departmentName', 'sellingPrice'];
  pageOptions: number[] = [10, 25, 50, 100];
  invCurrentPage = 0;
  invPageSize = 10;
  invTotalRows = 0;
  incSearchKey = "";
  @ViewChild(MatPaginator, { static: false })
  invPaginator!: MatPaginator;
  @ViewChild(MatSort)
  invSort!: MatSort;
  invItemDataSource: MatTableDataSource<any> = new MatTableDataSource();
  selection = new SelectionModel<any>(true, []);
  priceOverrideDataSource: MatTableDataSource<any> = new MatTableDataSource();

  //#endregion Mat table variables


  //#region region Local variables====================
  itemSearch: any = {};
  itemRequest: ItemRequest = new ItemRequest;
  userAccessData!: userAccess;
  itemArray: any[] = [];

  showTableForPO: boolean = false;
  showTableForInventory: boolean = false;
  showTableForTransactionReport: boolean = false;
  priceOverriddenReport: boolean = false;

  //#endregion region Local variables====================


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private itemService: ItemService, private userService: UserService, private utilityService: UtilityService, private itemDialogService: MatDialogRef<ItemSearchComponent>,) {

    this.userAccessData = this.userService.getUsersAccessData();

    //passing the data from the po screen for searching the item data  and then assigning it here and seaching the item as soon as page loads
    if (data.itemData != undefined) {

      if (data.calledFrom) {
        // if (data.calledFrom == "PurchaseOrder") {
        //   this.showTableForPO = true;
        // } else if (data.calledFrom == "InventorReceived") {
        //   this.showTableForInventory = true;
        // } else if (data.calledFrom == "ItemLabel") {
        //   this.showTableForInventory = true;
        // } 
        this.showTableForPO = false;
        this.showTableForInventory = false;
        this.showTableForInventory = false;
        this.showTableForTransactionReport = false;
        this.priceOverriddenReport = false;
        switch (data.calledFrom) {
          case "PurchaseOrder":
            {
              this.showTableForPO = true;
              break;
            }
          case "InventorReceived":
            {
              this.showTableForInventory = true;
              break;
            }
          case "ItemLabel":
            {
              this.showTableForInventory = true;
              break;
            }
          case "TransactionReport":
            {
              this.showTableForTransactionReport = true;

              this.itemRequest.fromReport = true;
              break;
            }
          case "PriceOverriddenReport":
            {
              this.priceOverriddenReport = true;
              this.showTableForTransactionReport = false;
              this.itemRequest.fromReport = true;

              break;

            }
          default: {

          }

        }


      }

      // if(data.itemData.itemId!=undefined || data.itemData.description != undefined)
      // {
      //this.itemRequest.itemId = data.itemData.itemID;
      this.searchItem();
      // }

    }
  }

  ngOnInit(): void {

  }


  @HostListener('keydown.enter', ['$event'])
  onEnterKey(event: KeyboardEvent) {
    this.searchItem();
  }

  //fetching items
  searchItem() {


    // setting pagination request object 
    this.itemRequest.NPINo = this.userAccessData.NPINo;
    this.itemRequest.pagination.pageNumber = this.currentPage + 1;
    this.itemRequest.pagination.pageSize = this.pageSize;

    this.itemService.getItemsForPO(this.itemRequest).subscribe(response => {

      if (response.result == constant.KEY_SUCCESS) {

        if (response.data != "") {
          var receivedItemResponse = JSON.parse(response.data);
          this.itemArray = receivedItemResponse.itemData;

          if (this.itemArray.length < 1) {
            var alertRef = this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "No data found").subscribe(result => {

              //if (result == true)
              //this.dialogService.closeAll()
              //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
            });

            alertRef.unsubscribe();
            if (this.showTableForTransactionReport == false) {
              this.itemDataSource = new MatTableDataSource<any>(this.itemArray);
              this.itemDataSource.paginator = this.paginator!;
              this.itemDataSource.sort = this.sort!;
            }
          } else {
            //for item table
            if (this.showTableForPO == true) {
              this.itemDataSource = new MatTableDataSource<any>(this.itemArray);
              this.itemDataSource.paginator = this.paginator!;
              this.itemDataSource.sort = this.sort!;
            }

            //for item inv table
            if (this.showTableForTransactionReport == true || this.showTableForInventory == true) {
              this.invItemDataSource = new MatTableDataSource<any>(this.itemArray);
              this.invItemDataSource.paginator = this.paginator!;
              this.invItemDataSource.sort = this.sort!;
            }

            if (this.priceOverriddenReport == true) {
              this.priceOverrideDataSource = new MatTableDataSource<any>(this.itemArray);
              this.priceOverrideDataSource.paginator = this.invPaginator!;
              this.priceOverrideDataSource.sort = this.invSort!;
            }

          }


          setTimeout(() => {

            this.paginator!.pageIndex = this.currentPage;
            this.paginator!.length = receivedItemResponse.totalRecords;

          });
        }
        else {
          var alertRef = this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "No data found").subscribe(result => {

            //if (result == true)
            //this.dialogService.closeAll()
            //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
          });

          alertRef.unsubscribe();
          this.itemDataSource = new MatTableDataSource<any>(this.itemArray);
          this.itemDataSource.paginator = this.paginator!;
          this.itemDataSource.sort = this.sort!;
        }



      }
      else if (response.result == constant.KEY_ERROR) {
        if (response.message == "No data found") {
          var alertRef = this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "No data found").subscribe(result => {
          });
        }
        else {
          this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while loading the items");
        }
      }

    }, error => {

      this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while fetching the Item details");

    });
  }

  pageChanged(event: PageEvent) {

    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    //if both the index are same then dont call the user api again for loading the data
    // if (event.pageIndex != event.previousPageIndex)
    this.searchItem();
  }

  sendSelectedItem(itemData: any) {

    this.itemDialogService.close(itemData);//this will close the dialoge for item search and send the data to subscriber

  }

  AllowOnlyNumber(event: any) {

    var inputValue = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[0-9]/.test(inputValue)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }


  //#endregion ========================================================INV ITEM TABLE METHODS===================================================================================================

  //#region checkbox selection code

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.invItemDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.invItemDataSource.data.forEach(row => this.selection.select(row));
  }
  //#endregion


  sendSelectedInvItems() {

    this.itemDialogService.close(this.selection.selected);//this will close the dialoge for item search and send the data to subscriber
  }

  //#endregion

}


