import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'epos-outlined-button',
  templateUrl: './outlined-button.component.html',
  styleUrls: ['./outlined-button.component.css']
})
export class OutlinedButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  btnLabel!: string;
  @Input() type: 'secondary' | 'success' | 'info' | 'help' | 'warning' | 'tertiary' = 'success'; // Default to primary type
  @Input() size: 'sm' | 'lg' = 'sm'; // Default to primary type
  @Input() disabled: boolean = false;

  @Input() needCursor: boolean = false; // Default to 'not-allowed' for disabled buttons

}
