import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutlinedButtonComponent } from './outlined-button/outlined-button.component';
import { StatusBadgeComponent } from './status-badge/status-badge.component';
import { PageHeaderComponent } from './page_header/page-header/page-header.component';
import { MatIconModule } from '@angular/material/icon';
import { TableEditButtonComponent } from './table-edit-button/table-edit-button.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { TableDeleteButoonComponent } from './table-delete-butoon/table-delete-button.component';
import { PopupCloseButtonComponent } from './popup-close-button/popup-close-button.component';
import { ExcelExportButtonComponent } from './excel-export-button/excel-export-button.component';
import { CsvExportButtonComponent } from './csv-export-button/csv-export-button.component';
import { JsonExportButtonComponent } from './json-export-button/json-export-button.component';
import { TextExportButtonComponent } from './text-export-button/text-export-button.component';
import { TableCloneButtonComponent } from './table-clone-button/table-clone-button.component';



@NgModule({
  declarations: [OutlinedButtonComponent, StatusBadgeComponent, PageHeaderComponent, TableEditButtonComponent, TableDeleteButoonComponent, PopupCloseButtonComponent, ExcelExportButtonComponent, CsvExportButtonComponent, JsonExportButtonComponent, TextExportButtonComponent, TableCloneButtonComponent],
  exports: [
    OutlinedButtonComponent,
    StatusBadgeComponent,
    PageHeaderComponent,
    TableEditButtonComponent,
    TableDeleteButoonComponent,
    PopupCloseButtonComponent,
    ExcelExportButtonComponent,
    CsvExportButtonComponent,
    JsonExportButtonComponent,
    TextExportButtonComponent,
    TableCloneButtonComponent

   ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,

  ],

})
export class ReusableComponentsModule { }
