import { HttpAuthInterceptorService } from './services/utility/http-auth-interceptor-service';
import { PromptDialogComponent } from './utility/prompt-dialog/prompt-dialog.component';
import { DataTablesModule } from 'angular-datatables';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { LayoutComponent } from './layout/layout.component';
import { MaterialModule } from './materialComponents/material.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { AlertDialogComponent } from './utility/alert-dialog/alert-dialog.component';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { CommonModule, DatePipe } from '@angular/common';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { CountdownAlertComponent } from './utility/countdown-alert/countdown-alert.component';
import { PageNotFoundComponent } from './utility/pageNotFound/page-not-found.component';
import { UnAuthorisePageComponent } from './utility/unAuthorisePage/un-authorise-page.component';
import { ChangePasswordComponent } from './changePassword/change-password/change-password.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
// import { GoogleChartsModule } from 'angular-google-charts';
import { TimeoutInterceptor, DEFAULT_TIMEOUT } from './services/utility/timeout-Interceptor';
import { RECAPTCHA_SETTINGS,   RecaptchaSettings, RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { LoginWithoutCaptchaComponent } from './loginWithoutCaptcha/login-without-captcha.component';
import { ItemSearchComponent } from './utility/itemSearch/item-search.component';
import { PdfViewerComponent } from './utility/PDFViewer/pdf-viewer.component';

import * as CanvasJSAngularChart from '../assets/canvasjs.angular.component';
// import { TransactionReportComponent } from './components/transaction-report/transaction-report.component';
// import { SalesCustomerReportComponent } from './components/sales-report-customer/sales-report-customer.component';
// import { SalesDepartmentReportComponent } from './components/sales-report-department/sales-report-department.component';

var CanvasJSChart = CanvasJSAngularChart.CanvasJSChart;

import { MatTableExporterModule } from 'mat-table-exporter';
import { ItemVendorAddModalComponent } from './utility/vendorItemAdd/item-vendor-add-modal.component';
import { DepartmentSearchComponent } from './utility/departmentSearch/department-search.component';
import { CustomerSearchComponent } from './utility/customerSearch/customer-search.component';
import { MatToolbar } from '@angular/material/toolbar';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { VendorSearchComponent } from './utility/vendorSearch/vendor-search.component';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LayoutComponent,
    SidebarComponent,
    HeaderComponent,
    PromptDialogComponent,
    AlertDialogComponent,
    CountdownAlertComponent,
    PageNotFoundComponent,
    UnAuthorisePageComponent,
    ChangePasswordComponent,
    LoginWithoutCaptchaComponent,
    ItemSearchComponent,
    PdfViewerComponent,
    ItemVendorAddModalComponent,
    DepartmentSearchComponent,
    CustomerSearchComponent,
    VendorSearchComponent
    


  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    DataTablesModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
    }),
    MatPasswordStrengthModule.forRoot(),
    RecaptchaFormsModule,
    RecaptchaModule,
    MatTableExporterModule,
    ReactiveFormsModule

    ],
  providers: [
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    [{ provide: DEFAULT_TIMEOUT, useValue: 150000 }],
    [{ provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.recaptcha.siteKey } as RecaptchaSettings, },],
    [{ provide: DatePipe}],
    [{provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}}]

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
