import { Component, Input, OnInit } from '@angular/core';
import { MatTableExporterDirective } from 'mat-table-exporter';

@Component({
  selector: 'app-csv-export-button',
  templateUrl: './csv-export-button.component.html',
  styleUrls: ['./csv-export-button.component.css']
})
export class CsvExportButtonComponent {

  @Input()
  exporter!: MatTableExporterDirective;
  @Input() fileName: string = 'Item_Data';
  @Input() sheetName: string = 'Item_Data';
  @Input() author: string = 'EPrimePOS';

  exportToCsv() {
    if (this.exporter) {
      this.exporter.exportTable('csv', {
        fileName: this.fileName,
        sheet: this.sheetName,
        Props: { Author: this.author }
      });
    } else {
      console.error('Exporter is not defined.');
    }
  }

}
