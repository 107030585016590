<!-- <h4 color="primary" class="dialog-headline"><p>Create user</p></h4> -->
<div class="dialog-headline">
  <mat-label style="float:left;">{{receivedData.headerTitle}}</mat-label>
  <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
</div>
<!-- <h2 mat-dialog-title style="background-color: rgb(38, 142, 226);">Install Angular</h2> -->
<mat-dialog-content class="mat-typography" style="padding-top: 20px;">

  <!-- user form taking values -->

  <form #userModalForm="ngForm" autocomplete="off">

    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

        <!-- THIS INPUT WILL BE SHOWN ON CREATE MODAL -->
        <div *ngIf="!user.mId" fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="User Name" name="userName" #userName="ngModel" id="userName"
              [(ngModel)]="user.userName" required [disabled]="receivedData.headerTitle=='Update User'"
              autocomplete="new-password">
            <span matSuffix>@{{pseudoName}}</span>


            <mat-error *ngIf="userName.invalid && (userName.dirty || userName.touched)" class="invalid-feedback">
              Please enter user name
            </mat-error>
          </mat-form-field>
        </div>

        <!-- THIS INPUT WILL BE SHOWN ON UPDATE MODAL -->
        <div *ngIf="user.mId" fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">

          <mat-form-field>
            <input matInput placeholder="User Name" name="userName" #userName="ngModel" id="userName"
              [(ngModel)]="user.userName" required readonly>
            <mat-error *ngIf="userName.invalid && (userName.dirty || userName.touched)" class="invalid-feedback"
              autocomplete="new-password">
              Please enter user name
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">

        </div>
      </div>
    </div>

    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Password" name="password" #password="ngModel" id="password"
              [(ngModel)]="user.password" required [type]="hidePass ? 'password' : 'text'" autocomplete="new-password">

            <!-- <mat-icon class="pointer" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
            </mat-icon> -->
            <!-- Commenting out for password hashing <img class="pointer" *ngIf="!hidePass" width="24px" style="padding-bottom: 10px;text-align: right;"
              matSuffix src="assets/images/icons/icon-visibility-ON.png" alt="Toggle Password Visibility"
              (click)="hidePass = !hidePass">
            <img class="pointer" *ngIf="hidePass" width="24px" style="padding-bottom: 10px;text-align: right;" matSuffix
              src="assets/images/icons/icon-visibility-OFF.png" alt="Toggle Password Visibility"
              (click)="hidePass = !hidePass"> Commenting out for password hashing -->

          </mat-form-field>

          <mat-password-strength (onStrengthChanged)="onPasswordStrengthChanged($event);" [externalError]="true"
            [min]="8" [max]="10" #passwordComponent [password]="password.value">
          </mat-password-strength>

          <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
              [hideToggle]="showError">
              <mat-expansion-panel-header>
                <mat-panel-description>
                  <mat-icon *ngIf="!panelOpenState">arrow_forward</mat-icon>
                  <mat-icon *ngIf="panelOpenState">arrow_downward</mat-icon>
                  Expand to see errors
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-password-strength-info [passwordComponent]="passwordComponent">
              </mat-password-strength-info>
            </mat-expansion-panel>

          </mat-accordion>
        </div>
        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

        <!--3819-->
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Confirm Password" name="confirmPassword" #confirmPassword="ngModel"
              id="confirmPassword" [(ngModel)]="user.confirmPassword" required
              [type]="hideConfirmPass ? 'password' : 'text'" autocomplete="new-password">

            <!-- Commenting out for password hashing <img class="pointer" *ngIf="!hideConfirmPass" width="24px" style="padding-bottom: 10px;text-align: right;"
              matSuffix src="assets/images/icons/icon-visibility-ON.png" alt="Toggle Password Visibility"
              (click)="hideConfirmPass = !hideConfirmPass">
            <img class="pointer" *ngIf="hideConfirmPass" width="24px" style="padding-bottom: 10px;text-align: right;"
              matSuffix src="assets/images/icons/icon-visibility-OFF.png" alt="Toggle Password Visibility"
              (click)="hideConfirmPass = !hideConfirmPass"> Commenting out for password hashing-->



            <mat-error *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)"
              class="invalid-feedback">
              Please enter confirm password
            </mat-error>

            <mat-error *ngIf="(user.confirmPassword!=user.password)" class="invalid-feedback">
              confirm password does not match with password
            </mat-error>
          </mat-form-field>
        </div> <!--3819-->
      </div>
    </div>

    <br>


    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Last Name" (keypress)="AllowOnlyChar($event)" name="lName" #lName="ngModel"
              id="lName" [(ngModel)]="user.lName" required maxlength="25">
            <mat-error *ngIf="lName.invalid && (lName.dirty || lName.touched)" class="invalid-feedback">
              Please enter last name
            </mat-error>
          </mat-form-field>


        </div>
        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="First Name" (keypress)="AllowOnlyChar($event)" name="fName" #fName="ngModel"
              id="fName" [(ngModel)]="user.fName" required maxlength="25">
            <mat-error *ngIf="fName.invalid && (fName.dirty || fName.touched)" class="invalid-feedback">
              Please enter first name
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>


    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

        <div fxFlex.gt-lg="47.5" fxFlex.gt-md="47.5" fxFlex.gt-xs="100" fxFlex="100">
          <!-- <mat-form-field>
            <mat-label>Select Drawer</mat-label>
            <mat-select id="drawNo" #drawNo="ngModel" name="drawNo" [(ngModel)]="user.DrawNo" required>
              <mat-option *ngFor="let eachDraw of drawerArray" [value]="eachDraw.drawerId">
                {{ eachDraw.drawerDescription}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->


          <mat-form-field>
            <mat-label>Select Role</mat-label>
            <mat-select id="userRole" #role="ngModel" name="role" [(ngModel)]="user.role"
              (selectionChange)="roleChange($event.value)" required>
              <mat-option *ngFor="let eachRole of roleArray" [value]="eachRole.roleName">
                {{ eachRole.roleName}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

        <!--3820--> <!-- <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="No. Of Attempt" name="noOfAttempt" #maxTenderedAmountLimit="ngModel"
            id="noOfAttempt" [(ngModel)]="user.noOfAttempt" (keypress)="AllowOnlyNumber($event)">
          </mat-form-field>
        </div> --> <!--3820-->
      </div>
    </div>

    <!--
    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">


        </div>
        <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>

        <div *ngIf="user.mId" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Password Changed On" name="passwordChangedOn" #passwordChangedOn="ngModel"
              id="passwordChangedOn" [(ngModel)]="user.passwordChangedOn" readonly>
          </mat-form-field>

        </div>

      </div>
    </div> -->

    <!-- <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Max Transaction Limit" name="maxTransactionLimit"
              #maxTransactionLimit="ngModel" id="maxTransactionLimit" [(ngModel)]="user.maxTransactionLimit"
              (input)="allow8DigitFilter($event,'maxTransactionLimit')" required>

            <mat-error
              *ngIf="maxTenderedAmountLimit.invalid && (maxTenderedAmountLimit.dirty || maxTenderedAmountLimit.touched)"
              class="invalid-feedback">
              Please enter Max TenderedAmount Limit
            </mat-error>
          </mat-form-field>

        </div>
        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Max TenderedAmount Limit" name="maxTenderedAmountLimit"
              #maxTenderedAmountLimit="ngModel" id="maxTenderedAmountLimit" [(ngModel)]="user.maxTenderedAmountLimit"
              (input)="allow8DigitFilter($event,'maxTenderedAmountLimit')" required>

            <mat-error
              *ngIf="maxTenderedAmountLimit.invalid && (maxTenderedAmountLimit.dirty || maxTenderedAmountLimit.touched)"
              class="invalid-feedback">
              Please enter Max TenderedAmount Limit
            </mat-error>
          </mat-form-field>

        </div>

      </div>
    </div> -->


    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <!-- <mat-form-field>
            <input matInput placeholder="Max Cashback Limit" name="maxCashbackLimit" #maxCashbackLimit="ngModel"
              (input)="allow3DigitFilter($event,'maxCashbackLimit')" id="maxCashbackLimit"
              [(ngModel)]="user.maxCashbackLimit" required>
            <mat-error *ngIf="maxCashbackLimit.invalid && (maxCashbackLimit.dirty || maxCashbackLimit.touched)"
              class="invalid-feedback">
              Please enter Max Cashback Limit
            </mat-error>
          </mat-form-field> -->
          <mat-form-field>



            <input matInput placeholder="Max Discount Limit" name="maxDiscountLimit" #maxDiscountLimit="ngModel"
              id="maxDiscountLimit" [(ngModel)]="user.maxDiscountLimit" required
              (input)="allow3DigitFilter($event,'maxDiscountLimit')">
            <mat-error *ngIf="maxDiscountLimit.invalid && (maxDiscountLimit.dirty || maxDiscountLimit.touched)"
              class="invalid-feedback">
              Please enter Max Discount Limit
            </mat-error>


          </mat-form-field>


        </div>
        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

        <!-- THIS DIV WILL BE SHOWN ON UPDATE MODAL -->
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Max ReturnTrans Limit" name="maxReturnTransLimit"
              #maxReturnTransLimit="ngModel" id="maxReturnTransLimit" [(ngModel)]="user.maxReturnTransLimit"
              (input)="allow8DigitFilter($event,'maxReturnTransLimit')" required>
            <mat-error *ngIf="maxReturnTransLimit.invalid && (maxReturnTransLimit.dirty || maxReturnTransLimit.touched)"
              class="invalid-feedback">
              Please enter Max ReturnTrans Limit
            </mat-error>
          </mat-form-field>

        </div>

      </div>
    </div>

    <!-- THIS DIV WILL BE SHOWN ON UPDATE MODAL -->
    <div fxLayout="row wrap" style="padding-top: 10px;" *ngIf="user.mId"
      matTooltip="Add 0 in No. Of Attempt and uncheck IsLocked? checkbox to unlock a user">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-checkbox #isLocked="ngModel" id="isLocked" name="isLocked" class="custom-checkbox"
            [(ngModel)]="user.isLocked">Is Locked?
          </mat-checkbox>
        </div>
        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>


        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-checkbox #changePasswordAtLogin="ngModel" id="changePasswordAtLogin" class="custom-checkbox"
            name="changePasswordAtLogin" [(ngModel)]="user.changePasswordAtLogin">Change Password At Login
          </mat-checkbox>
        </div>

      </div>
    </div>

    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

        <div style="padding-top: 20px;" fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-checkbox #isActive="ngModel" id="isActive" name="isActive" class="custom-checkbox"
            [(ngModel)]="user.isActive">Is Active
          </mat-checkbox>

        </div>
        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100"></div>

      </div>
    </div>


  </form>



</mat-dialog-content>
<!-- for adding line -->
<hr>
<!-- <mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close class="secondary" matTooltip="Close this dialog">
    <mat-icon>close</mat-icon>Close
  </button>
  <button mat-flat-button *ngIf="!user.mId && webRights.webAdministrativeFunction.webAdminUser.add" color="primary"
    (click)="createUser();" [disabled]="userModalForm.invalid || !isPasswordStrong " cdkFocusInitial>Create</button>

  <button mat-flat-button *ngIf="user.mId && webRights.webAdministrativeFunction.webAdminUser.update" color="primary"
    matTooltip="Update user" {{user.userName}} (click)="updateUser();"
    [disabled]="userModalForm.invalid || !isPasswordStrong || (user.confirmPassword!=user.password)" cdkFocusInitial>
    <mat-icon>edit</mat-icon>Update
  </button>

  <button mat-flat-button *ngIf="user.mId && webRights.webAdministrativeFunction.webAdminUser.delete" color="warn"
    (click)="deleteUser();" cdkFocusInitial matTooltip="Delete user {{user.userName}}">
    <mat-icon>delete_outline</mat-icon>Delete
  </button>
</mat-dialog-actions> -->

<mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="center center" style="margin: 10px;">
  <button mat-flat-button color="warn" (click)="deleteUser()"
    *ngIf="user.mId && webRights.webAdministrativeFunction.webAdminUser.delete"
    matTooltip="Delete user {{user.userName}}"
    style="background-color: #6C747E;width:
    80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;">Delete</button>

  <button mat-stroked-button mat-dialog-close style="background-color: #6C747E;width:
        80px;height: 40px;color: #FFFFFF; border-radius:
        4px;font-size: 14px;text-align: center;line-height: 20px;">
    Close
  </button>

  <button mat-flat-button color="primary" (click)="createUser()"
    *ngIf="!user.mId && webRights.webAdministrativeFunction.webAdminUser.add" style="background-color: #F59120;width:
        80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
    [disabled]="userModalForm.invalid || !isPasswordStrong " cdkFocusInitial>Create</button>

  <button mat-flat-button color="primary" (click)="updateUser()"
    *ngIf="user.mId && webRights.webAdministrativeFunction.webAdminUser.update" style="background-color: #F59120;width:
        80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
    [disabled]="userModalForm.invalid || !isPasswordStrong || (user.confirmPassword!=user.password)"
    cdkFocusInitial>Update</button>
</mat-dialog-actions>
