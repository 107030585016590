import { Pagination } from "../Pagination";
import { BaseSearchFilter } from "./BaseSearchFilter";

export class ItemRequest extends BaseSearchFilter {

    itemId!: string;
    description!: string;
    department!: string;
    pagination: Pagination = new Pagination;
    fromReport:boolean=false;
}