<div>

    <div class="dialog-headline">
        <mat-label style="float:left;">Search Item</mat-label>
        <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
    </div>

    <mat-dialog-content class="mat-typography" style="padding-top: 10px;">


        <div fxLayout="row wrap">

            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center" style="gap: 5px;">

                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

                    <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100"
                        style="padding-left: 10px;">
                        <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center" class="search-container"
                            style="text-align: right;">
                            <fieldset style=" align-items: center; gap: 20px;">
                                <input type="text" placeholder="Item Code" (keypress)="AllowOnlyNumber($event)"
                                    [(ngModel)]="itemRequest.itemId" name="itemId" id="itemId">

                                <input type="text" placeholder="Item Name" [(ngModel)]="itemRequest.description"
                                    name="description" id="description" class="form-group">


                            </fieldset>

                        </div>

                    </div>

                    <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100"></div>
                    <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
                        <button class="custom-blue-button" type="button" id="btnSearch"
                            style="width: 85px; height: 40px;" (click)="searchItem()">
                            <mat-icon color="white">search</mat-icon>Search
                        </button>
                    </div>
                </div>


            </div>


        </div>

        <div fxLayout="row wrap" style="padding-top: 25px;">

            <div class="example-container mat-elevation-z8" *ngIf="showTableForPO">

                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">




                    <mat-sidenav-container>

                        <mat-sidenav #sidenav position="start" mode="side" opened="false" class="width-30 p-6">
                            SIDENAV CONTENT HERE
                        </mat-sidenav>

                        <mat-sidenav-content>
                            <div class="mat-elevation-z8 m-12">
                                <div class="table-container">
                                    <!-- <table mat-table [dataSource]="dataSource"> -->
                                    <mat-table id="cutomerTable" matTableExporter matSort [dataSource]="itemDataSource">

                                        <ng-container matColumnDef="Action">
                                            <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                                            <mat-cell *matCellDef="let element">

                                                <button mat-icon-button matTooltip="Select {{element.description}}"
                                                    color="primary" (click)="sendSelectedItem(element)">
                                                    Select
                                                </button>

                                            </mat-cell>
                                        </ng-container>


                                        <ng-container color="primary" matColumnDef="itemId">
                                            <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header>Item
                                                Id</mat-header-cell>
                                            <mat-cell color="primary" *matCellDef="let element"> {{element.itemId}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="description">
                                            <mat-header-cell *matHeaderCellDef
                                                mat-sort-header>Description</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="vendorItemCode">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Item Code
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.vendorItems?.vendorItemID}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="qtyInStock">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Qty in Stock</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.qtyInStock}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="qtyOnOrder">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Qty on Order</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.qtyOnOrder}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="minOrdQty">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Minimum Order Qty</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.minOrdQty}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="sellingPrice">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Selling Price</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.sellingPrice}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="prefferedVendor">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Prefered Vendor</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.prefferedVendor}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="vendorItemID">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Vendor Item Id</mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                {{element.vendorItems?.vendorItemID}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="vendorCostPrice">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Vendor Cost Price</mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                {{element.vendorItems?.vendorCostPrice}} </mat-cell>
                                        </ng-container>


                                        <ng-container matColumnDef="pckSize">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                PCK Size</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.vendorItems?.pckSize}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="pckQty">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                PCK Qty</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.vendorItems?.pckQty}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="pckUnit">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                PCK Unit</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.vendorItems?.pckUnit}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="lastVendor">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Last Vendor Name</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.lastVendor}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="vendorCode">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Best Vendor</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.vendorItems?.vendorCode}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="bestPrice">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Best Price</mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                {{element.vendorItems?.vendorCostPrice}} </mat-cell>
                                        </ng-container>


                                        <!-- <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumns;"
                                        [ngClass]="{hovered: row.hovered}" (mouseover)="row.hovered = true"
                                        (mouseout)="row.hovered = false" (click)="sendSelectedItem(row)"></mat-row> -->

                                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                        <!-- <mat-row *matRowDef="let row; columns: displayedColumns;"
                                        [ngClass]="{hovered: row.hovered}" (mouseover)="row.hovered = true"
                                        (mouseout)="row.hovered = false" (click)="selection.toggle(row)"></mat-row> -->
                                        <mat-row *matRowDef="let row; columns: displayedColumns;"
                                            (click)="selectedRow = selectedRow === row ? null : row"
                                            [ngClass]="{ 'selected': row === selectedRow }"
                                            (click)="selection.toggle(selectedRow)"></mat-row>
                                    </mat-table>


                                    <mat-paginator fxLayoutAlign="start" #paginator [length]="totalRows"
                                        [pageIndex]="currentPage" [pageSize]="pageSize"
                                        [pageSizeOptions]="pageSizeOptions" aria-label="Select page"
                                        (page)="pageChanged($event)">
                                    </mat-paginator>
                                </div>
                                <!-- end table container -->

                            </div>
                        </mat-sidenav-content>

                    </mat-sidenav-container>
                </div>
            </div>

            <!-- =====================FOR INV ITEM TABLE======================= -->
            <div class="example-container mat-elevation-z8" *ngIf="showTableForInventory">

                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">




                    <mat-sidenav-container>

                        <mat-sidenav #sidenav position="start" mode="side" opened="false" class="width-30 p-6">
                            SIDENAV CONTENT HERE
                        </mat-sidenav>

                        <mat-sidenav-content>
                            <div class="mat-elevation-z8 m-12">
                                <div class="table-container">
                                    <!-- <table mat-table [dataSource]="dataSource"> -->
                                    <mat-table id="invItemTable" matTableExporter matSort
                                        [dataSource]="invItemDataSource">


                                        <!-- Checkbox Column -->
                                        <!-- <ng-container matColumnDef="Select">
                                        <mat-header-cell *matHeaderCellDef>
                                            Select
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="$event ? selection.toggle(row) : null"
                                                [checked]="selection.isSelected(row)">
                                            </mat-checkbox>
                                        </mat-cell>
                                    </ng-container> -->

                                        <ng-container color="primary" matColumnDef="Select">
                                            <mat-header-cell color="primary" *matHeaderCellDef><mat-checkbox
                                                    [disabled]="itemArray.length<1" matTooltip="Select All"
                                                    (change)="$event ? masterToggle() : null"
                                                    [checked]="selection.hasValue() && isAllSelected()"
                                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                                                </mat-checkbox></mat-header-cell>

                                            <mat-cell color="primary" *matCellDef="let element"> <mat-checkbox
                                                    (click)="$event.stopPropagation()"
                                                    (change)="$event ? selection.toggle(element) : null"
                                                    [checked]="selection.isSelected(element)">
                                                </mat-checkbox> </mat-cell>
                                        </ng-container>


                                        <ng-container matColumnDef="itemId">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>Item
                                                Id</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.itemId}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="description">
                                            <mat-header-cell *matHeaderCellDef
                                                mat-sort-header>Description</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="sellingPrice">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>Unit Price
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.sellingPrice}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="unit">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Unit</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.unit}} </mat-cell>
                                        </ng-container>


                                        <ng-container matColumnDef="qtyInStock">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Qty in Stock</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.qtyInStock}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="discount">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Discount</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.discount}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="exptDeliveryDate">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Delivery Date</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.exptDeliveryDate | date:
                                                "short"}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="reOrderLevel">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                ReOrder Level</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.reOrderLevel}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="saleStartDate">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Sale Start Date</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.saleStartDate | date:
                                                "short"}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="saleEndDate">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Sale End Date</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.saleEndDate | date: "short"}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="prefferedVendor">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Preffered Vendor</mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                {{element.prefferedVendor}} </mat-cell>
                                        </ng-container>


                                        <ng-container matColumnDef="lastVendor">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Last Vendor</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.lastVendor}}
                                            </mat-cell>
                                        </ng-container>

                                        <mat-header-row *matHeaderRowDef="invItemCol"></mat-header-row>
                                        <mat-row *matRowDef="let row; columns: invItemCol;"
                                            [ngClass]="{hovered: row.hovered}" (mouseover)="row.hovered = true"
                                            (mouseout)="row.hovered = false" (click)="selection.toggle(row)"></mat-row>

                                    </mat-table>


                                    <mat-paginator fxLayoutAlign="start" #paginator [length]="totalRows"
                                        [pageIndex]="currentPage" [pageSize]="pageSize"
                                        [pageSizeOptions]="pageSizeOptions" aria-label="Select page"
                                        (page)="pageChanged($event)">
                                    </mat-paginator>
                                </div>
                                <!-- end table container -->

                            </div>
                        </mat-sidenav-content>

                    </mat-sidenav-container>
                </div>
            </div>

            <div class="example-container mat-elevation-z8" *ngIf="showTableForTransactionReport">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

                    <mat-sidenav-container>

                        <mat-sidenav #sidenav position="start" mode="side" opened="false" class="width-30 p-6">
                            SIDENAV CONTENT HERE
                        </mat-sidenav>

                        <mat-sidenav-content>
                            <div class="mat-elevation-z8 m-12">
                                <div class="table-container">
                                    <!-- <table mat-table [dataSource]="dataSource"> -->
                                    <mat-table id="reportItemTable" matTableExporter matSort
                                        [dataSource]="invItemDataSource">

                                        <ng-container matColumnDef="Action">
                                            <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                                            <mat-cell *matCellDef="let element">

                                                <button mat-icon-button matTooltip="Select {{element.description}}"
                                                    color="primary" (click)="sendSelectedItem(element)">
                                                    Select
                                                </button>

                                            </mat-cell>
                                        </ng-container>

                                        <!-- <ng-container color="primary" matColumnDef="Select" >
                                        <mat-header-cell color="primary" *matHeaderCellDef><mat-checkbox
                                                 matTooltip="Select All"
                                                (change)="$event ? masterToggle() : null"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                                            </mat-checkbox></mat-header-cell>

                                        <mat-cell color="primary" *matCellDef="let element"> <mat-checkbox
                                                (click)="$event.stopPropagation()"
                                                (change)="$event ? selection.toggle(element) : null"
                                                [checked]="selection.isSelected(element)">
                                            </mat-checkbox> </mat-cell>
                                    </ng-container> -->




                                        <ng-container matColumnDef="itemId">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>Item
                                                Id</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.itemId}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="description">
                                            <mat-header-cell *matHeaderCellDef
                                                mat-sort-header>Description</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="departmentName">
                                            <mat-header-cell *matHeaderCellDef
                                                mat-sort-header>Department</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.departmentName}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="sellingPrice">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>Unit Price
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.sellingPrice}}
                                            </mat-cell>
                                        </ng-container>


                                        <!-- <mat-header-row *matHeaderRowDef="reportItemCol"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: reportItemCol;"
                                        [ngClass]="{hovered: row.hovered}" (mouseover)="row.hovered = true"
                                        (mouseout)="row.hovered = false" (click)="selection.toggle(row)"></mat-row> -->

                                        <mat-header-row *matHeaderRowDef="reportItemCol"></mat-header-row>
                                        <mat-row *matRowDef="let row; columns: reportItemCol;"
                                            (click)="selectedRow = selectedRow === row ? null : row"
                                            [ngClass]="{ 'selected': row === selectedRow }"
                                            (click)="selection.toggle(selectedRow)"></mat-row>
                                    </mat-table>


                                    <mat-paginator fxLayoutAlign="start" #paginator [length]="totalRows"
                                        [pageIndex]="currentPage" [pageSize]="pageSize"
                                        [pageSizeOptions]="pageSizeOptions" aria-label="Select page"
                                        (page)="pageChanged($event)">
                                    </mat-paginator>
                                </div>
                                <!-- end table container -->

                            </div>
                        </mat-sidenav-content>

                    </mat-sidenav-container>
                </div>
            </div>

            <div class="example-container mat-elevation-z8" *ngIf="priceOverriddenReport">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

                    <mat-sidenav-container>

                        <mat-sidenav #sidenav position="start" mode="side" opened="false" class="width-30 p-6">
                            SIDENAV CONTENT HERE
                        </mat-sidenav>

                        <mat-sidenav-content>
                            <div class="mat-elevation-z8 m-12">
                                <div class="table-container">
                                    <!-- <table mat-table [dataSource]="dataSource"> -->
                                    <mat-table id="reportItemTable" matTableExporter matSort
                                        [dataSource]="priceOverrideDataSource">


                                        <ng-container color="primary" matColumnDef="Select">
                                            <mat-header-cell color="primary" *matHeaderCellDef><mat-checkbox
                                                    matTooltip="Select All" (change)="$event ? masterToggle() : null"
                                                    [checked]="selection.hasValue() && isAllSelected()"
                                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                                                </mat-checkbox></mat-header-cell>

                                            <mat-cell color="primary" *matCellDef="let element"> <mat-checkbox
                                                    (click)="$event.stopPropagation()"
                                                    (change)="$event ? selection.toggle(element) : null"
                                                    [checked]="selection.isSelected(element)">
                                                </mat-checkbox> </mat-cell>
                                        </ng-container>




                                        <ng-container matColumnDef="itemId">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>Item
                                                Id</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.itemId}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="description">
                                            <mat-header-cell *matHeaderCellDef
                                                mat-sort-header>Description</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="departmentName">
                                            <mat-header-cell *matHeaderCellDef
                                                mat-sort-header>Department</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.departmentName}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="sellingPrice">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>Unit Price
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.sellingPrice}}
                                            </mat-cell>
                                        </ng-container>


                                        <!-- <mat-header-row *matHeaderRowDef="reportItemCol"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: reportItemCol;"
                                        [ngClass]="{hovered: row.hovered}" (mouseover)="row.hovered = true"
                                        (mouseout)="row.hovered = false" (click)="selection.toggle(row)"></mat-row> -->

                                        <mat-header-row *matHeaderRowDef="priceOverrideReportCol"></mat-header-row>
                                        <mat-row *matRowDef="let row; columns: priceOverrideReportCol;"
                                            (click)="selectedRow = selectedRow === row ? null : row"
                                            [ngClass]="{ 'selected': row === selectedRow }"
                                            (click)="selection.toggle(selectedRow)"></mat-row>
                                    </mat-table>


                                    <mat-paginator fxLayoutAlign="start" #invPaginator [length]="totalRows"
                                        [pageIndex]="currentPage" [pageSize]="pageSize"
                                        [pageSizeOptions]="pageSizeOptions" aria-label="Select page"
                                        (page)="pageChanged($event)">
                                    </mat-paginator>
                                </div>
                                <!-- end table container -->

                            </div>
                        </mat-sidenav-content>

                    </mat-sidenav-container>
                </div>
            </div>

        </div>





    </mat-dialog-content>
    <!-- for adding line -->
    <hr>
    <mat-dialog-actions align="end">

        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>

            <div fxFlex.gt-lg="60" fxFlex.gt-md="60" fxFlex.gt-xs="100" fxFlex="100" fxLayout="row"
                fxLayoutAlign="center center" fxLayoutGap="5px">
                <button mat-stroked-button mat-dialog-close class="secondary"
                    matTooltip="Close this dialog">Close</button>

                <button mat-flat-button style="background-color: #F59120;" color="primary"
                    matTooltip="Send Selected Items" (click)="sendSelectedInvItems()"
                    [disabled]="itemArray.length<1 || selection.selected.length<1">Send Selected
                </button>


            </div>

            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
        </div>

    </mat-dialog-actions>
</div>