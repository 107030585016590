<!-- <h4 color="primary" class="dialog-headline"><p>Create user</p></h4> -->
<div class="dialog-headline">
    <mat-label style="float:left;">{{receivedData.headerTitle}}</mat-label>
    <mat-icon *ngIf="!changePasswordAtLogin" class="pointer" matTooltip="Close this dialog" mat-dialog-close
        style="float: right;">close</mat-icon>
</div>
<!-- <h2 mat-dialog-title style="background-color: rgb(38, 142, 226);">Install Angular</h2> -->
<mat-dialog-content class="mat-typography" style="padding-top: 20px;">

    <!-- user form taking values -->

    <form #changePasswordModalForm="ngForm" autocomplete="off">

        <div fxLayout="row wrap">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

                <mat-form-field>
                    <input matInput placeholder="User Name" name="userName" #userName="ngModel" id="userName"
                        [(ngModel)]="userData.userName" required readonly>

                </mat-form-field>

            </div>
        </div>
        <br>
        <div fxLayout="row wrap">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput placeholder="Old Password" name="oldPassword" #oldPassword="ngModel"
                        id="oldPassword" [(ngModel)]="userData.oldPassword" required
                        [type]="hideOldPwd ? 'password' : 'text'">

                    <mat-icon class="pointer" matSuffix (click)="hideOldPwd = !hideOldPwd">{{hideOldPwd ?
                        'visibility_off' :
                        'visibility'}}
                    </mat-icon>

                    <mat-error *ngIf="oldPassword.invalid && (oldPassword.dirty || oldPassword.touched)"
                        class="invalid-feedback">
                        Please enter old password
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <br>

        <div fxLayout="row wrap">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput placeholder="New Password" name="newPassword" #newPassword="ngModel"
                        id="newPassword" [(ngModel)]="userData.newPassword" required
                        [type]="hideNewPwd ? 'password' : 'text'">

                    <mat-icon class="pointer" matSuffix (click)="hideNewPwd = !hideNewPwd">{{hideNewPwd ?
                        'visibility_off' :
                        'visibility'}}
                    </mat-icon>

                    <mat-error *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched)"
                        class="invalid-feedback">
                        Please enter new password
                    </mat-error>
                </mat-form-field>

                <mat-password-strength (onStrengthChanged)="onPasswordStrengthChanged($event);" [externalError]="true"
                    [min]="8" [max]="10" #passwordComponent [password]="newPassword.value">
                </mat-password-strength>

                <mat-accordion>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                        [hideToggle]="showError">
                        <mat-expansion-panel-header>
                            <mat-panel-description>
                                <mat-icon *ngIf="!panelOpenState">arrow_forward</mat-icon>
                                <mat-icon *ngIf="panelOpenState">arrow_downward</mat-icon>
                                Expand to see errors
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <mat-password-strength-info [passwordComponent]="passwordComponent">
                        </mat-password-strength-info>
                    </mat-expansion-panel>

                </mat-accordion>
            </div>
        </div>

        <br>

        <div fxLayout="row wrap">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput placeholder="Confirm Password" name="confirmPassword" #confirmPassword="ngModel"
                        id="confirmPassword" [(ngModel)]="userData.confirmPassword" required
                        [type]="hideConfirmPwd ? 'password' : 'text'">

                    <mat-icon class="pointer" matSuffix (click)="hideConfirmPwd = !hideConfirmPwd">{{hideConfirmPwd ?
                        'visibility_off' :
                        'visibility'}}
                    </mat-icon>


                    <mat-error *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)"
                        class="invalid-feedback">
                        Please enter confirm password
                    </mat-error>

                    <mat-error *ngIf="(userData.confirmPassword!=userData.password)" class="invalid-feedback">
                        confirm password does not match with password
                    </mat-error>
                </mat-form-field>

            </div>
        </div>

    </form>
    <br>


</mat-dialog-content>
<!-- for adding line -->
<hr>
<mat-dialog-actions align="end">
    <button *ngIf="!changePasswordAtLogin" mat-stroked-button mat-dialog-close class="secondary"
        matTooltip="Close this dialog">
        <mat-icon>close</mat-icon>Close
    </button>

    <button *ngIf="!changePasswordAtLogin" mat-flat-button color="warn"
        [disabled]="changePasswordModalForm.invalid || !(isPasswordStrong && userData.confirmPassword==userData.newPassword)"
        (click)="changeUserPassword();" cdkFocusInitial matTooltip="Change Password">
        Change Password
    </button>

    <button *ngIf="changePasswordAtLogin" mat-flat-button color="warn"
        [disabled]="changePasswordModalForm.invalid || !(isPasswordStrong && userData.confirmPassword==userData.newPassword)"
        (click)="changeUserPasswordAtLogin();" cdkFocusInitial matTooltip="Change Password">
        Change Password
    </button>

</mat-dialog-actions>