<div>

    <div class="dialog-headline">
        <mat-label style="float:left;">Search Customer</mat-label>
        <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
    </div>

    <mat-dialog-content class="mat-typography" style="padding-top: 20px;">


        <div fxLayout="row wrap">

            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <mat-card fxLayoutGap="20px">

                    <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fFlex="100">
                        <mat-form-field fxFlex="70">
                            <input matInput placeholder="First Name" [(ngModel)]="searchCust.firstName" name="firstName"
                                id="firstName" class="form-group">
                        </mat-form-field>
                    </div>

                    <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field fxFlex="70">
                            <input matInput placeholder="Last Name" [(ngModel)]="searchCust.lastName" name="lastName"
                                id="lastName" class="form-group">
                        </mat-form-field>
                    </div>

                    <div fxFlex.gt-lg="15" fxFlex.gt-md="15" fxFlex.gt-xs="100" fxFlex="100">

                        <mat-form-field>
                            <input matInput [matDatepicker]="dateOfBirthInStr" placeholder="DOB" name="SaleStartDate"
                                #DOB="ngModel" [(ngModel)]="searchCust.dateOfBirthInStr" readonly>
                            <mat-datepicker-toggle matSuffix [for]="dateOfBirthInStr"></mat-datepicker-toggle>
                            <mat-datepicker #dateOfBirthInStr></mat-datepicker>
                        </mat-form-field>
                    </div>


                    <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100" style="padding-top: 10px;">
                        <button mat-flat-button color="primary" style="float: right;" (click)="searchCustomer()">
                            <mat-icon>search</mat-icon>Search Customer
                        </button>
                    </div>

                </mat-card>
            </div>

        </div>



        <form #deptSearchModalForm="ngForm" autocomplete="off">

            <div class="example-container mat-elevation-z8" *ngIf="showTableForTransactionReport">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

                    <mat-sidenav-container>

                        <mat-sidenav #sidenav position="start" mode="side" opened="false" class="width-30 p-6">
                            SIDENAV CONTENT HERE
                        </mat-sidenav>

                        <mat-sidenav-content>
                            <div class="mat-elevation-z8 m-12">
                                <div class="table-container">
                                    <!-- <table mat-table [dataSource]="dataSource"> -->
                                    <mat-table id="searchCustTable" matTableExporter matSort
                                        [dataSource]="custDataSource">

                                        <ng-container matColumnDef="Action">
                                            <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
                                            <mat-cell *matCellDef="let element">

                                                <button mat-icon-button matTooltip="Select {{element.description}}"
                                                    color="primary" (click)="sendSelectedCust(element)">
                                                    Select
                                                </button>

                                            </mat-cell>
                                        </ng-container>


                                        <ng-container matColumnDef="customerID">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>Cust. Id
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.customerID}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="customerCode">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>Cust.
                                                Code</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.customerCode}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="firstName">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>First
                                                Name</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.firstName}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="lastName">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>Last
                                                Name</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.lastName}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="cellNo">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>Cell
                                                No.</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.cellNo}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="address1">
                                            <mat-header-cell *matHeaderCellDef
                                                mat-sort-header>Address1</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.address1}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="address2">
                                            <mat-header-cell *matHeaderCellDef
                                                mat-sort-header>Address2</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.address2}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="city">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>City</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.city}} </mat-cell>
                                        </ng-container>

                                        <mat-header-row
                                            *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
                                        <mat-row *matRowDef="let row; columns: displayedColumns;"
                                            [ngClass]="{'selected': row.hovered}" (mouseover)="row.hovered = true"
                                            (mouseout)="row.hovered = false" (click)="selection.toggle(row)"></mat-row>
                                        <!-- <mat-row *matRowDef="let row; columns: displayedColumns;"
                                            (click)="selectedRow = selectedRow === row ? null : row"
                                            [ngClass]="{ 'selected': row === selectedRow }"
                                            (click)="selection.toggle(selectedRow)"></mat-row> -->

                                    </mat-table>


                                    <mat-paginator fxLayoutAlign="start" #paginator [length]="totalRows"
                                        [pageIndex]="currentPage" [pageSize]="pageSize"
                                        [pageSizeOptions]="pageSizeOptions" aria-label="Select page"
                                        (page)="pageChanged($event)">
                                    </mat-paginator>
                                </div>
                                <!-- end table container -->

                            </div>
                        </mat-sidenav-content>

                    </mat-sidenav-container>
                </div>
            </div>


        </form>


    </mat-dialog-content>
    <!-- for adding line -->
    <hr>
    <mat-dialog-actions align="end">



        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>

            <div fxFlex.gt-lg="60" fxFlex.gt-md="60" fxFlex.gt-xs="100" fxFlex="100" fxLayout="row"
                fxLayoutAlign="center center" fxLayoutGap="5px">

                <button mat-flat-button style="background-color: #F59120;" color="primary"
                    matTooltip="Send Selected Items" (click)="sendSelectedDepts()"
                    [disabled]="custArray.length<1 || selection.selected.length<1">Send Selected
                </button>

                <button mat-stroked-button mat-dialog-close class="secondary" matTooltip="Close this dialog">
                    <mat-icon>close</mat-icon>Close
                </button>


            </div>

            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
        </div>

    </mat-dialog-actions>
</div>