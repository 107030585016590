import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CountdownAlertComponent } from 'src/app/utility/countdown-alert/countdown-alert.component';
import { AlertDialogComponent } from '../../utility/alert-dialog/alert-dialog.component';
import { PromptDialogComponent } from '../../utility/prompt-dialog/prompt-dialog.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(public dialog: MatDialog,private router:Router) { }

  //open prompt


  openPrompt(promptTitle: string, promptMessage: string) {

    const promptDialogRef = this.dialog.open(PromptDialogComponent, {
      data: {
        promptTitle: promptTitle,
        promptMessage: promptMessage
      }
    });

    return promptDialogRef.afterClosed();
  }


  //show respective alerts
  showAlert(alertType: any, alertTitle: any, alertMessage: any) {
    const alertDialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        alertType: alertType,
        alertTitle: alertTitle,
        alertMessage: alertMessage
      }, width: '20%',
      disableClose:true
    });
    return alertDialogRef.afterClosed();

  }

  showAlertAndTakeToLogin(alertType: any, alertTitle: any, alertMessage: any) {
    const alertDialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        alertType: alertType,
        alertTitle: alertTitle,
        alertMessage: alertMessage
      }, width: '20%'
    });
    return alertDialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        // window.location.replace('/login');
        this.router.navigateByUrl('/login');
      }
    });

  }

  //show respective alerts
  showCountDownAlert(alertType: any, alertTitle: any, alertMessage: any) {
    const alertDialogRef = this.dialog.open(CountdownAlertComponent, {
      data: {
        alertType: alertType,
        alertTitle: alertTitle,
        alertMessage: alertMessage
      }, width: '20%'
    });

    return alertDialogRef.afterClosed();

  }


  AllowOnlyNumber(event: any) {

    var inputValue = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[0-9]/.test(inputValue)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  AllowOnlyCharacter(event: any) {

    // Allow numbers, alpahbets, space, underscore
    if (/^[A-Za-z]$/.test(event.key)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  AllowCharacterWithSpace(event: any) {

    // Allow numbers, alpahbets, space, underscore
    if (/^[A-Za-z ]$/.test(event.key)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  AllowDecimal(event: any) {
    // Allow numbers, and at most one period after numeric values
    if (/^[0-9]$/.test(event.key)) {
      return true;
    } else if (event.key === '.' && event.target.value && !event.target.value.includes('.') && event.target.selectionStart > 0) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  
   IsNullOrWhiteSpace(str: string | null | undefined): boolean {
    return !str || str.trim() === '';
  }
  

}

enum PropertyType {
  SUCCESS = 'SUCCESS',
  ALERT = 'Alert',
  Error = 'Error',
  EMPTY = 'Info'
}


export class ModalProperties {
  title!: string;
  message!: string;
  alertType: PropertyType = PropertyType.EMPTY
}
