<div class="main-container">
  <mat-toolbar color="#FFFFFF" class="topbar telative" style="background-color: #FFFFFF;">
    <div class="navbar-header" style="text-align: left;">
      <a routerLink="/home" class="navbar-brand" style="cursor: pointer;" (click)="clearSessionStorage()">
        <b>
          <img src="assets/images/logos/PrimeRx_POS_Logo-032224_Full-Logo-Full-Color-V1.jpg" 
               width="75%" 
               alt="homepage" 
               class="light-logo">
        </b>
        <span fxShow="false" fxShow.gt-xs></span>
      </a>
    </div>
  
    <div fxFlex fxLayout="row" fxLayoutAlign="end center" fxFlex="100">
      <div class="item" style="padding: 20px;"><label style="font-size: 16px; font-weight: bold; text-align: left; color: #0C7BB8;">NPINo-{{userAccessData.NPINo}}</label></div>
      <div class="item" style="padding: 20px;"><label style="font-size: 16px; font-weight: bold; text-align: left; color: #0C7BB8;">{{userAccessData.storeName}}</label></div>
    </div>
    <span fxFlex></span>
    <app-header></app-header>
  </mat-toolbar>

  <mat-drawer-container class="example-container mat-typography" autosize>
    <mat-drawer #drawer mode="side" disableClose="true" opened="true" style="background-color: #1B2033;" [ngStyle]="{'width.px': isExpanded ? 50 : 230}">
      
      <app-sidebar [sidenavOpened]="isExpanded"></app-sidebar>
      
      <div style="position: absolute; bottom: 50px; left: 45%; transform: translateX(-50%); text-align: center;">
        <button mat-icon-button (click)="isExpanded = !isExpanded" value="sidebarclosed" color="#F59120" [matTooltip]="getTooltipText()" style="padding-left: 10px;color: #F59120;">
          <mat-icon>{{ isExpanded ? 'chevron_right' : 'chevron_left' }}</mat-icon>
        </button>
      </div>
      <div *ngIf="!isExpanded" style="position: absolute; bottom: 30px; left: 45%; transform: translateX(-50%); text-align: center;">
        <mat-label style="color: #ADADAD;font-size:x-small;">Version No.: 1.0.0</mat-label>
      </div>
      <div *ngIf="!isExpanded" style="position: absolute; bottom: 10px; left: 10%; transform: translateX(-5%); text-align: center;">
        <mat-label style="color: #ADADAD;font-size:x-small;">2024 © EPrimePOS All rights reserved</mat-label>
      </div>
     
      
    </mat-drawer>
    <mat-drawer-content class="page-wrapper">
      <div>
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
  
  
</div>
