import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

export enum CallType {
  VENDOR_INFO = "VendorInformation",
  VENDOR_CONNECTION = "VendorConnection",
  VENDOR_FIELDS = "VendorFields"
}

export class constant {

  //http header variable
  public static httpClientHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  public static APP_ID: string = "MPOSWeb";
  public static KEY_NPI = 'NPINo';
  public static KEY_USERNAME = 'userName';
  public static KEY_STORE_NAME = 'storeName';
  public static KEY_PSEUDO_NAME = 'pseudoName';
  public static KEY_USER_RIGHTS = 'userRights';
  public static KEY_SUCCESS = 'SUCCESS';
  public static KEY_ERROR = 'ERROR';
  public static KEY_WARNING = 'WARNING';
  public static KEY_FAILURE = 'FAILURE';
  public static ALERT_WARNING_TITLE = 'Warning';
  public static ALERT_CREATE_TITLE = 'Created';
  public static ALERT_UPDATE_TITLE = 'Updated';
  public static ALERT_DELETE_TITLE = 'Deleted';
  public static ALERT_FAILED_TITLE = 'Failed';
  public static ALERT_SUCCESS_TITLE = 'Success';
  public static ALERT_TIMEOUT_TITLE = 'Time out';
  public static KEY_STATION_DETAILS = 'stationDetail';
  public static KEY_POAckData = 'POAckData';
  public static KEY_STORE_DETAILS = 'storeDetails';
  public static KEY_DATANOTFOUND = "Data Not Found";

  public static TOKEN_KEY = 'auth-token';

  // Host/Ports
  //public static baseURL: string = "http://localhost:";  //for testing locally
  // public static ItemDepartmentTag: string = "9030";
  // public static ItemTag: string = "9030";
  // public static ManageRightsTag: string = "9030";
  // private static UserTag: string = "9030";
  // private static StoreTag: string = "9030";
  // private static RoleTag: string = "9030";
  // private static CustomereTag: string = "9030";
  // private static TaxTag: string = "9030";
  // private static ReconTag: string = "9030";
  // private static DashboardTag: string = "9030";
  // private static VendorOrderTag: string = "9030/edi";
  // private static VendorTag: string = "9030/edi";
  // private static storeConfigStoreTag: string = "9030";
  // private static storeConfigUserTag: string = "9030";
  // private static ediUserTag: string = "9030";
  // private static inventoryTag: string = "9030";
  // private static clpSettingsTag: string = "9030";
  // private static transactionTag: string = "9030";
  // private static paytypeTag: string = "9030";
  // private static ReportServiceTag: string ="9030";
  // private static schedulerTag: string = "9030";
  // private static pointyTag: string = "9030";

  // //Ports
  public static baseURL: string = environment.baseURL;
  public static ItemDepartmentTag: string = "ePrimePOSItemDepartmentService";
  public static ItemTag: string = "ePrimePOSItemService";
  public static ManageRightsTag: string = "ePrimePOSManageRightsService";
  private static UserTag: string = "ePrimePOSUsersService";
  private static StoreTag: string = "ePrimePOSStoreService";
  private static RoleTag: string = "ePrimePOSRolesService";
  private static CustomereTag: string = "ePrimePOSCustomerService";
  private static TaxTag: string = "ePrimePOSTaxService";
  private static ReconTag: string = "ePrimePOSReconService";
  private static DashboardTag: string = "ePrimePOSDashboardService";
  private static VendorOrderTag: string = "ediVendorOrderService";
  private static VendorTag: string = "ediVendorService";
  private static storeConfigStoreTag: string = "storeConfigStoreService";
  private static storeConfigUserTag: string = "storeConfigUserService";
  private static ediUserTag: string = "ediUserService";
  private static inventoryTag: string = "ePrimePOSInventoryService";
  private static clpSettingsTag: string = "ePrimePOSClpSettingsService";
  private static transactionTag: string = "ePrimePOSTXNService";
  private static paytypeTag: string = "ePrimePOSPaytypeMasterService";
  private static ReportServiceTag: string = "ePrimePOSReportsService";
  private static schedulerTag: string = "ePrimePOSSchedulerService";
  private static pointyTag: string = "ePrimePOSPointyConsumerService";
  //Suggestive Selling Service
  private static suggSelTag: string ="SuggestiveSellingService";
  private static drugServiceTag: string ="DrugProviderService";

  //URL's

  //User
  public static userLoginUrl: string = constant.baseURL + this.UserTag + "/Users/auth/authenticateUser";
  public static refreshToken: string = constant.baseURL + this.UserTag + "/Users/auth/refresh-token";
  public static userLoginAuthStore: string = constant.baseURL + this.UserTag + "/Users/auth/authenticateStore";
  public static getUserUrl: string = constant.baseURL + this.UserTag + "/Users/getuserbyNpiNo";
  public static getUserRights: string = constant.baseURL + this.UserTag + "/Users/auth/fetchAuthUserRights";
  public static getUsersUrl: string = constant.baseURL + this.UserTag + "/Users/getUsersAsyncByNPI";
  public static insertUserUrl: string = constant.baseURL + this.UserTag + "/Users/InsertUsers";
  public static updateUserUrl: string = constant.baseURL + this.UserTag + "/Users/UpdateUsers";
  public static deleteUserUrl: string = constant.baseURL + this.UserTag + "/Users/DeleteUsers";
  public static changeUserPasswordUrl: string = constant.baseURL + constant.UserTag + "/Users/changeUserPassword";
  public static changeUserPasswordAtLoginUrl: string = constant.baseURL + constant.UserTag + "/Users/changeUserPasswordAtLogin";
  public static getUserByUserNameUrl: string = constant.baseURL + constant.UserTag + "/Users/getUserByUserName";
  public static getGroupPharmacyByPseudoNameUrl: string = constant.baseURL + constant.UserTag + "/Users/getGroupPharmacyByPseudoName";
  public static getUserByRole: string = constant.baseURL + constant.UserTag + "/Users/getUserByRole";

  //Store
  public static getStoreByNPI: string = constant.baseURL + this.StoreTag + "/Store/getStore";
  public static getStoresForNPI: string = constant.baseURL + this.StoreTag + "/Store/getStore";
  public static getEODHistoryByNPI: string = constant.baseURL + this.StoreTag + "/Store/getEODHistory";
  public static getConfiguredStations: string = constant.baseURL + this.StoreTag + "/Store/getConfiguredStations";
  public static resetStationByNPI: string = constant.baseURL + this.StoreTag + "/Store/resetStation";
  public static updateStoreUrl: string = constant.baseURL + this.StoreTag + "/Store/updateStore";

  public static fetchEODDataUrl: string = constant.baseURL + this.StoreTag + "/Store/initEOD";
  public static checkEODEligibilityUrl: string = constant.baseURL + this.StoreTag + "/Store/checkEODEligibility";
  public static saveEODUrl: string = constant.baseURL + this.StoreTag + "/Store/saveEOD";

  public static fetchStoreDataFromStoreConfigUrl: string = constant.baseURL + this.storeConfigStoreTag + "/Store/fetchStoreDataFromStoreConfig";
  public static changePasswordWithCheckboxInStoreConfigUrl: string = constant.baseURL + this.storeConfigUserTag + "/User/changePasswordWithCheckboxInStoreConfig";
  public static changePasswordWithCheckboxInEDIUrl: string = constant.baseURL + this.ediUserTag + "/User/changeUserPasswordAtLogin";

  //itemDepartment
  public static getItemDepartmentUrl: string = constant.baseURL + this.ItemDepartmentTag + "/ItemDepartment/getItemDepartmentByNPI";
  public static createItemDepartmentUrl: string = constant.baseURL + this.ItemDepartmentTag + "/ItemDepartment/InsertItemDepartment";
  public static updateItemDepartmentUrl: string = constant.baseURL + this.ItemDepartmentTag + "/ItemDepartment/UpdateItemDepartment";
  public static deleteItemDepartmentUrl: string = constant.baseURL + this.ItemDepartmentTag + "/ItemDepartment/DeleteItemDepartment";
  public static insertDefaultItemDepartmentUrl: string = constant.baseURL + this.ItemDepartmentTag + "/ItemDepartment/insertDefaultItemDepartment";
  public static getItemDepartmentPaginationUrl: string = constant.baseURL + this.ItemDepartmentTag + "/ItemDepartment/getItemDeptByNPIWithPagination";
  //item
  public static getItemUrl: string = constant.baseURL + this.ItemTag + "/Items/getItemByNPIWithVendor";
  public static getBaseItemUrl: string = constant.baseURL + this.ItemTag + "/Items/getBaseItem";
  public static getItemByIDList: string = constant.baseURL + this.ItemTag +"/Items/getItemByIDList";
  public static getAllNpiItemUrl: string = constant.baseURL + this.ItemTag + "/Items/getItemByNPI";
  //public static getAllNpiItemUrl: string="http://localhost:8013/Items/getItemByNPI";
  // public static getBaseItemUrl: string = "http://localhost:9030"+ "/Items/getBaseItem";

  public static getItemPriceHistoryUrl: string = constant.baseURL + this.ItemTag + "/Items/getPriceHistory";
  public static reactivateDeletedItemUrl: string = constant.baseURL + this.ItemTag + "/Items/reactivateDeletedItem";

  public static updatePriceHistory: string = constant.baseURL + this.ItemTag + "/Items/fetchAndUpdatePrice";
  public static getItemVendorDataUrl: string = constant.baseURL + this.ItemTag + "/Items/getItemVendorData";
  public static insertItemVendorDataUrl: string = constant.baseURL + this.ItemTag + "/Items/insertItemVendor";
  //public static insertItemVendorDataUrl: string = "http://localhost:8013/Items/insertItemVendor";
  public static updateLastVendorDataUrl: string = constant.baseURL + this.ItemTag + "/Items/updateLastVendor";
  //LABEL FIELDS
  public static saveLabelTemplateUrl: string = constant.baseURL + this.ItemTag + "/Items/saveLabelTemplate";
  public static deleteLabelTemplateUrl: string = constant.baseURL + this.ItemTag + "/Items/deleteLabelTemplate";
  public static getByTemplateNameUrl: string = constant.baseURL + this.ItemTag + "/Items/getByTemplateName";
  public static updateLabelTemplateUrl: string = constant.baseURL + this.ItemTag + "/Items/updateLabelTemplate";
  public static generateLabelsUrl: string = constant.baseURL + this.ItemTag + "/Items/generateLabels";
  public static createLabelPDFUrl: string = constant.baseURL + this.ItemTag + "/Items/generateLabelsPDF";
  public static CheckItemCodeExistsUrl: string = constant.baseURL + this.ItemTag + "/Items/CheckItemCodeExists";//EPPOS-3245

  // item monitoring category
  public static getItemMonitoringCategoriesUrl: string = constant.baseURL + this.ItemTag + "/Items/getItemMonitoringCategories";
  public static insertItemMonitoringCategoriesUrl: string = constant.baseURL + this.ItemTag + "/Items/insertItemMonitoringCategories";
  public static updateItemMonitoringCategoriesUrl: string = constant.baseURL + this.ItemTag + "/Items/updateItemMonitoringCategories";
  public static deleteItemMonitoringCategoriesUrl: string = constant.baseURL + this.ItemTag + "/Items/deleteItemMonitoringCategories";

  //Tax
  public static getTaxUrl: string = constant.baseURL + this.TaxTag + "/Tax/getTaxById";
  public static getTaxesByNPI: string = constant.baseURL + this.TaxTag + "/Tax/getTaxByID";
  public static createTaxForNPI: string = constant.baseURL + this.TaxTag + "/Tax/InsertTax";
  public static updateTaxForNPI: string = constant.baseURL + this.TaxTag + "/Tax/UpdateTax";
  public static deleteTaxForNPI: string = constant.baseURL + this.TaxTag + "/Tax/DeleteTax";
  public static insertDefaultTaxUrl: string = constant.baseURL + this.TaxTag + "/Tax/insertDefaultTax";
  public static getItemTaxesByEntityIdUrl: string = constant.baseURL + this.ItemTag + "/Items/getItemTaxByEntityId";

  // quick add items
  public static getQuickAddItemsOnLoadUrl: string = constant.baseURL + this.ItemTag + "/Items/getQuickAddItems";
  public static createQuickAddItemsUrl: string = constant.baseURL + this.ItemTag + "/Items/createUpdateQuickAddOrder";
  public static deleteQuickAddItemsUrl: string = constant.baseURL + this.ItemTag + "/Items/deleteQuickAddOrderItem";

  public static getQuickAddItemsUrl: string = constant.baseURL + this.ItemTag + "/Items/getQuickItemsByNPIID";

  public static createUpdateQuickAddGroupUrl: string = constant.baseURL + this.ItemTag + "/Items/createUpdateQuickAddGroup";
  public static deleteQuickAddGroupItemUrl: string = constant.baseURL + this.ItemTag + "/Items/deleteQuickAddGroupItem";
  public static createItemUrl: string = constant.baseURL + this.ItemTag + "/Items/InsertItem";
  public static getAggregateItemUrl: string = constant.baseURL + this.ItemTag + "/Items/getAggregateItem";
  public static deleteItemUrl: string = constant.baseURL + this.ItemTag + "/Items/DeleteItem";
  public static updateItemUrl: string = constant.baseURL + this.ItemTag + "/Items/updateItemWithVendor";
  public static insertDefaultItemUrl: string = constant.baseURL + this.ItemTag + "/Items/loadDefaultItemFromEDI";

  public static getAllAggregateItemUrl: string = constant.baseURL + this.ItemTag + "/Items/getAllAggregateItemByNpi";

  //Role
  public static getRoleUrl: string = constant.baseURL + this.RoleTag + "/Roles/getActiveRoles";
  public static getRolesOnLoadUrl: string = constant.baseURL + this.RoleTag + "/Roles/getRolesAsyncByNPI";
  public static createRoleUrl: string = constant.baseURL + this.RoleTag + "/Roles/InsertRole";
  public static updateRoleUrl: string = constant.baseURL + this.RoleTag + "/Roles/UpdateRole";
  public static deleteRoleUrl: string = constant.baseURL + this.RoleTag + "/Roles/DeleteRole";
  public static getModulesUrl: string = constant.baseURL + this.RoleTag + "/Roles/getModules";

  // public static getRoleUrl: string = "https://localhost:" + "8048" + "/Roles/getActiveRoles";
  // public static getRolesOnLoadUrl: string = "https://localhost:" + "8048"+ "/Roles/getRolesAsyncByNPI";
  // public static createRoleUrl: string = "https://localhost:" + "8048" + "/Roles/InsertRole";
  // public static updateRoleUrl: string = "https://localhost:" + "8048" + "/Roles/UpdateRole";
  // public static deleteRoleUrl: string = "https://localhost:" + "8048" + "/Roles/DeleteRole";
  // public static getModulesUrl: string = "https://localhost:" + "8048" + "/Roles/getModules";

  //manage rights
  public static getRolesByRightUrl: string = constant.baseURL + this.ManageRightsTag + "/ManageRights/getManageRightsByID";
  public static InsertManageRightsUrl: string = constant.baseURL + this.ManageRightsTag + "/ManageRights/InsertManageRights";
  public static UpdateManageRightsUrl: string = constant.baseURL + this.ManageRightsTag + "/ManageRights/UpdateManageRights";
  public static DeleteManageRightsUrl: string = constant.baseURL + this.ManageRightsTag + "/ManageRights/DeleteManageRights";

  //charts
  public static getSaleTrendChartUrl: string = constant.baseURL + this.DashboardTag + "/Dashboard/getSaleTrendChart";
  public static getSaleByDepChartUrl: string = constant.baseURL + this.DashboardTag + "/Dashboard/getSaleByDepChart";
  public static getSaleByEmployeeChartUrl: string = constant.baseURL + this.DashboardTag + "/Dashboard/getSaleByEmployeeChart";
  public static getTopPerformingItemUrl: string = constant.baseURL + this.DashboardTag + "/Dashboard/getTopPerformingItem";
  public static getSaleByRegisterChartUrl: string = constant.baseURL + this.DashboardTag + "/Dashboard/getSaleByRegisterChart";
  public static getTotalByPaymentTypeUrl: string = constant.baseURL + this.DashboardTag + "/Dashboard/getTotalByPaymentType";
  public static getTotalByTransTypeUrl: string = constant.baseURL + this.DashboardTag + "/Dashboard/getTotalByTransType";
  public static getTopPerformingDepChartUrl: string = constant.baseURL + this.DashboardTag + "/Dashboard/getTopPerformingDepChart";

  //Customer
  public static getCustomersByNPI: string = constant.baseURL + this.CustomereTag + "/Customer/getCustomersAsyncByNPI";
  public static createCustomer: string = constant.baseURL + this.CustomereTag + "/Customer/InsertCustomer";
  public static updateCustomer: string = constant.baseURL + this.CustomereTag + "/Customer/UpdateCustomer";
  public static deleteCustomer: string = constant.baseURL + this.CustomereTag + "/Customer/DeleteCustomer";
  public static updateClpSettings: string = constant.baseURL + this.CustomereTag + "/Customer/UpdateClpSettings";
  public static getClpByProgram: string = constant.baseURL + this.CustomereTag + "/Customer/GetClpSettingsByProgName";
  public static getAllClpSettingsByNpi: string = constant.baseURL + this.CustomereTag + "/Customer/getAllClpSettingsByNpi";
  public static getClpSettings: string = constant.baseURL + this.CustomereTag + "/Customer/getClpSettings";
  public static deleteClpSettings: string = constant.baseURL + this.CustomereTag + "/Customer/deleteClpSettingsByNpiProgramName";
  public static createCLP: string = constant.baseURL + this.CustomereTag + "/Customer/InsertClp";


  //Recon
  public static getReconDataCountByNPIUrl: string = constant.baseURL + this.ReconTag + "/Recon/getPrePostReconDataCount";
  public static getUnsuccessfulReconTXNUrl: string = constant.baseURL + this.ReconTag + "/Recon/getUnsuccessfulReconTXN";
  public static getAllReconTXNUrl: string = constant.baseURL + this.ReconTag + "/Recon/getAllReconTXN";
  public static getSuccessfulReconTXNUrl: string = constant.baseURL + this.ReconTag + "/Recon/getSuccessfulReconTXN";
  public static getMobileLogDataUrl: string = constant.baseURL + this.ReconTag + "/Recon/getMobileLogData";

  //Dashboard
  public static getLogFile: string = constant.baseURL + this.ReconTag + "/Recon/getLogFile";

  //Vendor Data
  public static getPOAcknowledgementUrl: string = constant.baseURL + this.VendorOrderTag + "/VendorOrder/getPoAcknowledgementData";
  public static getEDIUserUrl: string = constant.baseURL + this.VendorOrderTag + "/User/getUserByRole";
  public static getVendorsByNPINoUrl: string = constant.baseURL + this.VendorTag + "/Vendor/getAllVendorsByNPI";
  public static createPOUrl: string = constant.baseURL + this.VendorOrderTag + "/VendorOrder/createPOFromScreen";
  //public static createPOUrl: string = "http://localhost:8065/VendorOrder/createPOFromScreen";
  public static getPOUrl: string = constant.baseURL + this.VendorOrderTag + "/VendorOrder/getPurchaseOrder";
  public static saveFileUrl: string = constant.baseURL + this.VendorOrderTag + "/VendorOrder/saveInvoiceFile";
  public static getFileDownloadUrl: string = constant.baseURL + this.VendorOrderTag + "/VendorOrder/getFileDownloadData";
  public static getVendorsWithContractByNPINoUrl: string = constant.baseURL + this.VendorTag + "/Vendor/getAllVendorsWithContract";

  //Inventory
  public static mapPODataForInventoryReceivedUrl: string = constant.baseURL + this.inventoryTag + "/Inventory/mapPODataForInventoryReceived";
  public static getInvTransTypeDataUrl: string = constant.baseURL + this.inventoryTag + "/Inventory/getInvTransTypeData";
  public static getItemJoinInventoryDataUrl: string = constant.baseURL + this.inventoryTag + "/Inventory/getItemJoinInventoryData";
  public static saveInventoryDataUrl: string = constant.baseURL + this.inventoryTag + "/Inventory/saveInventoryData";

  //#region StateMaster Service EPPOS-3807
  public static StateMasterTag: string = "/StateMasterService"; //#EPPOS-3807


  public static stateCityByZipURL: string = constant.baseURL + this.StateMasterTag + "/StateMaster/stateCityByZip";
  // public static stateCityByZipURL: string = "http://localhost:8097/StateMaster/stateCityByZip";
  //#endregion

  //CLP Settings
  public static getSettingsByNpi: string = constant.baseURL + this.clpSettingsTag + "/ClpSettings/getSettingsByNpi";
  public static insertClpSettings: string = constant.baseURL + this.clpSettingsTag + "/ClpSettings/InsertClpSettings";


  //Discount Settings
  public static getDiscountSettingsByNpi: string = constant.baseURL + this.clpSettingsTag + "/ClpSettings/getDiscountSettingsByNpi";
  public static insertDiscountSettings: string = constant.baseURL + this.clpSettingsTag + "/ClpSettings/InsertDiscountSettings";
  public static insertExcludedSettings: string = constant.baseURL + this.clpSettingsTag + "/ClpSettings/InsertExcludeSettings";

  //manage rights strings
  public static mobileAdministrativeFunction = 'Mobile Administrative Function';
  public static webAdministrativeFunction = "Web Administrative Function";
  public static mobilePOSFunction = 'Mobile POS Function';

  //Transaction
  public static getAllTransactionsByNpi: string = constant.baseURL + this.transactionTag + "/Transaction/getAllTransactionsOfNPI";



  //Reports Service
  public static getSalesByItemReportDataUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/getSalesByItemReport";
  public static getSalesByCustomerReportDataUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/getSalesByCustomerReport";
  public static getPayoutTransactionsForChart: string = constant.baseURL + this.ReportServiceTag + "/Report/getPayoutTransactionsForChart";
  public static getPayoutTransactionsForReport: string = constant.baseURL + this.ReportServiceTag + "/Report/getPayoutTransactionsForReport";
  public static getSalesByPaymentReportDataUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/getSalesByPaymentReport";
  public static getDiscountReportDataUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/getDiscountReport";
  public static getSalesByDepartmentReportDataUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/getSalesByDepartmentReport";
  public static getSalesByUserReportDataUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/getSalesByUserReport";
  public static getItemPriceHistoryReportUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/getPriceHistoryReport";
  public static getSalesByPaymentReportPDFUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/generateSalesByPaymentPDF";
  // public static getSalesByPaymentReportPDFUrl: string = "http://localhost:8053/Report/generateSalesByPaymentPDF";
  public static getInventoryStatusReportUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/getInventoryStatusReport";
  public static getAllTransactionsReport: string = constant.baseURL + this.ReportServiceTag + "/Report/getAllTransactionsOfNPI";
  public static getInventoryReceivedReportUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/getInventoryReceivedReport";

  public static getPriceOverrideReportUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/getPriceOverrideReport";
  public static gettaxOverrideReportUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/getTaxOverrideReport";
  public static getInventoryStatusPDFReportUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/getInventoryStatusPDFReport";
  public static getSalesByDepartmentPDFReportUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/getSalesByDepartmentPDFReport";
  public static getSalesByCustomerReportPDFUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/getSalesByCustomerPDFReport";
  public static getPayoutReportPDFUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/GetPayoutPDFReport";
  public static getSalesByUserReportPDFUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/getSalesByUserPDFReport";
  public static getSalesByItemReportPDFUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/GetSalesByItemPDFReport";
  public static getInventoryReceivedReportPDFUrl: string = constant.baseURL + this.ReportServiceTag + "/Report/GetInventoryReceivedPDFReport";

  //paytype
  public static getPaytypeDetailUrl: string = constant.baseURL + this.paytypeTag + "/PaytypeMaster/getPaytypeMasterAsync";
  public static getPaytypeDetailByNpiUrl: string = constant.baseURL + this.paytypeTag + "/PaytypeMaster/getPaytypeMasterByNPI";
  //public static getPaytypeDetailByNpiUrl: string="http://localhost:8021/PaytypeMaster/getPaytypeMasterByNPI";
  public static INSERTMASTERPAYTYPE: string = constant.baseURL + this.paytypeTag + "/PaytypeMaster/InsertPaytypeMaster"
  public static UPDATEMASTERPAYTYPEBYNPI: string = constant.baseURL + this.paytypeTag + "/PaytypeMaster/UpdatePaytypeMaster"
  public static GETMASTERPAYTYPEBYNPI: string = constant.baseURL + this.paytypeTag + "/PaytypeMaster/getPaytypeMasterByNPI"

  //Pointy
  public static signUpURL: string = constant.baseURL + this.pointyTag + "/Pointy/signup";
  public static UpdatePointyInventoryCronSchedulerURL: string = constant.baseURL + this.schedulerTag + "/Scheduler/UpdatePointyInventoryCronScheduler";
  public static SavePointyInventoryCronInfoURL: string = constant.baseURL + this.schedulerTag + "/Scheduler/SavePointyInventoryCronInfo";
  public static GetSchedulerURL: string = constant.baseURL + this.schedulerTag + "/Scheduler/GetScheduler";
  public static updatePointyDataUrl: string = constant.baseURL + this.StoreTag + "/Store/updatePointyData";

  //Suggestive Selling Service
  public static GetSuggSelSettingsByNpi: string = constant.baseURL + this.suggSelTag + "/SuggesiveSelling/GetByNpi";
  //public static GetSuggSelSettingsByNpi: string = "https://localhost:8066/SuggesiveSelling/GetByNpi";
  public static SaveNdcMapping: string = constant.baseURL + this.suggSelTag + "/SuggesiveSelling/SaveNdcMapping";
  public static SaveConceptMapping: string = constant.baseURL + this.suggSelTag + "/SuggesiveSelling/SaveConceptMapping";
  public static GetOTCItems: string = constant.baseURL + this.suggSelTag + "/SuggesiveSelling/GetOTCItems";
  public static DeleteMapping: string = constant.baseURL + this.suggSelTag + "/SuggesiveSelling/DeleteMapping";

  //Drug Provider Service
  public static GetDrugInfo: string = constant.baseURL + this.drugServiceTag + "/DrugProvider/GetDrugInfo";

  public static mobileUser = 'Mobile User';
  public static mobileCustomer = "Mobile Customer";
  public static mobileItem = 'Mobile Item';
  public static stationClose = "Station Close";

  public static webUser = 'Web User';
  public static webCompanySetup = "Store"; //18-11-2022
  public static webCustomer = "Web Customer";
  public static webDepartment = 'Web Department';
  public static webItem = 'Web Item';
  public static inventoryReport = "Inventory Report";
  public static EOD = 'EOD';
  public static Preferences = "Preferences";
  public static Role = 'Role';
  public static ManageRights = "Manage Rights";
  public static StoreView = 'Store View';
  public static ResetStation = "Reset Station";
  public static QuickAddOrder = 'Quick Add Order';
  public static QuickAddGroup = "Quick Add Group";
  public static Recon = "Recon";
  public static ItemPriceHistory = "Item Price History";
  public static EODHistory = 'EOD History';
  public static Tax = 'Tax';
  public static ItemMonitoringCategory = 'Item Monitoring Category';
  public static PurchaseOrder = 'Purchase Order';
  public static PurchaseOrderAck = 'Purchase Order Acknowledgement';
  public static File810Processing = '810 File Processing';
  public static LabelDesing = 'Label Design';
  public static ClpSettings = "CLP Settings";
  public static TransactionReport = "Transaction Report";
  public static ManualFileUpdate = "Manual File Update";
  public static ItemPriceLogLabel = "Item Price Log Label";
  public static CustomerSalesReport = "Customer Sales Report";
  public static DepartmentSalesReport = "Department Sales Report";
  public static UserSalesReport = "User Sales Report";
  public static TransactionSummary = "Transaction Summary";
  public static PriceOverrideReport = "Price Override Report";
  public static PayOutReport = "Payout Report";
  public static DiscountReport = "Discount Report";
  public static PaymentSalesReport = "Sales By Payment Report";

  //28/07/2023
  public static SalesItemReport = "Sales By Item Report";
  public static ItemPriceChangeReport = "Item Price Change Report";
  public static InventoryRecdReport = "Inventory Recieved Report";
  public static InventoryStatusReport = "Inventory Status Report";
  public static InventoryReceived = "Inventory Received";//EPPOS-2627

  public static POSSale = 'POS Sale';
  public static MobilePayment = "Mobile Payment";
  public static MobilePOSItem = 'Mobile MPOS Item';
  public static MobilePOSCustomer = "Mobile MPOS Customer";
  public static MobileItemDiscount = 'Mobile Item Discount';
  public static MobilePriceOverride = "Mobile Price Override";
  public static MobileTaxOverrride = 'Mobile Tax Overrride';
  public static ViewStationClose = "View Station Close";
  public static OnHold = 'On Hold';
  public static ViewOnHold = "View On Hold";
  public static blindClose = "Blind Close";
  public static IsStationCloseVerifier = 'Is Station Close Verifier';
  public static IsStoreCreditAllowed = "Is Store Credit Allowed";
  public static IsNoSaleAllowed = "Is No Sale Allowed";
  public static counselor = "Counselor"; //EPPOS-3556

  //For store view chart on Total By Trans. Type tab
  public static totalSaleTransCount = "No Of Sale Trans.";
  public static totalSaleAmount = "Total Sale Amount";
  public static totalReturnAmount = "Total Return Amount";
  public static returnTransCount = "No. Of Return Trans."

  public static SalesByTrend = "Sales Trend";
  public static SalesByDepartment = "Sales By Department";
  public static SalesByEmployee = "Sales By Employee";
  public static SalesByRegister = "Sales By Register";
  public static TopDepartment = "Top Departments";
  public static TopItems = "Top Items";
  public static TotalByPaymentType = "Total By Payment Type";
  public static TotalByTransType = "Total By Trans. Type";

  //CLP Settings
  public static ByTransactionAmount = "By Transaction Amount";
  public static ByLineItems = "By Line Items";
  public static ByQuantity = "By Quantity";

  public static RedeemManual = "Manual";
  public static ReddemAuto = "Automatic";

  public static PoinCalMethodPercentage = "Percentage";
  public static PoinCalMethodDollar = "Dollar";


  public static readonly vendorContractSeparator = "_";

  //Coupon status

  public static Available = "Available";
  public static Used = "Used";
  public static Expired = "Expired";
  public static Cancelled = "Cancelled";

  public static Sale = "SALE";
  public static Return = 'RETURN';
  public static Payout = "PAYOUT";
  public static RecOnPayment = "REC_ON_ACCOUNT"

  public static PayTypeCash = "CASH";
  public static PayTypeCheck = "CHEQUE";
  public static PayTypeCreditCard = "CREDIT_CARD";
  public static PayTypeCustom = "CUSTOM_PAYMENT";

  public static Scheduler = "Scheduler";

  //#region EPPOS-983 tax override report
  public static taxOverrideDropdownFields: any[] = [
    {
      fieldName: "Both",
      fieldValue: "isBoth"
    },
    {
      fieldName: "Tax Override",
      fieldValue: "isTaxOverride"
    },
    {
      fieldName: "Tax Override All",
      fieldValue: "isTaxOverrideAll"
    }
  ];

  public static isAllItem = "isAllItem";
  public static isRxItem = "isRxItem";
  public static isOtcItem = "isOtcItem";
  public static isBoth = "isBoth";
  public static isTaxOverride = "isTaxOverride";
  public static isTaxOverrideAll = "isTaxOverrideAll";
  //#endregion

  public static blancFilterMsg = "No input shall consider fetch all";
}

export class itemConstants {

  public static itemTypes: any[] = [
    {
      typeName: "Stock",
      typeValue: "Stock"
    },
    {
      typeName: "Non Stock",
      typeValue: "Non Stock"
    },
    {
      typeName: "Comment",
      typeValue: "Comment"
    }
  ];

  public static taxPolicy: any[] = [
    {
      policyName: "Item Tax Setting",
      policyValue: "I"
    },
    {
      policyName: "Department Tax Setting",
      policyValue: "D"
    },
    {
      policyName: "Dept Setting if dept is Taxable",
      policyValue: "O"
    }
  ];

  public static discountType: any[] = [
    {
      discountName: "Item",
      discountValue: "I"
    },
    {
      discountName: "Department",
      discountValue: "D"
    }
  ];

  public static CLPointPolicy: any[] = [
    {
      clPointName: "Default CL Point Setting",
      clPointValue: ""
    },
    {
      clPointName: "Item CL Point Setting",
      clPointValue: "D"
    },
    {
      clPointName: "Department CL Point Setting",
      clPointValue: "D"
    },
    {
      clPointName: "Sub-Dept CL Point Setting",
      clPointValue: "S"
    }
  ];

  public static LabelTempArray: any[] = [
    {
      labelName: "WL-875 1.625\"x1\" Rounded Label 30 stickers per sheet",
      labelValue: "WL-875"
    },
    {
      labelName: "WL-1000 1.5\"x1\" Rounded 50 stickers per sheet",
      labelValue: "WL-1000"
    },
    {
      labelName: "WL-25 1.75\"x0.5\" Rounded 80  stickers per sheet",
      labelValue: "WL-25"
    },
    {
      labelName: "WL-1050 1.831\"x0.5\" Rounded 64 stickers per sheet",
      labelValue: "WL-1050"
    },
    {
      labelName: "WL-800 2.5\"x1.563\" Rounded 18 stickers per sheet",
      labelValue: "WL-800"
    },
    {
      labelName: "WL-75 4\"x1\" Rounded 20 stickers per sheet",
      labelValue: "WL-75"
    },
    {
      labelName: "WL-200 3.43\"x0.67\" Rounded 30 stickers per sheet",
      labelValue: "WL-200"
    },
    {
      labelName: "WL-1075 1.42\"x1\" Square Corner 66 stickers per sheet",
      labelValue: "WL-1075"
    },
    {
      labelName: "WL-1100 1.5\"x0.5\" Square Corner 100 stickers per sheet",
      labelValue: "WL-1100"
    },
    {
      labelName: "WL-975 1.5\"x1\" Square Corner 50 stickers per sheet",
      labelValue: "WL-975"
    },
    {
      labelName: "WL-900 2.83\"x1\" Square Corner 33 stickers per sheet",
      labelValue: "WL-900"
    },
    {
      labelName: "WL-750 2.83\"x2.2\" Square Corner 15 stickers per sheet",
      labelValue: "WL-750"
    },
    {
      labelName: "WL-1025 1\" Round Label 63",
      labelValue: "WL-1025"
    },
    {
      labelName: "WL-325 1.67\" Round Label 24 stickers per sheet",
      labelValue: "WL-325"
    },
    {
      labelName: "WL-5375 2\" Round Label 20 stickers per sheet",
      labelValue: "WL-5375"
    },

  ];

  public static LabelItemFields: any[] = [
    {
      fieldName: "Item Id",
      fieldValue: "itemId"
    },
    {
      fieldName: "Item Id Barcode",
      fieldValue: "itemIdBarcode"
    },
    {
      fieldName: "Item Description",
      fieldValue: "description"
    },
    {
      fieldName: "Item Selling Price",
      fieldValue: "sellingPrice"
    },
    {
      fieldName: "Vendor Item Id",
      fieldValue: "vendorItemId"
    },
    {
      fieldName: "Vendor Item Id Barcode",
      fieldValue: "vendorItemIdBarcode"
    },
    {
      fieldName: "Vendor Name",
      fieldValue: "vendorName"
    }

  ];
}

export enum ResponseStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  ERROR = 'ERROR'
}

