import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, mergeMap, retryWhen, throwError, timer } from 'rxjs';
import { ErrorMessages } from '../constant/constant';

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {
  constructor(private httpClient: HttpClient) {}

  private requestWithRetry<T>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
    url: string,
    body?: any,
    headers?: HttpHeaders,
    params?: HttpParams
  ) {
    const options = { headers, params };

    let request$;
    switch (method) {
      case 'GET':
        request$ = this.httpClient.get<T>(url, options);
        break;
      case 'POST':
        request$ = this.httpClient.post<T>(url, body, options);
        break;
      case 'PUT':
        request$ = this.httpClient.put<T>(url, body, options);
        break;
      case 'DELETE':
        request$ = this.httpClient.delete<T>(url, options);
        break;
      case 'PATCH':
        request$ = this.httpClient.patch<T>(url, body, options);
        break;
      default:
        return throwError(() => new Error('Invalid HTTP method'));
    }

    return request$.pipe(
      retryWhen(errors =>
        errors.pipe(
          mergeMap((error, attempt) =>
            attempt < 3 && [500, 502, 503, 504].includes(error.status) ? timer(1000) : throwError(() => error)
          )
        )
      ),
      catchError(error => {
        console.error('HTTP error occurred:', error);
        return throwError(() => ({
          status: error.status,
          message:
            error.status === 0 ? ErrorMessages.NOINTERNET :
            error.error?.message || ErrorMessages.SOMETHINGWENTWRONG,
        }));
      })
    );
  }

  get<T>(url: string, headers?: HttpHeaders, params?: HttpParams) {
    return this.requestWithRetry<T>('GET', url, null, headers, params);
  }

  post<T>(url: string, body: any, headers?: HttpHeaders) {
    return this.requestWithRetry<T>('POST', url, body, headers);
  }

  put<T>(url: string, body: any, headers?: HttpHeaders) {
    return this.requestWithRetry<T>('PUT', url, body, headers);
  }

  delete<T>(url: string, headers?: HttpHeaders, params?: HttpParams) {
    return this.requestWithRetry<T>('DELETE', url, null, headers, params);
  }

  patch<T>(url: string, body: any, headers?: HttpHeaders) {
    return this.requestWithRetry<T>('PATCH', url, body, headers);
  }
}
