<div style="background-color: aliceblue;">

    <div class="dialog-headline">
        <mat-label style="float:left;">Pdf Viewer</mat-label>
        <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
    </div>

    <mat-dialog-content class="mat-typography" style="padding-top: 20px;">

        <div id="pdfContainer"></div>

        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
           

        </div>

    </mat-dialog-content>
    <!-- for adding line -->
    <hr>
    <mat-dialog-actions align="end">

        <button mat-stroked-button mat-dialog-close class="secondary" matTooltip="Close this dialog">
            <mat-icon>close</mat-icon>Close
        </button>

    </mat-dialog-actions>
</div>