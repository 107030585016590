import { Component, Input, OnInit } from '@angular/core';
import { MatTableExporterDirective } from 'mat-table-exporter';

@Component({
  selector: 'app-excel-export-button',
  templateUrl: './excel-export-button.component.html',
  styleUrls: ['./excel-export-button.component.css']
})
export class ExcelExportButtonComponent {

  @Input()
  exporter!: MatTableExporterDirective;
  @Input() fileName: string = 'Item_Data';
  @Input() sheetName: string = 'Item_Data';
  @Input() author: string = 'EPrimePOS';

  exportToExcel() {
    if (this.exporter) {
      this.exporter.exportTable('xlsx', {
        fileName: this.fileName,
        sheet: this.sheetName,
        Props: { Author: this.author }
      });
    } else {
      console.error('Exporter is not defined.');
    }
  }

}
