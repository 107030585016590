import { Customer } from './../../models/Customer';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { ClpSettings } from 'src/app/models/ClpSettings';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private httpClient: HttpClient) { }

  getCustomersByNPI(cust: Customer) {
    return this.httpClient.post<any>(constant.getCustomersByNPI, cust, constant.httpClientHeader).pipe(
      map((response: any) => {
        if (response.result == "SUCCESS") {
          const customerArray: Customer[] = JSON.parse(response.data);
          return { data: customerArray, error: "" };
        } else {
          return { error: "Error Fetching Customers" };
        }
      })
    )
  }

  searchCustomersByNameAndCell(cust: any) {
    return this.httpClient.post<any>(constant.getCustomersByNPI, cust, constant.httpClientHeader).pipe(
      map((response: any) => {
        if (response.result == "SUCCESS") {
          const customerArray: Customer[] = JSON.parse(response.data);
          return { data: customerArray, error: "" };
        } else {
          return { error: "Err or Fetching Customers" };
        }
      })
    )

  }
  deleteCustomer(cust: any) {
    return this.httpClient.post<any>(constant.deleteCustomer, cust, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )

  }

  createCustomer(cust: any) {
    return this.httpClient.post<any>(constant.createCustomer, cust, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )

  }

  UpdateCustomer(cust: any) {
    return this.httpClient.post<any>(constant.updateCustomer, cust, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )

  }

  CreateCLP(clp: any) {
    return this.httpClient.post<any>(constant.createCLP, clp, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )

  }

  UpdateClpSettings(clp: any) {
    return this.httpClient.post<any>(constant.updateClpSettings, clp, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getClpSettingsByNPIProgram(clpSettings:any) {
    return this.httpClient.post<any>(constant.getClpSettings, clpSettings, constant.httpClientHeader).pipe(
      map((response: any) => {
        if (response.result == "SUCCESS") {
          const clp: ClpSettings = JSON.parse(response.data);
          return { data: clp, error: "" };
        } else {
          return { error: "Error Fetching CLP Settings" };
        }
      })
    )
  }

  getClpSettings(userNPI: string) {
    return this.httpClient.post<any>(constant.getAllClpSettingsByNpi, { NPINo: userNPI }, constant.httpClientHeader).pipe(
      map((response: any) => {
        if (response.result == "SUCCESS") {
          const clpArray: ClpSettings[] = JSON.parse(response.data);
          return { data: clpArray, error: "" };
        } else {
          return { error: "Error Fetching Clp settings" };
        }
      })
    )
  }

  deleteClpSettings(clp: any) {
    return this.httpClient.post<any>(constant.deleteClpSettings, clp, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )

  }
}
