import { WebRights, WebAdministrativeFunction, WebAdminCustomer } from './../models/manageRights';
import { User } from 'src/app/models/User';
import { data } from 'jquery';
import { I } from '@angular/cdk/keycodes';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/User/user.service';
import { constant } from '../constant/constant';
import { TokenStorageService } from '../services/utility/token-storage-service';
import { ChangePasswordComponent } from '../changePassword/change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { UtilityService } from '../services/utility/utility.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [
  ]
})


export class LoginComponent implements OnInit {

  user: any = {};
  loggedIn = false;
  token: string | undefined;
  productAccess: any = "";
  ProductAccess: any = [];
  UserAccessMaster: any = {};
  dbVerifiedUser: any = {};

  constructor(private router: Router, private userService: UserService, private tokenService: TokenStorageService, private dialogService: MatDialog, private utilityService: UtilityService) {
    this.token = undefined;
  }


  ngOnInit(): void {

  }


  hide: boolean = true;

  myFunction() {
    this.hide = !this.hide;
  }

  loginUser() {

    let pseudoNameSpearator = "@";
    this.user.appId = [];
    this.user.appId.push("MPOSWeb");

    if (!this.token) {
      this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Please check capthca to proceed further");
      return;
    }

    let userLoginInfo = {
      user: this.user,
      stationId: ""
    };
    this.userService.loginUser(userLoginInfo).subscribe({
      next: (response: any) => {
        try {
          var receivedUserData = JSON.parse(response.data);

          if (response.result === "SUCCESS") {
            receivedUserData.appId = constant.APP_ID;
            this.authUserStore(receivedUserData);
          } else {
            var errorData = receivedUserData?.Message || "Unknown error occurred";
            this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, errorData);
          }
        } catch (err) {
          console.error('Error parsing response:', err);
          this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Invalid response format");
        }
      },
      error: (error: any) => {
        console.error('Login request failed:', error);
        let errorMessage = error?.error?.message || error?.message || "An unexpected error occurred";
        this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, errorMessage);
      }
    });
  }

  authUserStore(data: any) {
    this.tokenService.saveToken(data.token);
    this.tokenService.saveRefreshToken(data.user.refreshToken);
    this.tokenService.saveUser(data.user);

    if (data.changePasswordAtLogin) {
      const changePasswordRef = this.dialogService.open(ChangePasswordComponent, {
        width: '40%',
        disableClose: false,
        data: { headerTitle: "Change Password at login", userData: data }
      });
      changePasswordRef.afterClosed().subscribe(result => {

        location.reload();

      });

    } else {
      this.userService.authenticateUserStore(data).subscribe((storeResult: any) => {

        var receivedStoreData = JSON.parse(storeResult.data);

        if (storeResult.result == "SUCCESS") {

          data.storeDetails = receivedStoreData.storeDetails;
          sessionStorage.setItem(constant.KEY_STORE_DETAILS, JSON.stringify(receivedStoreData.storeDetails));
          sessionStorage.setItem(constant.KEY_STATION_DETAILS, JSON.stringify(receivedStoreData.StationDetail));

          this.authUserRights(data);

        } else if (storeResult.result == "FAILURE") {

          var errorData = receivedStoreData.Message;
          this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, errorData);
        }
        else {
          this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while fetching the store data");
        }


      });
    }

  }


  authUserRights(data: any) {
    this.userService.authorizeUserRights(data).subscribe((rightsResult: any) => {

      var receivedRightData = JSON.parse(rightsResult.data);

      if (rightsResult.result == "SUCCESS") {

        if(this.validateWebRights(receivedRightData.userRights.webRights.webAdministrativeFunction))
        {

          data.rights = receivedRightData.userRights;
          this.userService.saveUserSessionData(data)

          if (data.storeDetails.StationDetail != null && data.storeDetails.StationDetail != undefined) {

            this.router.navigateByUrl('/home');

          } else {

            this.router.navigateByUrl('/store');

          }
        } else {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "web rights are not given for the user!");

        }
      } else if (rightsResult.result == "FAILURE") {

        var errorData = receivedRightData.Message;
        this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, errorData);
      }
      else {
        this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while fetching the rights data");
      }


    });
  }

  validateWebRights(rights: any): boolean{
    if (!rights) {
      return false;
    }

    return Object.values(rights).some((value) => value === true);
  }

  resetPassword() {

  }
}
