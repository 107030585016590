<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->

<!-- <button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5"> <img src="assets/images/users/1.jpg" alt="user"
        class="profile-pic"> </button>
<mat-menu #profile="matMenu" class="mymegamenu">

    <button mat-menu-item class="text-info">
        <mat-icon color="primary">account_box</mat-icon> <b>{{userAccessData.userName}}</b>

    </button>
    
    <button mat-menu-item (click)="editLoggedinUserProfile()">
        <mat-icon color="primary">mode_edit</mat-icon> Edit User
    </button>
    <button mat-menu-item (click)="changePassword()">
        <mat-icon color="primary">settings</mat-icon> Change Password
    </button>
    <button mat-menu-item (click)="logoutUser()">
        <mat-icon color="primary">exit_to_app</mat-icon> Sign Out
    </button>
</mat-menu> -->

<button [matMenuTriggerFor]="notifications" mat-icon-button class="m-r-5"> <mat-icon color="primary">notifications</mat-icon> </button>
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5"> <mat-icon color="primary">account_circle</mat-icon> </button>
<mat-menu #profile="matMenu" class="mymegamenu">

    <button mat-menu-item class="text-info">    
        <mat-icon color="primary">account_box</mat-icon> <b>{{userAccessData.userName}}</b>

    </button>
    
    <button mat-menu-item (click)="editLoggedinUserProfile()">
        <mat-icon color="primary">mode_edit</mat-icon> Edit User
    </button>
    <button mat-menu-item (click)="changePassword()">
        <mat-icon color="primary">settings</mat-icon> Change Password
    </button>
    
    <button mat-menu-item (click)="logoutUser()">
        <mat-icon>exit_to_app</mat-icon> Logout </button>
</mat-menu>

<mat-menu #notifications="matMenu" class="mymegamenu">
    <!-- <li style="padding: 5px;">
        Message 1
    </li>
    <li style="padding: 5px;">
        Message 2
    </li> -->
    
</mat-menu>