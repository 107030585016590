import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { ClpSettings } from 'src/app/models/ClpSettings';
import { BaseHttpService } from 'src/app/utility/BaseHttpService';
import { Customer } from './../../models/Customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private baseHttpService: BaseHttpService) {}

  getCustomersByNPI(cust: Customer) {
    return this.baseHttpService.post<any>(constant.getCustomersByNPI, cust, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        if (response.result == "SUCCESS") {
          const customerArray: Customer[] = JSON.parse(response.data);
          return { data: customerArray, error: "" };
        } else {
          return { error: "Error Fetching Customers" };
        }
      })
    )
  }

  searchCustomersByNameAndCell(cust: any) {
    return this.baseHttpService.post<any>(constant.getCustomersByNPI, cust, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        if (response.result == "SUCCESS") {
          const customerArray: Customer[] = JSON.parse(response.data);
          return { data: customerArray, error: "" };
        } else {
          return { error: "Error Fetching Customers" };
        }
      })
    )
  }

  deleteCustomer(cust: any) {
    return this.baseHttpService.post<any>(constant.deleteCustomer, cust, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  createCustomer(cust: any) {
    return this.baseHttpService.post<any>(constant.createCustomer, cust, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  UpdateCustomer(cust: any) {
    return this.baseHttpService.post<any>(constant.updateCustomer, cust, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  CreateCLP(clp: any) {
    return this.baseHttpService.post<any>(constant.createCLP, clp, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  UpdateClpSettings(clp: any) {
    return this.baseHttpService.post<any>(constant.updateClpSettings, clp, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getClpSettingsByNPIProgram(clpSettings:any) {
    return this.baseHttpService.post<any>(constant.getClpSettings, clpSettings, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        if (response.result == "SUCCESS") {
          const clp: ClpSettings = JSON.parse(response.data);
          return { data: clp, error: "" };
        } else {
          return { error: "Error Fetching CLP Settings" };
        }
      })
    )
  }

  getClpSettings(userNPI: string) {
    return this.baseHttpService.post<any>(constant.getAllClpSettingsByNpi, { NPINo: userNPI }, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        if (response.result == "SUCCESS") {
          const clpArray: ClpSettings[] = JSON.parse(response.data);
          return { data: clpArray, error: "" };
        } else {
          return { error: "Error Fetching Clp settings" };
        }
      })
    )
  }

  deleteClpSettings(clp: any) {
    return this.baseHttpService.post<any>(constant.deleteClpSettings, clp, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }
}
