import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { PreviewDialogComponent } from '../preview-dialog/preview-dialog.component';
import { ReceiptDesignerService } from 'src/app/services/ReceiptDesign/receipt-design.service';
import { constant } from 'src/app/constant/constant';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { UserService } from 'src/app/services/User/user.service';
import { userAccess } from 'src/app/models/UserAccess';

@Component({
  selector: 'app-reciept-designer',
  templateUrl: './reciept-designer.component.html',
  styleUrls: ['./reciept-designer.component.css']
})
export class RecieptDesignerComponent implements OnInit {
  logoAlignment: string = '';
  barcodeAlignment: string = '';
  qrAlignment: string = '';
  userAccessData!: userAccess;//this variable will hold user data

  constructor(
    private dialogService: MatDialog,
    private cdr: ChangeDetectorRef,
    private utilityService: UtilityService,
    private receiptDesignService: ReceiptDesignerService,
    private userService: UserService
  ) {
    this.userAccessData = this.userService.getUsersAccessData();
  }

  ngOnInit(): void {
  }

  @ViewChild('elementTypeSelect') elementTypeSelect: MatSelect | undefined;
  activeField: string | null = null;
  fieldAlignments: { [key: string]: 'left' | 'center' | 'right' } = {}; // Store alignment for each field
  droppedLogo: string | null = null; // Store the dropped logo
  droppedStoreName: string = "";
  droppedStoreAddress1: string | null = null;
  droppedStoreAddress2: string | null = null;
  droppedStoreCity: string | null = null;
  droppedStoreZip: string | null = null;
  droppedStorePhoneNo: string | null = null;
  droppedStoreFaxNo: string | null = null;
  droppedStoreTollFreeNo: string | null = null;
  droppedStoreWebAddress: string | null = null;
  droppedFooterText: string | null = null;
  isDragging: boolean = false; // To track if an element is being dragged
  selectedElementType: string = '';
  // Define headerText property
  headerText: any;
  bodyText: any;
  footerText: any;

  fonts: string[] = [
    'DS DIGI',
    'Houschka Pro',
    'Open Sans',
    'Orange Juice',
    'Shine in Valentine',
    'Tox Typewriter',
    'Type Machine'
  ]; // Available fonts

  availableFonts: string[] = [
    "DS_DIGI",
    "houschka_pro_medium",
    "opensans_regular",
    "orange_juice",
    "Shine_in_Valentine",
    "Tox_Typewriter",
    "Type_Machine"
  ]

  fontSizes: number[] = [8, 10, 12, 14, 16, 18, 20, 22, 24]; // Available font sizes
  selectedFont: string = 'Open Sans';
  selectedFontSize: number = 14;
  isBold: boolean = false;
  isItalic: boolean = false;
  isUnderline: boolean = false;
  selectedAlignment: string = 'left';
  selectedCasing: string = 'none';
  isBullet: boolean = false;  // Bullet style
  isNumbered: boolean = false; // Numbering style
  alignment: string = 'left'; // Default alignment
  casing: string = 'normal'; // Default casing style
  // Add a property to store the logo
  previewImage: string | null = null; // To store the image preview URL
  newElementText: string = '';  // For text input
  elements: Array<any> = []; // Store added elements
  isQrEnabledHeader: boolean = false;
  isBarcodeEnabledHeader: boolean = false;
  isQrEnabledFooter: boolean = false;
  isBarcodeEnabledFooter: boolean = false;

  headerFields = [
    'Store Name',
    'Store Address 1',
    'Store Address 2',
    'Store City',
    'Store Zip',
    'Store Phone Number',
    'Store Fax Number',
    'Store Toll Free Number',
    'Store Web Address'
  ];
  footerFields = [
    'Footer Text'
  ];

  // To toggle the sections visibility
  showHeader = true;
  showBody = true;
  showFooter = true;

  elementHeaderText: string = '';
  elementBodyText: string = '';
  elementFooterText: string = '';

  setActiveField(field: string): void {
    this.activeField = field;
    this.applyFontPropertiesToActiveField(); // Apply styles as soon as the field is focused
  }

  applyFontPropertiesToActiveField(): void {
    if (this.activeField) {
      const styles = {
        'font-size': `${this.selectedFontSize}px`,
        'font-family': this.selectedFont,
        'font-weight': this.isBold ? 'bold' : 'normal',
        'font-style': this.isItalic ? 'italic' : 'normal',
        'text-decoration': this.isUnderline ? 'underline' : 'none',
        'text-align': this.selectedAlignment,
        'text-transform': this.getTextTransformStyle(),
      };

      this.updateFieldStyles(this.activeField, styles);
    }
  }

  // New function to update field styles dynamically
  updateFieldStyles(field: string, styles: any): void {
    const element = this.getFieldElement(field);
    if (element) {
      Object.assign(element.style, styles);
      this.cdr.detectChanges(); // Ensure Angular updates the view
    }
  }

  // Helper function to get the DOM element corresponding to the active field
  getFieldElement(field: string): HTMLElement | null {
    switch (field) {
      case 'storeName':
        return document.querySelector('.dropped-store-name') as HTMLElement;
      case 'storeAddress1':
        return document.querySelector('.dropped-store-address1') as HTMLElement;
      case 'storeAddress2':
        return document.querySelector('.dropped-store-address2') as HTMLElement;
      case 'storeCity':
        return document.querySelector('.dropped-store-city') as HTMLElement;
      case 'storeZip':
        return document.querySelector('.dropped-store-zip') as HTMLElement;
      case 'storePhoneNo':
        return document.querySelector('.dropped-store-phone') as HTMLElement;
      case 'storeFaxNo':
        return document.querySelector('.dropped-store-fax') as HTMLElement;
      case 'storeTollFreeNo':
        return document.querySelector('.dropped-store-tollfree') as HTMLElement;
      case 'storeWebAddress':
        return document.querySelector('.dropped-store-web') as HTMLElement;
      case 'footerText':
        return document.querySelector('.dropped-footer-text') as HTMLElement;
      default:
        return null;
    }
  }

  // Toggle the visibility of each section
  toggleSection(section: string): void {
    if (section === 'header') {
      this.showHeader = !this.showHeader;
    } else if (section === 'body') {
      this.showBody = !this.showBody;
    } else if (section === 'footer') {
      this.showFooter = !this.showFooter;
    }
  }

  // Add dropped field to builder
  addDroppedField(field: string): void {
    switch (field) {
      case 'Store Name':
        this.droppedStoreName = 'Enter Store Name';
        break;
      case 'Store Address 1':
        this.droppedStoreAddress1 = 'Enter Store Address 1';
        break;
      case 'Store Address 2':
        this.droppedStoreAddress2 = 'Enter Store Address 2';
        break;
      case 'Store City':
        this.droppedStoreCity = 'Enter Store City';
        break;
      case 'Store Zip':
        this.droppedStoreZip = 'Enter Store Zip';
        break;
      case 'Store Phone Number':
        this.droppedStorePhoneNo = 'Enter Store Phone Number';
        break;
      case 'Store Fax Number':
        this.droppedStoreFaxNo = 'Enter Store Fax Number';
        break;
      case 'Store Toll Free Number':
        this.droppedStoreTollFreeNo = 'Enter Store Toll Free Number';
        break;
      case 'Store Web Address':
        this.droppedStoreWebAddress = 'Enter Store Web Address';
        break;
      case 'Footer Text':
        this.droppedFooterText = 'Enter Footer Text';
        break;
    }
  }

  // Helper function to get the correct text-transform style based on selected casing
  getTextTransformStyle(): string {
    switch (this.selectedCasing) {
      case 'uppercase':
        return 'uppercase';
      case 'lowercase':
        return 'lowercase';
      case 'titlecase':
        return 'capitalize';
      default:
        return 'none';
    }
  }

  // Method to add element
  addElement() {
    if (this.selectedElementType === 'text' && this.newElementText.trim()) {
      this.elements.push({
        type: 'text',
        text: this.newElementText,
        fontSize: '14px',
        alignment: 'left',
      });
      this.newElementText = ''; // Clear the input after adding
    }
    if (this.selectedElementType === 'image' && this.newElementText) {
      this.elements.push({
        type: 'image',
        image: this.newElementText, // Store the image URL/path
      });
      this.newElementText = ''; // Clear the input after adding
    }
  }

  // Method to remove element
  removeElement(index: number) {
    this.elements.splice(index, 1); // Remove the element at the specified index
  }

  // Reset the logo
  resetLogo(): void {
    this.previewImage = ''; // Clear the preview image
    this.droppedLogo = ''; // Clear the stored logo
    const fileInput = document.getElementById('logoUpload') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // Reset the file input so it can accept the same file again
    }
  }

  // On Drag Start
  onDragStart(event: DragEvent, field: string): void {
    event.dataTransfer?.setData('field', field);
    this.isDragging = true; // Indicate dragging state
  }

  // Start dragging for logo
  onLogoDragStart(event: DragEvent): void {
    event.dataTransfer?.setData('text/plain', 'logo'); // Mark it as a logo
    this.isDragging = true; // Indicate something is being dragged
  }

  // Drag End
  onDragEnd(): void {
    this.isDragging = false; // Reset dragging state

    // Ensure all highlights are cleared when dragging ends
    const highlightedElements = document.querySelectorAll('.drop-area');
    highlightedElements.forEach((element) =>
      element.classList.remove('drop-area')
    );
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    const target = event.target as HTMLElement;
    if (target.classList.contains('builder-header')) {
      target.classList.add('drop-area'); // Apply the class
    }
  }

  onDragLeave(event: DragEvent): void {
    const target = event.target as HTMLElement;
    if (target.classList.contains('builder-header')) {
      target.classList.remove('drop-area'); // Remove the class
    }
  }

  // On Drop
  onDrop(event: DragEvent): void {
    event.preventDefault();
    const target = event.target as HTMLElement;
    target.classList.remove('drop-area'); // Ensure the class is removed after dropping
    const field = event.dataTransfer?.getData('field');
    this.isDragging = false; // Reset dragging state
    // Handle dropping logo
    if (event.dataTransfer?.getData('text/plain') === 'logo') {
      this.droppedLogo = this.previewImage; // Use the preview image as the dropped logo
    }
    if (field) {
      this.addDroppedField(field);
    }
  }

  onElementTypeChange(): void {
    // Ensure the dropdown closes after selection
    if (this.elementTypeSelect) {
      this.elementTypeSelect.close();
    }
  }

  onLogoUpload(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d')!;
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const pixels = imageData.data;

          // Convert the image to grayscale (black & white)
          for (let i = 0; i < pixels.length; i += 4) {
            const avg = (pixels[i] + pixels[i + 1] + pixels[i + 2]) / 3; // Get grayscale value
            pixels[i] = pixels[i + 1] = pixels[i + 2] = avg; // Apply grayscale value
          }
          ctx.putImageData(imageData, 0, 0);

          // Resize the image (optional)
          const resizedCanvas = document.createElement('canvas');
          resizedCanvas.width = Math.min(384, canvas.width);
          resizedCanvas.height = resizedCanvas.width * (canvas.height / canvas.width);
          const resizedCtx = resizedCanvas.getContext('2d')!;
          resizedCtx.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);

          // Set the preview image
          this.previewImage = resizedCanvas.toDataURL('image/png');
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  // Method to trigger the file input click event
  triggerFileInput(): void {
    const fileInput = document.getElementById('logoUpload') as HTMLInputElement;
    if (fileInput) {
      fileInput.click(); // Trigger file input dialog
    }
  }

  changeFont(font: string) {
    this.applyFontPropertiesToActiveField();
  }

  changeFontSize(size: string) {
    this.applyFontPropertiesToActiveField();
  }

  toggleBold() {
    this.isBold = !this.isBold;
    this.applyFontPropertiesToActiveField();
  }

  toggleItalic() {
    this.isItalic = !this.isItalic;
    this.applyFontPropertiesToActiveField();
  }

  toggleUnderline() {
    this.isUnderline = !this.isUnderline;
    this.applyFontPropertiesToActiveField();
  }

  toggleBullet() {
    this.isBullet = !this.isBullet;
    this.isNumbered = false; // Disable numbering if bulleting is enabled
  }

  toggleNumbering() {
    this.isNumbered = !this.isNumbered;
    this.isBullet = false; // Disable bulleting if numbering is enabled
  }

  toggleAlignment(alignment: 'left' | 'center' | 'right') {
    this.selectedAlignment = alignment;

    // Apply alignment to the logo if it is dropped
    if (this.droppedLogo) {
      this.logoAlignment = alignment;
    }

    if (this.isQrEnabledHeader || this.isQrEnabledFooter) {
      this.qrAlignment = alignment;
    } if (this.isBarcodeEnabledHeader || this.isBarcodeEnabledFooter) {
      this.barcodeAlignment = alignment;
    }
  }

  toggleCasing(type: string) {
    this.selectedCasing = type;
  }

  openDialog(): void {
    this.dialogService.open(RecieptDesignerComponent, {
      width: '50%',
      disableClose: true,
      data: { headerTitle: 'Receipt Designer' },
    });
  }

  // Method to generate the preview
  showPreview() {
    this.createPreviewContent();
    const dialogRef = this.dialogService.open(PreviewDialogComponent, {
      width: '400px',
      data: { content: this.previewContent },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  // Store the generated content in a variable
  previewContent: string = '';

  // Helper method to create the content for preview
  createPreviewContent() {
    let content = '<div style="width: 383px; border:none;">';

    // Function to apply styles based on dynamic properties
    const getStyle = (
      fontSize: number,
      fontFamily: string,
      alignment: string,
      isBold: boolean,
      isItalic: boolean,
      isUnderline: boolean,
      casing: string,
      isImage: boolean = false // New parameter for images
    ) => {
      let alignmentStyle = `text-align: ${alignment};`;

      if (isImage) {
        alignmentStyle =
         `
          display: flex;
          justify-content: ${alignment};
        `;
      }

      return `
        ${alignmentStyle}
        font-size: ${fontSize}px;
        font-family: ${fontFamily};
        font-weight: ${isBold ? 'bold' : 'normal'};
        font-style: ${isItalic ? 'italic' : 'normal'};
        text-decoration: ${isUnderline ? 'underline' : 'none'};
        text-transform: ${casing === 'uppercase' ? 'uppercase' : casing === 'lowercase' ? 'lowercase' : 'none'};
        border: none;
      `;
    };

    if (this.isQrEnabledHeader) {
      content += `
        <div style="${getStyle(this.selectedFontSize, this.selectedFont, this.selectedAlignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing, true)}">
          <img src="assets/images/Dummy_QR.png" style="width: 50%;"/>
        </div>`;
    }

    if (this.isBarcodeEnabledHeader) {
      content += `
        <div style="${getStyle(this.selectedFontSize, this.selectedFont, this.selectedAlignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing, true)}">
          <img src="assets/images/DummyBarcode.png" style="width: 50%;" />
        </div>`;
    }

    if (this.droppedLogo) {
      content += `
        <div style="${getStyle(this.selectedFontSize, this.selectedFont, this.selectedAlignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing, true)}">
          <img src="${this.droppedLogo}" style="width: 180px; height: auto; object-fit: contain;" />
        </div>`;
    }

    // Add other store details with dynamic styling
    if (this.droppedStoreName) {
      content += `<div style="${getStyle(this.selectedFontSize, this.selectedFont, this.selectedAlignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing)}">
        ${this.droppedStoreName}
      </div>`;
    }

    if (this.droppedStoreAddress1) {
      content += `<div style="${getStyle(this.selectedFontSize, this.selectedFont, this.selectedAlignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing)}">
        ${this.droppedStoreAddress1}
      </div>`;
    }
    if (this.droppedStoreAddress2) {
      content += `<div style="${getStyle(this.selectedFontSize, this.selectedFont, this.selectedAlignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing)}">
        ${this.droppedStoreAddress2}
      </div>`;
    }
    if (this.droppedStoreCity) {
      content += `<div style="${getStyle(this.selectedFontSize, this.selectedFont, this.selectedAlignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing)}">
        ${this.droppedStoreCity}
      </div>`;
    }
    if (this.droppedStoreZip) {
      content += `<div style="${getStyle(this.selectedFontSize, this.selectedFont, this.selectedAlignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing)}">
        ${this.droppedStoreZip}
      </div>`;
    }
    if (this.droppedStorePhoneNo) {
      content += `<div style="${getStyle(this.selectedFontSize, this.selectedFont, this.selectedAlignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing)}">
        ${this.droppedStorePhoneNo}
      </div>`;
    }
    if (this.droppedStoreFaxNo) {
      content += `<div style="${getStyle(this.selectedFontSize, this.selectedFont, this.selectedAlignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing)}">
        ${this.droppedStoreFaxNo}
      </div>`;
    }
    if (this.droppedStoreTollFreeNo) {
      content += `<div style="${getStyle(this.selectedFontSize, this.selectedFont, this.selectedAlignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing)}">
        ${this.droppedStoreTollFreeNo}
      </div>`;
    }
    if (this.droppedStoreWebAddress) {
      content += `<div style="${getStyle(this.selectedFontSize, this.selectedFont, this.selectedAlignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing)}">
        ${this.droppedStoreWebAddress}
      </div>`;
    }
    if (this.isQrEnabledFooter) {
      content += `<div style="${getStyle(this.selectedFontSize, this.selectedFont, this.selectedAlignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing, true)}">
        <img src="assets/images/Dummy_QR.png" style="width: 50%;" />
      </div>`;
    }
    if (this.isBarcodeEnabledFooter) {
      content += `<div style="${getStyle(this.selectedFontSize, this.selectedFont, this.selectedAlignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing, true)}">
        <img src="assets/images/DummyBarcode.png" style="width: 50%;" />
      </div>`;
    }
    if (this.droppedFooterText) {
      content += `<div style="${getStyle(this.selectedFontSize, this.selectedFont, this.selectedAlignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing)}">
        ${this.droppedFooterText}
      </div>`;
    }

    // Add custom elements (text, images, etc.) with dynamic styling
    this.elements.forEach(element => {
      if (element.type === 'text') {
        content += `<div style="${getStyle(this.selectedFontSize, this.selectedFont, element.alignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing)}">
          ${element.text}
        </div>`;
      }
      if (element.type === 'image') {
        content += `<div style="${getStyle(this.selectedFontSize, this.selectedFont, element.alignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing, true)}">
          <img src="${element.image}" style="max-width: 100%; height: auto;" />
        </div>`;
      }
    });

    // Add footer if exists with dynamic styling
    if (this.elementFooterText) {
      content += `<div style="${getStyle(this.selectedFontSize, this.selectedFont, this.selectedAlignment, this.isBold, this.isItalic, this.isUnderline, this.selectedCasing)}">
        ${this.elementFooterText}
      </div>`;
    }

    content += '</div>'; // Close the main div
    this.previewContent = content; // Set the generated preview content
  }

  getMappedFont(font: string): string {
    const fontMap: Record<string, string> = {
      'DS DIGI': 'DS_DIGI',
      'Houschka Pro': 'houschka_pro_medium',
      'Open Sans': 'opensans_regular',
      'Orange Juice': 'orange_juice',
      'Shine in Valentine': 'Shine_in_Valentine',
      'Tox Typewriter': 'Tox_Typewriter',
      'Type Machine': 'Type_Machine'
    };

    return fontMap[font] || font.replace(/\s+/g, '_'); // Fallback to replacing spaces with underscores
  }

  // Export design as JSON (including the preview content)
  async exportDesign(): Promise<void> {
    // Optionally compress the logo image before export
    const compressImage = (base64Image: string, maxWidth: number, maxHeight: number): Promise<string> => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = base64Image;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d')!;
          let { width, height } = img;
          if (width > maxWidth || height > maxHeight) {
            const aspectRatio = width / height;
            if (width > height) {
              width = maxWidth;
              height = maxWidth / aspectRatio;
            } else {
              height = maxHeight;
              width = maxHeight * aspectRatio;
            }
          }
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL('image/png', 1.0));
        };
        img.onerror = () => resolve(base64Image);
      });
    };

    const designData = {
      logo: this.droppedLogo?.replace(/^data:image\/png;base64,/, ''),
      storeName: this.droppedStoreName,
      storeAddress1: this.droppedStoreAddress1,
      storeAddress2: this.droppedStoreAddress2,
      storeCity: this.droppedStoreCity,
      storeZip: this.droppedStoreZip,
      storePhoneNo: this.droppedStorePhoneNo,
      storeFaxNo: this.droppedStoreFaxNo,
      storeTollFreeNo: this.droppedStoreTollFreeNo,
      storeWebAddress: this.droppedStoreWebAddress,
      footerText: this.droppedFooterText,
      selectedFont: this.getMappedFont(this.selectedFont),
      selectedFontSize: this.selectedFontSize,
      isBold: this.isBold,
      isItalic: this.isItalic,
      isUnderline: this.isUnderline,
      selectedAlignment: this.selectedAlignment,
      selectedCasing: this.selectedCasing,
      isQrEnabledHeader: this.isQrEnabledHeader,
      isBarcodeEnabledHeader: this.isBarcodeEnabledHeader,
      isQrEnabledFooter: this.isQrEnabledFooter,
      isBarcodeEnabledFooter: this.isBarcodeEnabledFooter,
    };

    // if (designData.droppedLogo) {
    //   designData.droppedLogo = await compressImage(designData.droppedLogo, 100, 100);
    // }

    const jsonData = JSON.stringify(designData, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'receipt-design.json';
    link.click();
    URL.revokeObjectURL(link.href);
    var finalDesignData = {
      NPINo: this.userAccessData.NPINo,
      DesignData: designData
    }
    this.insertDesignData(finalDesignData);
  }

  insertDesignData(finaldesignData: any): any {
    this.receiptDesignService.insertReceiptDesignData(finaldesignData).subscribe({
      next: (response) => {
        // Check if the response is successful
        if (response.result === 'SUCCESS') {
          this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_CREATE_TITLE, "Design saved successfully").subscribe(result => {
            this.dialogService.closeAll();
          });
        } else if (response.result === 'FAILURE') {
          // Show an alert if the state and city data could not be fetched
          this.utilityService.showAlert(constant.KEY_ERROR, 'Failed to save designed data', response.message);
        }
      }
    })
  }

}
