import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { BaseHttpService } from 'src/app/utility/BaseHttpService';

@Injectable({
  providedIn: 'root'
})
export class ItemDepartmentService {

  constructor(private baseHttpService: BaseHttpService) { }

  getItemDepartment(itemDept: any) {
    return this.baseHttpService.post<any>(constant.getItemDepartmentUrl, itemDept, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  createItemDepartment(itemDept: any) {
    return this.baseHttpService.post<any>(constant.createItemDepartmentUrl, itemDept, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  updateItemDepartment(itemDept: any) {
    return this.baseHttpService.post<any>(constant.updateItemDepartmentUrl, itemDept, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  deleteItemDepartment(itemDept: any) {
    return this.baseHttpService.post<any>(constant.deleteItemDepartmentUrl, itemDept, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  createDefaultItemDepartment(itemDept: any) {
    return this.baseHttpService.post<any>(constant.insertDefaultItemDepartmentUrl, itemDept, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getItemDeptWithPagination(itemDept: any) {
    return this.baseHttpService.post<any>(constant.getItemDepartmentPaginationUrl, itemDept, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

}
