import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-dialog',
  templateUrl: './preview-dialog.component.html',
  styleUrls: ['./preview-dialog.component.css'],
})
export class PreviewDialogComponent {
  previewContent: SafeHtml = ''; // Define as SafeHtml to store sanitized content

  constructor(
    public dialogRef: MatDialogRef<PreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { content: string },
    private sanitizer: DomSanitizer // Inject DomSanitizer
  ) {
    // Sanitize and assign the HTML content
    this.previewContent = this.sanitizer.bypassSecurityTrustHtml(data.content);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
