<div class="example-container" [class.example-is-mobile]="mobileQuery.matches" *ngIf="shouldRun">
  <mat-nav-list>
    <ng-container *ngFor="let item of menuItems.getMenuitem()">
      <!-- Parent Items without Children -->
      <ng-container *ngIf="!item.childMenu || item.childMenu.length === 0">
        <mat-list-item [routerLink]="item.state">
          <div fxLayout="row" fxLayoutAlign="start center"
            style="padding-top: 10px; color: #ADADAD; font-size: 13px; font-weight: normal; text-align: left; line-height: 20px;">
            <img *ngIf="item.imageUrl" [src]="item.imageUrl" [matTooltip]="item.name" alt="Image"
              style="width: 20px; height: 20px;">
            <mat-label style="padding-left: 8px; vertical-align: middle; padding-top: 4px;"
              *ngIf="!sidenavOpened">{{ item.name }}</mat-label>
          </div>
        </mat-list-item>
      </ng-container>

      <!-- Parent Items with Children -->
      <!-- <ng-container *ngIf="item.childMenu && item.childMenu.length > 0">
        <div style="display: flex; align-items: center; cursor: pointer; padding-left: 15px; padding-top: 10px;"
          (click)="item.expanded = !item.expanded" fxLayout="row" fxLayoutAlign="space-between center">
          <div style="display: flex; align-items: center;">
            <img [src]="item.imageUrl" alt="EDI Image" style="width: 20px; height: 20px; margin-right: 8px;" [matTooltip]="item.name">
            <mat-label *ngIf="!sidenavOpened"
              [class.parent-selected]="parentItem === item"
              style="padding-top: 10px; color: #ADADAD; font-size: 13px; font-weight: normal; text-align: left; line-height: 20px;">
              {{item.name}}
            </mat-label>
          </div>
        </div>
        <mat-list *ngIf="item.expanded">
          <mat-list-item *ngFor="let child of item.childMenu" [routerLink]="child.state" style="height: 30px;">
            <div fxLayout="row" fxLayoutAlign="start center"
              style="padding-top: 0px; color: #ADADAD; font-size: 13px;
              font-weight: normal; text-align: left; line-height: 20px;">
              <mat-label style="padding-left: 0px; vertical-align: middle; padding-top: 0px;"
                (mouseover)="child.isHovered = true" (mouseout)="child.isHovered = false"
                [class.hovered]="child.isHovered" (click)="onMenuItemClick(child, item)"
                [class.selected]="selectedItem === child" *ngIf="!sidenavOpened">{{ child.name }}
              </mat-label>
            </div>
          </mat-list-item>
        </mat-list>
      </ng-container> -->
      <ng-container *ngIf="item.childMenu && item.childMenu.length > 0">
        <div style="display: flex; align-items: center; cursor: pointer; padding-left: 15px; padding-top: 10px;"
             (click)="item.expanded = !item.expanded"
             fxLayout="row" fxLayoutAlign="space-between center"
             [class.selected-parent]="isParentSelected(item)">
          <div style="display: flex; align-items: center;">
            <img [src]="item.imageUrl" alt="EDI Image" style="width: 20px; height: 20px; margin-right: 8px;" [matTooltip]="item.name">
            <mat-label *ngIf="!sidenavOpened"
                [ngClass]="{'parent-selected': parentItem === item, 'parent-not-selected': parentItem !== item}"
                style="padding-top: 10px; font-size: 13px; font-weight: normal; text-align: left; line-height: 20px;">
              {{item.name}}
            </mat-label>
          </div>
        </div>
        <mat-list *ngIf="item.expanded">
          <!--#3832--><mat-list-item *ngFor="let child of item.childMenu"
            [routerLink]="child.state"
            [class.selected]="selectedItem === child"
            (click)="onMenuItemClick(child, item)"
            style="height: 30px;"><!--#3832-->
            <div fxLayout="row" fxLayoutAlign="start center"
              style="padding-top: 0px; color: #ADADAD; font-size: 13px; font-weight: normal; text-align: left; line-height: 20px;">
              <mat-label style="padding-left: 0px; vertical-align: middle; padding-top: 0px;"
                (mouseover)="child.isHovered = true"
                (mouseout)="child.isHovered = false"
                [class.hovered]="child.isHovered"
                (click)="onMenuItemClick(child, item)"
                [class.selected]="selectedItem === child"
                *ngIf="!sidenavOpened">{{ child.name }}
              </mat-label>
            </div>
          </mat-list-item>
        </mat-list>
      </ng-container>


    </ng-container>
  </mat-nav-list>
</div>
