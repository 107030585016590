import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ChangePasswordComponent } from '../changePassword/change-password/change-password.component';
import { constant } from '../constant/constant';
import { UserService } from '../services/User/user.service';
import { TokenStorageService } from '../services/utility/token-storage-service';
import { UtilityService } from '../services/utility/utility.service';

@Component({
  selector: 'app-login-without-captcha',
  templateUrl: './login-without-captcha.component.html',
  styleUrls: ['./login-without-captcha.component.css']
})

export class LoginWithoutCaptchaComponent implements OnInit {

  user: any = {};
  loggedIn = false;
  token: string | undefined;
  productAccess: any = "";
  ProductAccess: any = [];
  UserAccessMaster: any = {};
  dbVerifiedUser: any = {};

  constructor(private router: Router, private userService: UserService, private tokenService: TokenStorageService, private dialogService: MatDialog, private utilityService: UtilityService) {
    this.token = undefined;
  }


  ngOnInit(): void {

  }


  hide: boolean = true;

  myFunction() {
    this.hide = !this.hide;
  }

  loginUser() {

    let pseudoNameSpearator = "@";
    this.user.appId = [];
    this.user.appId.push("MPOSWeb");

    let userLoginInfo = {
      user: this.user,
      stationId: ""
    };
    this.userService.loginUser(userLoginInfo).subscribe((response: any) => {


      var receivedUserData = JSON.parse(response.data);

      if (response.result == "SUCCESS") {

        receivedUserData.appId = constant.APP_ID;
        this.authUserStore(receivedUserData);

      } else if (response.result == "FAILURE") {

        var errorData = receivedUserData.Message;
        this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, errorData);
      }
      else {
        this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while fetching the user data");
      }

    });
  }

  authUserStore(data: any) {
    this.tokenService.saveToken(data.token);
    this.tokenService.saveRefreshToken(data.user.refreshToken);
    this.tokenService.saveUser(data.user);

    if (data.changePasswordAtLogin) {
      const changePasswordRef = this.dialogService.open(ChangePasswordComponent, {
        width: '40%',
        disableClose: false,
        data: { headerTitle: "Change Password at login", userData: data }
      });
      changePasswordRef.afterClosed().subscribe(result => {

        location.reload();

      });

    } else {
      this.userService.authenticateUserStore(data).subscribe((storeResult: any) => {

        var receivedStoreData = JSON.parse(storeResult.data);

        if (storeResult.result == "SUCCESS") {

          data.storeDetails = receivedStoreData.storeDetails;
          sessionStorage.setItem(constant.KEY_STORE_DETAILS, JSON.stringify(receivedStoreData.storeDetails));
          sessionStorage.setItem(constant.KEY_STATION_DETAILS, JSON.stringify(receivedStoreData.StationDetail));

          this.authUserRights(data);

        } else if (storeResult.result == "FAILURE") {

          var errorData = receivedStoreData.Message;
          this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, errorData);
        }
        else {
          this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while fetching the store data");
        }


      });
    }

  }


  authUserRights(data: any) {
    this.userService.authorizeUserRights(data).subscribe((rightsResult: any) => {

      var receivedRightData = JSON.parse(rightsResult.data);

      if (rightsResult.result == "SUCCESS") {

        data.rights = receivedRightData.userRights;
        this.userService.saveUserSessionData(data)


        if (data.storeDetails.StationDetail != null && data.storeDetails.StationDetail != undefined) {

          this.router.navigateByUrl('/home');
        } else {
          this.router.navigateByUrl('/store');
        }
      } else if (rightsResult.result == "FAILURE") {

        var errorData = receivedRightData.Message;
        this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, errorData);
      }
      else {
        this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while fetching the rights data");
      }


    });
  }

  resetPassword() {

  }

}
