import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableExporterDirective } from 'mat-table-exporter';

@Component({
  selector: 'app-text-export-button',
  templateUrl: './text-export-button.component.html',
  styleUrls: ['./text-export-button.component.css']
})
export class TextExportButtonComponent {

  @Input() exporter: MatTableExporterDirective | undefined;
  @Input() fileName: string = 'Item_Data';
  @Input() sheetName: string = 'Item_Data';
  @Input() author: string = 'EPrimePOS';

  exportToText() {
    if (this.exporter) {
      this.exporter.exportTable('json', {
        fileName: this.fileName,
        sheet: this.sheetName,
        Props: { Author: this.author }
      });
    } else {
      console.error('Exporter is not defined.');
    }
  }
}
