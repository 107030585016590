import { data } from 'jquery';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, map, switchMap } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { User } from 'src/app/models/User';
import { TokenStorageService } from '../utility/token-storage-service';
import { Router } from '@angular/router';
import { UtilityService } from '../utility/utility.service';
import { Idle } from '@ng-idle/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})


export class UserService {
  userLoginStatus: any;

  constructor(private httpClient: HttpClient,private router: Router,private dialogService: MatDialog,private utilityService: UtilityService,private idle: Idle,  private tokenStorageService: TokenStorageService) { }

  loginUser(user: any) {
    return this.httpClient.post<any>(constant.userLoginUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )

  }
  refreshToken(refToken: string) {
    const params = new URLSearchParams();
    params.set('refToken', refToken);
    return this.httpClient.post<any>(constant.refreshToken + "?refToken=" + encodeURIComponent(refToken), constant.httpClientHeader).pipe(
      map((response: any) => {
        const receivedUser: any = JSON.parse(response.data);
        return receivedUser;
      })
    )

  }
  authenticateUserStore(user: any) {
    return this.httpClient.post<any>(constant.userLoginAuthStore, user, constant.httpClientHeader).pipe(
      map(response => {
        return response;
      })
    );
  }

  authorizeUserRights(user: any) {
    return this.httpClient.post<any>(constant.getUserRights, user, constant.httpClientHeader).pipe(
      map(response => {
        return response;
      })
    );
  }

  saveUserSessionData(data:any){

    if (data) {

      this.tokenStorageService.saveToken(data.token);
      this.tokenStorageService.saveRefreshToken(data.user.refreshToken);
      this.tokenStorageService.saveUser(data.user);

      sessionStorage.setItem(constant.KEY_NPI, data.user.NPINo);
      sessionStorage.setItem(constant.KEY_USERNAME, data.user.userName);
      sessionStorage.setItem(constant.KEY_STORE_NAME, data.storeDetails.storeName);
      sessionStorage.setItem(constant.KEY_PSEUDO_NAME, data.storeDetails.pseudoName);
      sessionStorage.setItem(constant.KEY_USER_RIGHTS, JSON.stringify(data.rights));
      sessionStorage.setItem(constant.KEY_STATION_DETAILS, JSON.stringify(data.storeDetails.StationDetail));
    }

  }

  getUsersAccessData(): any {
    let NPINo = sessionStorage.getItem(constant.KEY_NPI);
    let userName = sessionStorage.getItem(constant.KEY_USERNAME);
    let storeName = sessionStorage.getItem(constant.KEY_STORE_NAME);
    let pseudoName = sessionStorage.getItem(constant.KEY_PSEUDO_NAME);
    let userRights = sessionStorage.getItem(constant.KEY_USER_RIGHTS);
    let stationDetails = sessionStorage.getItem(constant.KEY_STATION_DETAILS);

    var returnUserData = {
      NPINo: NPINo,
      userName: userName,
      storeName: storeName,
      pseudoName: pseudoName,
      userRights: userRights,
      stationDetails: stationDetails
    };
    
    return returnUserData;
  }

  getUsers(user: any) {
    return this.httpClient.post<any>(constant.getUsersUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )

  }

  createUsers(user: User) {
    return this.httpClient.post<any>(constant.insertUserUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  updateUsers(user: User) {
    return this.httpClient.post<any>(constant.updateUserUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )

  }

  deleteUsers(user: User) {
    return this.httpClient.post<any>(constant.deleteUserUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  logOutUser() {
    //removing the user session and then user will log out 
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }

  logOutUserFromTimeOut() {
    //removing the user session and then user will log out 
    this.dialogService.closeAll();
    sessionStorage.clear();
    this.idle.watch();//restart watching the user for idleness
    this.router.navigateByUrl('/login');
    this.utilityService.showAlert(constant.KEY_SUCCESS,"Logged Out","You have been logged out due to inactivity");
  }

  changePasswordAtLogin(user: User) {
    return this.httpClient.post<any>(constant.changeUserPasswordAtLoginUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )
  }
  changeUserPassword(user: User) {
    return this.httpClient.post<any>(constant.changeUserPasswordUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  getUserByUserName(user: any) {
    return this.httpClient.post<any>(constant.getUserByUserNameUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  changePasswordInEDI(user: User) {
    return this.httpClient.post<any>(constant.changePasswordWithCheckboxInEDIUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )
  }

  getUserByRole(user: any) {
    return this.httpClient.post<any>(constant.getUserByRole, user, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
}
