<div class="main-container" style="background-color: rgb(234, 237, 244);padding-top: 65px;">



    <div class="page-wrapper" style="background-color: rgb(234, 237, 244);padding-top: 50px;">
        <form name="nameForm" (keydown.enter)="loginUser()">

            <div fxLayout="row wrap">
                <!-- column -->
                <div fxFlex.gt-lg="33" fxFlex.gt-md="33" fxFlex.gt-xs="100" fxFlex="100">


                </div>

                <!-- column -->
                <div fxFlex.gt-lg="33" fxFlex.gt-md="33" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-card>
                        <div fxLayout="row wrap">
                            <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100"></div>
                            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
                                <h2>EPrimePOS</h2>
                            </div>
                            <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100"></div>
                        </div>


                        <hr>
                        <mat-card-content fxLayout="column">
                            <form #loginUserForm="ngForm" autocomplete="off">
                                <div class="form-group row">
                                    <div class="col-md-10">
                                        <mat-form-field>
                                            <input matInput placeholder="User Name" name="UserName" #UserName="ngModel"
                                                id="UserName" [(ngModel)]="user.UserName" class="form-group" required>

                                            <mat-error *ngIf="UserName.invalid && (UserName.dirty || UserName.touched)"
                                                class="invalid-feedback">
                                                Please enter user name
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-2"></div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-md-10">

                                        <mat-form-field>
                                            <input matInput placeholder="Password" name="password" #password="ngModel"
                                                id="password" [(ngModel)]="user.password" required
                                                [type]="hide ? 'password' : 'text'">

                                            <mat-icon class="pointer" matSuffix (click)="hide = !hide">{{hide ?
                                                'visibility_off' :
                                                'visibility'}}
                                            </mat-icon>

                                            <mat-error *ngIf="password.invalid && (password.dirty || password.touched)"
                                                class="invalid-feedback">
                                                Please enter password
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                </div>
                                <mat-label style=" float: right; cursor:pointer" (click)="resetPassword()">Forgot
                                    Password ??? </mat-label>

                            </form>
                            <br>
                            <button mat-raised-button style="width: 100%;" [disabled]="loginUserForm.invalid"
                                (click)="loginUser();" color="primary">Login</button>

                        </mat-card-content>

                    </mat-card>


                </div>

                <!-- column -->
                <!-- column -->
                <div fxFlex.gt-lg="33" fxFlex.gt-md="33" fxFlex.gt-xs="100" fxFlex="100">

                </div>
                <!-- column -->
            </div>

        </form>
    </div>
</div>