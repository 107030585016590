<div>

    <div class="dialog-headline">
        <mat-label style="float:left;">Search Department</mat-label>
        <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
    </div>

    <mat-dialog-content class="mat-typography" style="padding-top: 20px;">


        <div fxLayout="row wrap">

            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <mat-card fxLayoutGap="20px">

                    <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field fxFlex="70">
                            <input matInput placeholder="Department Code" [(ngModel)]="itemDept.DeptCode"
                                name="DeptCode" id="DeptCode" class="form-group">
                        </mat-form-field>

                    </div>

                    <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">

                        <mat-form-field fxFlex="70">

                            <input matInput placeholder="Department Name" [(ngModel)]="itemDept.DeptName"
                                name="DeptName" id="DeptName" class="form-group">

                        </mat-form-field>

                    </div>



                    <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"
                        style="padding-top: 10px;padding-right: 10px;">

                        <button class="custom-blue-button" type="button" id="btnSearch"
                            style="width: 85px; height: 40px;" (click)="searchDepartment()">
                            <mat-icon color="white">search</mat-icon>Search
                        </button>
                    </div>

                </mat-card>
            </div>

        </div>



        <form #deptSearchModalForm="ngForm" autocomplete="off">

            <div class="example-container mat-elevation-z8" *ngIf="showTableForTransactionReport">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100"
                    style="padding-bottom: 10px;">

                    <mat-sidenav-container>

                        <mat-sidenav #sidenav position="start" mode="side" opened="false" class="width-30 p-6">
                            SIDENAV CONTENT HERE
                        </mat-sidenav>

                        <mat-sidenav-content>
                            <div class="mat-elevation-z8 m-12">
                                <div class="table-container">
                                    <!-- <table mat-table [dataSource]="dataSource"> -->
                                    <mat-table id="reportItemTable" matTableExporter matSort
                                        [dataSource]="deptDataSource">

                                        <!-- <ng-container matColumnDef="Action">
                                            <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                                            <mat-cell *matCellDef="let element">
    
                                                <button mat-icon-button matTooltip="Select {{element.description}}"
                                                    color="primary" (click)="sendSelectedDept(element)">
                                                    Select
                                                </button>
    
                                            </mat-cell>
                                        </ng-container> -->

                                        <ng-container color="primary" matColumnDef="Select">
                                            <mat-header-cell color="primary" *matHeaderCellDef><mat-checkbox
                                                    matTooltip="Select All" (change)="$event ? masterToggle() : null"
                                                    [checked]="selection.hasValue() && isAllSelected()"
                                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                                                </mat-checkbox></mat-header-cell>

                                            <mat-cell color="primary" *matCellDef="let element"> <mat-checkbox
                                                    (click)="$event.stopPropagation()"
                                                    (change)="$event ? selection.toggle(element) : null"
                                                    [checked]="selection.isSelected(element)">
                                                </mat-checkbox> </mat-cell>
                                        </ng-container>




                                        <ng-container matColumnDef="DeptCode">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>Dept. Code
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.DeptCode}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="DeptName">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>Dept.
                                                Name</mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.DeptName}} </mat-cell>
                                        </ng-container>

                                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                        <mat-row *matRowDef="let row; columns: displayedColumns;"
                                            [ngClass]="{hovered: row.hovered}" (mouseover)="row.hovered = true"
                                            (mouseout)="row.hovered = false" (click)="selection.toggle(row)"></mat-row>

                                    </mat-table>


                                    <mat-paginator fxLayoutAlign="start" #paginator [length]="totalRows"
                                        [pageIndex]="currentPage" [pageSize]="pageSize"
                                        [pageSizeOptions]="pageSizeOptions" aria-label="Select page"
                                        (page)="pageChanged($event)">
                                    </mat-paginator>
                                </div>
                                <!-- end table container -->

                            </div>
                        </mat-sidenav-content>

                    </mat-sidenav-container>
                </div>
            </div>


        </form>


    </mat-dialog-content>
    <!-- for adding line -->
    <hr>
    <mat-dialog-actions align="end">



        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>

            <div fxFlex.gt-lg="60" fxFlex.gt-md="60" fxFlex.gt-xs="100" fxFlex="100" fxLayout="row"
                fxLayoutAlign="center center" fxLayoutGap="5px">

                <button mat-flat-button style="background-color: #F59120;" color="primary"
                    matTooltip="Send Selected Items" (click)="sendSelectedDepts()"
                    [disabled]="deptArray.length<1 || selection.selected.length<1">Send Selected
                </button>

                <button mat-stroked-button mat-dialog-close class="secondary" matTooltip="Close this dialog">
                    <mat-icon>close</mat-icon>Close
                </button>

            </div>

            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
        </div>

    </mat-dialog-actions>
</div>