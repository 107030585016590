import { SelectionModel } from '@angular/cdk/collections';
import { Component, HostListener, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from "@angular/material/table";
import { constant } from 'src/app/constant/constant';
import { userAccess } from "src/app/models/UserAccess";
import { UserService } from "src/app/services/User/user.service";
import { VendorService } from 'src/app/services/Vendor/vendor.service';
import { UtilityService } from "src/app/services/utility/utility.service";

@Component({
    selector: 'app-vendor-search',
    templateUrl: './vendor-search.component.html',
    styleUrls: ['./vendor-search.component.scss']
  })

  export class VendorSearchComponent implements OnInit {

    searchVendor:any={
      NPINo:'',
      vendorCode:'',
      vendorName:''
    };

    showTableForTransactionReport:boolean=true;
    vendorArray: any[] = [];
    userAccessData!: userAccess;
    selection = new SelectionModel<any>(true, []);

    @ViewChild(MatPaginator, { static: false })
    paginator!: MatPaginator;
    @ViewChild(MatSort)
    sort!: MatSort;
    displayedColumns = ['Select', 'vendorCode', 'vendorName','address1','address2','city','cellNo','phoneOff','emailAddress','salePriceUpdate','vendorId'];
    pageSizeOptions: number[] = [10, 25, 50, 100];
    currentPage = 0;
    pageSize = 10;
    totalRows = 0;
    searchKey = "";
    vendorDataSource: MatTableDataSource<any> = new MatTableDataSource();

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private userService: UserService, private utilityService: UtilityService,private vendorService: VendorService, private deptDialogService: MatDialogRef<VendorSearchComponent>,) {
        this.userAccessData = this.userService.getUsersAccessData();
        // this.searchVendor.vendorCode="";
        // this.searchVendor.vendorName="";
        if (data.calledFrom) {
            switch (data.calledFrom)
            {
              
                case "SalesReport":
                {
                  this.showTableForTransactionReport = true;
                  //this.itemDept.fromReport=true;
                  //this.itemDept.DeptCode=data.itemData.DeptCode
                  break;
                }
                default:{
    
                }
    
            }
        }
        this.searchVendors();
    }
    ngOnInit(): void {
      
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.vendorDataSource.data.length;
        return numSelected === numRows;
      }
    
      /** Selects all rows if they are not all selected; otherwise clear selection. */
      masterToggle() {
        this.isAllSelected() ?
          this.selection.clear() :
          this.vendorDataSource.data.forEach(row => this.selection.select(row));
      }

      AllowOnlyNumber(event: any) {

        var inputValue = String.fromCharCode(event.keyCode);
        // Allow numbers, alpahbets, space, underscore
        if (/[0-9]/.test(inputValue)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }

      pageChanged(event: PageEvent) {

        console.log({ event });
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.searchVendors();
      }

      sendSelectedDept(deptData: any) {
        this.deptDialogService.close(deptData);//this will close the dialoge for item search and send the data to subscriber
      }

      sendSelectedVendors() {

        this.deptDialogService.close(this.selection.selected);//this will close the dialoge for item search and send the data to subscriber
      }

    @HostListener('keydown.enter', ['$event'])
    onEnterKey(event: KeyboardEvent) {
        this.searchVendors();
    }

      searchVendors() {
       
        this.searchVendor.NPINo=this.userAccessData.NPINo
        this.vendorService.getVendorsByNPINo(this.searchVendor).subscribe(response => {
    
          if (response.result == constant.KEY_SUCCESS) {
    
            if (response.data != "") {
              var receivedItemResponse = JSON.parse(response.data);
              this.vendorArray = receivedItemResponse;
    
              if (this.vendorArray.length < 1) {
                var alertRef = this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "No data found").subscribe(result => {
    
                  //if (result == true)
                  //this.dialogService.closeAll()
                  //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                });
    
                alertRef.unsubscribe();
                this.vendorDataSource = new MatTableDataSource<any>(this.vendorArray);
                this.vendorDataSource.paginator = this.paginator!;
                this.vendorDataSource.sort = this.sort!;
              } else {
                //for item table
                this.vendorDataSource = new MatTableDataSource<any>(this.vendorArray);
                this.vendorDataSource.paginator = this.paginator!;
                this.vendorDataSource.sort = this.sort!;
                
              
              }
    
    
              setTimeout(() => {
    
                this.paginator!.pageIndex = this.currentPage;
                this.paginator!.length = receivedItemResponse.totalRecords;
    
              });
            }
            else {
              var alertRef = this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "No data found").subscribe(result => {
    
                //if (result == true)
                //this.dialogService.closeAll()
                //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
              });
    
              alertRef.unsubscribe();
              this.vendorDataSource = new MatTableDataSource<any>(this.vendorArray);
              this.vendorDataSource.paginator = this.paginator!;
              this.vendorDataSource.sort = this.sort!;
            }
    
    
    
          }
          else if (response.result == constant.KEY_ERROR) {
            if (response.message == "No data found") {
              var alertRef = this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "No data found").subscribe(result => {
              });
            }
            else {
              this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while loading the items");
            }
          }
    
        }, error => {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while fetching Vendors details");
    
        });
      }

  }