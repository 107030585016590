import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { LabelPrintObject } from 'src/app/models/LabelPrintObject';
import { BaseHttpService } from 'src/app/utility/BaseHttpService';

@Injectable({
  providedIn: 'root'
})

export class ItemService {

  constructor(private httpClient: HttpClient, private baseHttpService: BaseHttpService) { }

  getItems(item: any) {
    return this.baseHttpService.post<any>(constant.getBaseItemUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  getItemPriceHistory(item: any) {
    return this.baseHttpService.post<any>(constant.getItemPriceHistoryUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getQuickAddItemsOnLoad(item: any) {
    return this.baseHttpService.post<any>(constant.getQuickAddItemsOnLoadUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getQuickAddItems(item: any) {
    return this.baseHttpService.post<any>(constant.getQuickAddItemsUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  reactivateDeletedItem(item: any) {
    return this.baseHttpService.post<any>(constant.reactivateDeletedItemUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  createQuickAddItems(item: any) {
    return this.baseHttpService.post<any>(constant.createQuickAddItemsUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  updateQuickAddItems(item: any) {
    return this.baseHttpService.post<any>(constant.createQuickAddItemsUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  deleteQuickAddItems(item: any) {
    return this.baseHttpService.post<any>(constant.deleteQuickAddItemsUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  createQuickAddGroups(item: any) {
    return this.baseHttpService.post<any>(constant.createUpdateQuickAddGroupUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  updateQuickAddGroups(item: any) {
    return this.baseHttpService.post<any>(constant.createUpdateQuickAddGroupUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  deleteQuickAddGroups(item: any) {
    return this.baseHttpService.post<any>(constant.deleteQuickAddGroupItemUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getItemMonitoringCategories(item: any) {
    return this.baseHttpService.post<any>(constant.getItemMonitoringCategoriesUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  insertItemMonitoringCategories(item: any) {
    return this.baseHttpService.post<any>(constant.insertItemMonitoringCategoriesUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  updateItemMonitoringCategories(item: any) {
    return this.baseHttpService.post<any>(constant.updateItemMonitoringCategoriesUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  deleteItemMonitoringCategories(item: any) {
    return this.baseHttpService.post<any>(constant.deleteItemMonitoringCategoriesUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  createItem(item: any) {
    return this.baseHttpService.post<any>(constant.createItemUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getAggregateItem(item: any) {
    return this.baseHttpService.post<any>(constant.getAggregateItemUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  updateItem(item: any) {
    return this.baseHttpService.post<any>(constant.updateItemUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {

        return response;
      })
    )
  }

  deleteItem(item: any) {
    return this.baseHttpService.post<any>(constant.deleteItemUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }


  insertDefaultItems(item: any) {
    return this.baseHttpService.post<any>(constant.insertDefaultItemUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getItemsForPO(item: any) {
    return this.baseHttpService.post<any>(constant.getItemUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  generateLabels(item: any) {
    return this.baseHttpService.post<any>(constant.generateLabelsUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  saveLabelTemplate(item: any) {
    return this.baseHttpService.post<any>(constant.saveLabelTemplateUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  deleteLabelTemplate(item: any) {
    return this.baseHttpService.post<any>(constant.deleteLabelTemplateUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {

        return response;
      })
    )
  }

  updateLabelTemplate(item: any) {
    return this.baseHttpService.post<any>(constant.updateLabelTemplateUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getByTemplateName(item: any) {
    return this.baseHttpService.post<any>(constant.getByTemplateNameUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getAllAggregateItem(item: any) {
    return this.baseHttpService.post<any>(constant.getAllAggregateItemUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  updatePriceHistory(NPINO: any) {
    return this.baseHttpService.post<any>(constant.updatePriceHistory, NPINO, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  createLabelPDF(labelData: LabelPrintObject[]) {
    return this.httpClient.post(constant.createLabelPDFUrl, labelData,{responseType:'blob'});
  }

  getItemsByDeptId(item: any) {
    return this.baseHttpService.post<any>(constant.getItemByIDList, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getItemByNPI(item: any) {
    return this.baseHttpService.post<any>(constant.getAllNpiItemUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }


  getItemVendorData(item: any) {
    return this.baseHttpService.post<any>(constant.getItemVendorDataUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  createItemVendor(item: any) {
    return this.baseHttpService.post<any>(constant.insertItemVendorDataUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  updateLastVendor(poData: any) {
    return this.baseHttpService.post<any>(constant.updateLastVendorDataUrl, poData, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }


  CheckItemCodeExists(item: any) {
    return this.baseHttpService.post<any>(constant.CheckItemCodeExistsUrl, item, constant.httpClientHeader.headers).pipe(
      map((response: any) => {

        return response;
      })
    )
  }

}
