import { ChangeDetectorRef, Component } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { UtilityService } from './services/utility/utility.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ePrimePOS';
  bluerValue: number = 5;
  thickness: number = 5;
  
  // some fields to store our state so we can display it in the UI
  idleState = "NOT_STARTED";
  countdown?: number = undefined;
  lastPing?: Date = undefined;
  

  constructor(private idle: Idle, keepalive: Keepalive, cd: ChangeDetectorRef, private utilityService: UtilityService) {
    // set idle parameters
    idle.setIdle(1800); // how long can they be inactive before considered idle, in seconds
    idle.setTimeout(1800); // how long can they be idle before considered timed out, in seconds
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    idle.onIdleStart.subscribe(() => {

      this.idleState = "IDLE";
      this.utilityService.showCountDownAlert("WARNING", "You are idle since 30 minutes", "Please click cancel to continue the session else click logout");
      console.log("IDLE");
    });
    // do something when the user is no longer idle
    idle.onIdleEnd.subscribe(() => {

      console.log("NOT_IDLE");
      this.idleState = "NOT_IDLE";
      this.countdown = undefined;
      cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed out
    idle.onTimeout.subscribe(() => {
      console.log("TIMED_OUT");
      this.idleState = "TIMED_OUT";
    });
    // do something as the timeout countdown does its thing
    idle.onTimeoutWarning.subscribe(seconds => {

      console.log("NOT_IDLE");
      this.countdown = seconds;
    });

    // set keepalive parameters, omit if not using keepalive
    //keepalive.interval(15); // will ping at this interval while not idle, in seconds
    keepalive.onPing.subscribe(() => this.lastPing = new Date()); // do something when it pings
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = undefined;
    this.lastPing = undefined;
  }

  ngOnInit(): void {
    // right when the component initializes, start reset state and start watching
    this.reset();
  }
}
