import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { constant } from 'src/app/constant/constant';
import { userAccess } from 'src/app/models/UserAccess';
import { ItemService } from 'src/app/services/Item/item.service';
import { UserService } from 'src/app/services/User/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-item-vendor-add-modal',
  templateUrl: './item-vendor-add-modal.component.html',
  styleUrls: ['./item-vendor-add-modal.component.css']
})
export class ItemVendorAddModalComponent implements OnInit {

  itemData: any = {};
  itemVendorData: any = {};
  userAccessData!: userAccess;
  item: any={};
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private utilityService: UtilityService,
    private dialogService: MatDialogRef<ItemVendorAddModalComponent>,private userService: UserService,
    private itemService: ItemService) {
      this.userAccessData = this.userService.getUsersAccessData();
    this.itemData = data.itemData;
    this.itemVendorData.NPINo = data.itemData.NPINo;
    this.itemVendorData.itemID = data.itemData.itemId;
    this.itemVendorData.vendorCode = data.itemData.vendorItems.vendorCode;
  }

  ngOnInit(): void {
  }

  createItemVendor() {

    this.itemVendorData.userID=this.userAccessData.userName;
    this.itemService.createItemVendor(this.itemVendorData).subscribe(response => {

      if (response.result == "SUCCESS") {
        var receivedVendorResponse = JSON.parse(response.data);
        this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_CREATE_TITLE, "Item vendor inserted successfully").subscribe(result => {

          //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
          this.dialogService.close(receivedVendorResponse);
        });
      } else {

        this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while loading the vendors");
      }
    });

  }

}
