import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private httpClient: HttpClient) { }

  getRoles(roleFilter: any) {
    return this.httpClient.post<any>(constant.getRoleUrl, roleFilter, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  getRolesOnLoad(roleFilter: any) {
    return this.httpClient.post<any>(constant.getRolesOnLoadUrl, roleFilter, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
  createRole(roleFilter: any) {
    return this.httpClient.post<any>(constant.createRoleUrl, roleFilter, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
  updateRole(roleFilter: any) {
    return this.httpClient.post<any>(constant.updateRoleUrl, roleFilter, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
  deleteRole(roleFilter: any) {
    return this.httpClient.post<any>(constant.deleteRoleUrl, roleFilter, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
  getModules() {
    return this.httpClient.get<any>(constant.getModulesUrl, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

}
